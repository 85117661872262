"use client";

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useState, useMemo, useCallback, useRef, createContext, useEffect, useContext,
} from "react";
import { debounce } from "@audacia-hq/shared/utils";
import { MarketingEvent, UserSession } from "@audacia-hq/shared/models";
import dayjs from "dayjs";

import MarketingApiClient from "../services/marketingAPI";

declare global {
  interface Window {
    clarity: (...args:any) => void;
  }
}

const setLocalStorageItem = (key: string, value: string) => {
  if (typeof window !== "undefined") {
    localStorage?.setItem(key, value);
    return true;
  }
  return false;
};

const getLocalStorageItem = (key: string) => {
  if (typeof window !== "undefined") {
    return localStorage?.getItem(key);
  }
  return null;
};

interface EventTrackerContextType {
  addEvent: (event: MarketingEvent) => void;
  userSession?: UserSession | null;
  loading: boolean;
}

const EventTrackerContext = createContext<EventTrackerContextType>({
  addEvent: () => ({}),
  userSession: null,
  loading: false,
});

const SEND_EVENT_DELAY = 2000;

const EventTrackerProvider: React.FC<{children: React.ReactNode; client: MarketingApiClient; userUid?: string }> = ({ children, client, userUid }) => {
  const [stackedEvents, setStackedEvents] = useState<any[]>([]);
  const [userSession, setUserSession] = useState<UserSession>();
  const [loading, setLoading] = useState<boolean>(true);
  const refStackedEvents = useRef<any[]>([]);

  const sendEvents = useCallback(debounce(() => {
    if (!userSession) return;
    client.postEvents(userSession.userSessionUid, refStackedEvents.current);
    setStackedEvents([]);
  }, SEND_EVENT_DELAY), [userSession]);

  const addEvent = (event: MarketingEvent) => setStackedEvents((prevEvents) => ([...prevEvents, event]));

  useEffect(() => {
    refStackedEvents.current = stackedEvents;
    if (!stackedEvents || stackedEvents.length === 0) return;
    sendEvents();
  }, [stackedEvents]);

  useEffect(() => {
    setLoading(true);
    const session = getLocalStorageItem("userSessionUid");
    // Get campaign uid from query
    const params = new URLSearchParams(window.location.search);
    const campaignUid = (() => {
      const paramsCampaign = params.get("campaign") || params.get("c");
      if (paramsCampaign) return paramsCampaign;

      // If seen less than 90 days, still rely on this. This helps tracking guests that become users.
      const lastSeenAt = getLocalStorageItem("lastSeenAt");
      const storageCampaign = getLocalStorageItem("campaignUid");
      if (dayjs().diff(dayjs(lastSeenAt), "days") < 90) return storageCampaign;
      return undefined;
    })();

    setLocalStorageItem("campaignUid", campaignUid || "");
    setLocalStorageItem("lastSeenAt", new Date().toISOString());
    client.postUserSession(session, { campaignUid })
      .then((res) => setUserSession(res))
      .catch(async (err) => {
        localStorage?.removeItem("userSessionUid");
        client.postUserSession(undefined, { campaignUid }).then((res) => setUserSession(res)).catch(() => undefined);
      })
      .finally(() => setLoading(false));
  }, [userUid]);

  useEffect(() => {
    if (!userSession) return;
    setLocalStorageItem("userSessionUid", userSession.userSessionUid);
    if (!window?.clarity) return;
    try {
      userSession.featureFlagVersions?.forEach((ffv) => {
        window?.clarity("set", "feature", `${ffv.identifier}#${ffv.version.key ?? "Control"}`);
      });
    } catch (error) { /* */ }
  }, [userSession]);

  const value = useMemo(() => ({
    addEvent,
    userSession,
    loading,
  }), [addEvent, userSession, loading]);

  return (
    <EventTrackerContext.Provider value={value}>
      {children}
    </EventTrackerContext.Provider>
  );
};

const useEventTracker = () => useContext(EventTrackerContext);

export default EventTrackerContext;
export { EventTrackerProvider, useEventTracker };
