import * as React from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import AlertModal from "../modal/AlertModal";
import Button from "../Button";

interface Props {
  onValidate: (newEmail: string) => void;
}

const EmailChange: React.FC<Props> = ({ onValidate }) => {
  const { t } = useTranslation("common");
  const [showEmailModal, setShowEmailModal] = useState<boolean>(false);

  const formik = useFormik<{ email: string }>({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("errors.email.invalid").required("errors.required"),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (form) => {
      formik.resetForm();
      setShowEmailModal(false);
      onValidate(form.email);
    },
  });

  const handleClose = () => {
    formik.resetForm();
    setShowEmailModal(false);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Button
        className="pb-0 pl-1"
        theme="link"
        size="small"
        onClick={() => setShowEmailModal(true)}
      >
        {t("emailChangeButton")}
      </Button>
      <AlertModal
        title={t("emailChangeTitle")}
        variant="info"
        open={showEmailModal}
        cancelLabel={t("cancel")}
        validateLabel={t("confirm")}
        onClose={handleClose}
        onValidate={() => formik.submitForm()}
      >
        <p>{t("emailChangeHint")}</p>
        <input
          id="email"
          type="email"
          autoComplete="email"
          placeholder={t("emailChangePlaceholder")}
          className={`input text-sm mt-4 ${formik.errors.email ? "border-red-500" : "border-gray-300"}`}
          value={formik.values.email}
          onChange={(v) => formik.setFieldValue("email", v.target.value)}
        />
        {formik.errors.email && (
          <span className="text-red-500 text-sm">{t(formik.errors.email)}</span>
        )}
      </AlertModal>
    </form>
  );
};

export default EmailChange;
