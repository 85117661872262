import { useVideo } from "@audacia-hq/shared/contexts";
import { faMicrophoneSlash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React, { PropsWithChildren, useEffect } from "react";

interface Props {
  deviceId: string;
  isMuted: boolean;
  className?: string;
}

interface InternalProps {
  isMuted?: boolean;
  audioContext?: AudioContext;
  streamPromise?: Promise<MediaStream>;
  stream?: MediaStream;
  source?: MediaStreamAudioSourceNode;
  analyser?: AnalyserNode;
  samples?: Uint8Array;
  loopTimeout?: ReturnType<typeof setTimeout>;
  displayTimeout?: ReturnType<typeof setTimeout>;
}

const MutedMicrophoneWarning: React.FC<Props> = ({
  deviceId, isMuted, className,
}) => {
  const { t } = useVideo();
  const [isTalking, setIsTalking] = React.useState(false);
  const [isDismissed, setIsDismissed] = React.useState(false);
  const internals = React.useRef<InternalProps>({});

  useEffect(() => {
    internals.current.isMuted = isMuted;
    if (!isMuted) setIsDismissed(false);
    if (!isMuted || !deviceId) { return () => { /* */ }; }
    startSampleCapture(deviceId);
    return () => stopSampleCapture();
  }, [isMuted, deviceId]);

  const detectSpeech = () => {
    if (!internals.current.isMuted || !internals.current.analyser) {
      return;
    }
    if (document.visibilityState === "visible") {
      internals.current.analyser.getByteTimeDomainData(internals.current.samples);

      const maxAmplitude = internals.current.samples.reduce((acc, val) => Math.max(acc, Math.abs(val - 128)), 0);
      const loudness = maxAmplitude / 128;
      if (loudness > 0.25) {
        setIsTalking(true);
        if (internals.current.displayTimeout) {
          clearTimeout(internals.current.displayTimeout);
        }
        internals.current.displayTimeout = setTimeout(() => setIsTalking(false), 5000);
      }
    }
    internals.current.loopTimeout = setTimeout(detectSpeech, 200);
  };

  const startSampleCapture = async (deviceId: string) => {
    try {
      internals.current.audioContext = new AudioContext();
      internals.current.stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId } });
      internals.current.source = internals.current.audioContext.createMediaStreamSource(internals.current.stream);
      internals.current.analyser = internals.current.audioContext.createAnalyser();
      internals.current.source.connect(internals.current.analyser);

      internals.current.analyser.fftSize = 2048;
      internals.current.samples = new Uint8Array(internals.current.analyser.fftSize);

      detectSpeech();
    } catch (err) {
      // do stuff
    }
  };

  const stopSampleCapture = () => {
    if (internals.current.loopTimeout) {
      clearTimeout(internals.current.loopTimeout);
      internals.current.loopTimeout = undefined;
    }
    if (internals.current.displayTimeout) {
      clearTimeout(internals.current.displayTimeout);
      internals.current.displayTimeout = undefined;
    }
    internals.current.analyser?.disconnect();
    internals.current.source?.disconnect();
    internals.current.stream?.getTracks().forEach((track) => track.stop());
    internals.current.audioContext?.close();
    internals.current.analyser = undefined;
    internals.current.source = undefined;
    internals.current.stream = undefined;
    internals.current.audioContext = undefined;
    setIsTalking(false);
  };

  return (
    <div className={clsx(
      "transition-opacity ease-out duration-500",
      className,
      isMuted && isTalking && !isDismissed ? "!opacity-100" : "!opacity-0 pointer-events-none",
    )}
    >
      <div className="relative flex flex-row w-fit gap-2 items-center text-sm bg-white py-1 px-2 rounded shadow">
        <FontAwesomeIcon icon={faMicrophoneSlash} className="" />
        <div className="flex flex-col gap-1 w-fit">
          {/* <div className="font-semibold">{t("video.unmuteToTalk.title")}</div> */}
          <div>{t("video.unmuteToTalk.text")}</div>
        </div>
        <FontAwesomeIcon
          icon={faXmark}
          className="cursor-pointer"
          onClick={() => {
            setIsDismissed(true);
            stopSampleCapture();
          }}
        />
        <div
          className="absolute top-full left-[0.85rem] md:left-[2.85rem] w-0 h-0"
          style={{
            content: "",
            borderLeft: "5px solid transparent",
            borderRight: "5px solid transparent",
            borderTop: "10px solid white",
            clear: "both",
          }}
        />
      </div>

    </div>
  );
};

export default MutedMicrophoneWarning;
