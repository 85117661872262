import React from "react";

import AnimatedDots from "../dots/AnimatedDots";

interface TypingIndicatorProps {
  /** The name of the user who is typing */
  pseudo: string;
  /** Translation function */
  t: (key: string, params?: any) => string;
}

/**
 * A component that displays a typing indicator.
 */
const TypingIndicator: React.FC<TypingIndicatorProps> = ({ pseudo, t }) => (
  <div key={`writing-${pseudo}`} className="flex items-center space-x-4 text-slate-500">
    <div className="ml-4"><AnimatedDots /></div>
    <div>{t("isWriting", { pseudo })}</div>
  </div>
);

export default TypingIndicator;
