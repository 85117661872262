import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { generateColor } from "@audacia-hq/shared/utils";

interface Props {
  text?: string;
  colorSeed: string;
  highlightBorderPx?: number;
  className?: string;
}

const TextAvatar: React.FC<Props> = ({
  text, colorSeed, highlightBorderPx, className,
}) => {
  const generateLetters = (t: string) => {
    const p = t.split(" ");
    return (p.length > 1 && p[0] && p[1] ? p[0].at(0) + p[1].at(0) : p[0].substring(0, 2)).toUpperCase();
  };

  if (!colorSeed) return null;

  return (
    <div
      className={`w-12 h-12 p-3 rounded-full flex justify-center items-center text-lg text-white ${className}`}
      style={{
        backgroundColor: generateColor(colorSeed),
        ...(highlightBorderPx ? { border: `${highlightBorderPx}px solid ${generateColor(colorSeed, 1, true)}` } : {}),
      }}
    >
      { text ? generateLetters(text) : (<FontAwesomeIcon icon={faQuestion} />) }
    </div>
  );
};

export default TextAvatar;
