import { faCircleNotch, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useLayout } from "@audacia-hq/shared/contexts";

import Modal from "../modal/Modal";

interface Props {
  url: string;
  show: boolean;
  onSend: () => Promise<void>;
  onClose: () => void;
}

const AttachmentPannel: React.FC<Props> = ({
  url, show, onSend, onClose,
}) => {
  const [loading, setLoading] = useState(false);
  const { mainHeight } = useLayout();

  const onSendAttachment = async () => {
    setLoading(true);
    await onSend();
    setLoading(false);
  };

  return (
    <Modal
      show={show}
      onClose={() => onClose()}
    >
      <div className="flex-row justify-between">
        <div className="w-full grow">
          <img src={url} alt="attachments" style={{ maxHeight: mainHeight - 130 }} className="object-cover" />
        </div>
        <div className="text-right mt-5 pt-5 border-t border-slate-400">
          <button
            data-pw="attachmentSend"
            type="button"
            aria-label="send"
            className="rounded-full text-white hover:bg-primary-200 bg-primary-500 w-12 h-12 text-xl transition-all duration-150"
            onClick={() => onSendAttachment()}
          >
            <FontAwesomeIcon icon={loading ? faCircleNotch : faPaperPlane} spin={loading} />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AttachmentPannel;
