import dayjs, {Dayjs} from "dayjs";
import minMax from "dayjs/plugin/minMax";

dayjs.extend(minMax);

interface SlottingRules {
  timeBetween: number; // padding seconds between consecutive slotted items
  minimumOverlap: number; // minimum seconds overlapping slot. lower => move to next slot
  maximumExcess: number; // maximum seconds outside of slot. higher => move to next slot
  startFrom?: Dayjs;
}

interface ItemToSlot {
  [key: string]: any;
  duration: number;
  startedAt?: string | Dayjs;
  type: string;
}

interface SlottedItem {
  [key: string]: any;
  startAt: Dayjs;
  lateness: number;
}

interface Slot {
  [key: string]: any;
  start: Dayjs;
  end: Dayjs;
}

interface StringSlot {
  [key: string]: any;
  start: string;
  end: string;
}

interface StringPlanningObj {
  from?: string;
  to?: string;
  slots: StringSlot[]
  queueSlots?: StringSlot[]
}

export const dayjsifyPlanning = (planning: StringPlanningObj) => {
  if (!planning) return undefined;
  return {
    ...planning,
    from: planning?.from ? dayjs(planning.from) : undefined, 
    to: planning?.to ? dayjs(planning.to) : undefined, 
    slots: planning.slots.map(({ start, end, ...others }) => ({ ...others, start: dayjs(start), end: dayjs(end) })),
    queueSlots: planning.queueSlots?.map(({ start, end, ...others }) => ({ ...others, start: dayjs(start), end: dayjs(end) })),
  };
};


export const estimateSlottedItemStart = (items: ItemToSlot[], slots: Slot[], rules: SlottingRules) => {
  if(!items?.length) return [];
  
  const {timeBetween, minimumOverlap, maximumExcess, startFrom } = rules;

  const boundSlots =  (startFrom ? slots?.filter((s) => s.end.isAfter(startFrom)) : slots) || [];

  const estimateRecur = (items: ItemToSlot[], slots: Slot[], lastEnd: Dayjs, result: SlottedItem[]): SlottedItem[] => {
    if (items.length === 0) return result;
    const { startedAt, duration: itemDuration, type } = items[0];

    if(type === "BREAK") { //skip breaks, but shift following items
      return estimateRecur(
        items.slice(1),
        slots,
        lastEnd.add(itemDuration, "second"),
        result,
      );
    }

    const elapsed = startedAt ? dayjs().diff(startedAt, "second") : 0;
    const duration = Math.max(0, itemDuration - elapsed);
    const lastSlotted = result.at(-1);
    if (slots.length === 0) {
      const startAt = lastEnd.add((lastSlotted ? timeBetween : 0), "second");
      return estimateRecur(
        items.slice(1),
        slots,
        startAt.add(duration, "second"),
        [...result, {
          ...items[0],
          startAt,
          lateness: (lastSlotted ? lastSlotted?.lateness + timeBetween : 0) + duration,
        }],
      );
    }

    const concatStart = dayjs.max(slots[0].start, lastSlotted ? lastEnd.add(timeBetween, "second") : lastEnd);
    const concatEnd = concatStart.add(duration, "second");

    const overlap = slots[0].end.diff(concatStart, "second");
    const excess = concatEnd.diff(slots[0].end, "second");

    // concatenate after previous item (if fits in slot || last slot || contiguous next slot)
    if ((overlap >= minimumOverlap && excess <= maximumExcess) || (!slots[1] || slots[1].start.isSame(slots[0].end))) {
      const lateness = !slots[1] && excess > 0 ? excess : 0;
      const nextSlotIdx = slots.findIndex((s) => !s.end.isBefore(concatEnd));

      return estimateRecur(
        items.slice(1),
        nextSlotIdx === -1 ? [] : slots.slice(nextSlotIdx),
        concatEnd,
        [...result, {
          ...items[0],
          startAt: concatStart,
          lateness,
        }],
      );
    }

    // pick start between last + buffer or next slot start (whichever is furthest)
    const itemStart = dayjs.max(lastEnd.add(timeBetween, "second"), slots[1].start);
    const itemEnd = itemStart.add(duration, "second");
    const nextSlotIdx = slots.findIndex((s) => !s.end.isBefore(itemEnd));

    // insert at start of next slot
    return estimateRecur(
      items.slice(1),
      nextSlotIdx === -1 ? [] : slots.slice(nextSlotIdx),
      itemEnd,
      [...result, {
        ...items[0],
        startAt: itemStart,
        lateness: 0,
      }],
    );
  };

  const initFrom = startFrom ? dayjs.max(boundSlots[0]?.start || startFrom, startFrom) : boundSlots[0]?.start || dayjs();

  // preset first item if already started
  if (items[0]?.startedAt) {
    const start = dayjs(items[0].startedAt);
    const end = start.add(items[0].duration, "second");

    return estimateRecur(
      items.slice(1),
      boundSlots,
      dayjs.max(initFrom.subtract(timeBetween, "second"), end),
      items[0].type !== "BREAK" ? [{
        ...items[0],
        startAt: start,
        lateness: 0,
      }] : [],
    );
  }

  return estimateRecur(
    items,
    slots,
    initFrom,
    [],
  );
};

const findLastIndex = (array: any[], conditionFct: (item: any, idx: number) => boolean): number => {
  for (let idx = array.length - 1; idx >= 0; idx -= 1) {
    if (conditionFct(array[idx], idx)) return idx;
  }
  return -1;
};

export const mergeSlots = (a: Slot, b: Slot): Slot[] => (a.end.isSame(b.start) && (
  a["channels"]?.length === b["channels"]?.length
  && a["channels"]?.every((c: string) => b["channels"]?.includes(c))
)
  ? ([{ ...a, end: b.end }])
  : ([a, b]));

export const fuseSlots = (...slotArrays: Slot[][]): Slot[] => {
  if (slotArrays.length === 0) return [];
  if (slotArrays.length === 1) return slotArrays[0];
  return slotArrays.reduce((aggr, slots) => {
    if (aggr.length === 0) return [...slots];
    if (slots.length === 0) return aggr;
    const pop = aggr.pop() as Slot;

    return [...aggr, ...(mergeSlots(pop, slots[0])), ...slots.slice(1)];
  }, []);
};

export const truncateSlotsLeft = (slots: Slot[], from: Dayjs) => {
  const overlapIdx = slots.findIndex((s) => s.end.isAfter(from));
  if (overlapIdx === -1) return [];
  const overlapSlot = slots[overlapIdx];
  if (!overlapSlot.start.isBefore(from)) return slots.slice(overlapIdx);
  return [{ ...overlapSlot, start: from }, ...(slots.slice(overlapIdx + 1))];
};

export const truncateSlotsRight = (slots: Slot[], to: Dayjs) => {
  const overlapIdx = findLastIndex(slots, (s: Slot) => s.start.isBefore(to));
  if (overlapIdx === -1) return [];
  const overlapSlot = slots[overlapIdx];
  if (!overlapSlot.end.isAfter(to)) return slots.slice(0, overlapIdx + 1);
  return [...slots.slice(0, overlapIdx), { ...overlapSlot, end: to }];
};

export const truncateSlots = (slots: Slot[], from: Dayjs, to: Dayjs): Slot[] => truncateSlotsLeft(truncateSlotsRight(slots, to), from);

// empty baseSlots between from & to, then paste spliceSlots in from-to range in the gap
export const spliceSlots = (baseSlots: Slot[], splicedSlots: Slot[], from: Dayjs, to: Dayjs) : Slot[] => {
  if (baseSlots.length === 0) return truncateSlots(splicedSlots, from, to);

  return fuseSlots(
    truncateSlotsRight(baseSlots, from),
    truncateSlots(splicedSlots, from, to),
    truncateSlotsLeft(baseSlots, to),
  );
};
