import { FormikErrors, FormikValues } from "formik";
import React, { useCallback, useState } from "react";
import Cropper, { Area, Point } from "react-easy-crop";
import { useTranslation } from "react-i18next";

import getCroppedImg from "../utils/cropImage";
import Button from "../Button";

interface Props {
  className?: string;
  name: string;
  value: File[];
  setFieldValue: (
    field: string,
    value: File[],
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<FormikValues>>;
  initialPreview?: string;
  refusalReason?: string;
  disabled?: boolean;
}

const accept = ["image/jpeg", "image/png", "image/bmp", "image/webp"];

const UploadAndCropImageField: React.FC<Props> = ({
  className, name, value, setFieldValue, initialPreview, refusalReason, disabled,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string>();
  const [file, setFile] = useState<string>();
  const [previewUrl, setPreviewUrl] = useState<string>();
  const [filename, setFilename] = useState<string>();
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
  const onCropComplete = useCallback((_: Area, cap: Area) => {
    setCroppedAreaPixels(cap);
  }, []);
  const [isCropping, setIsCropping] = useState(false);

  const showCroppedImage = useCallback(async () => {
    if (!file || !croppedAreaPixels) return;
    try {
      const croppedImage = await getCroppedImg(file, croppedAreaPixels, 0);
      const fileValue = new File([croppedImage], filename);
      setFieldValue(name, [fileValue], true);
      setPreviewUrl((prev) => {
        if (prev) URL.revokeObjectURL(prev);
        if (!fileValue) return undefined;
        return URL.createObjectURL(fileValue);
      });
      setIsCropping(false);
    } catch (e) {
      // handle error
    }
  }, [croppedAreaPixels, file, filename, setFieldValue, name]);

  const selectPicture = (file: File) => {
    if (!file) return;
    if (file.size > 10 * 1024 ** 2) {
      setError(t("common:errors.files.size", { maxSize: "10MB" }));
      return;
    }
    setError(undefined);
    setFilename(file.name);
    setFile(URL.createObjectURL(file));
    setIsCropping(true);
  };

  return (
    <div className="flex-row">
      <div className="mb-2 text-xs">
        {t("common:adviseImage")}
      </div>
      {!isCropping && (
        <div className="text-center my-6">
          {(previewUrl || initialPreview) && (
            <div
              className="mx-auto w-1/3 aspect-square mb-8 relative group"
              style={{
                backgroundImage: `url(${previewUrl || initialPreview})`,
                backgroundPosition: "center center",
                backgroundSize: "auto 100%",
              }}
            >
              {!previewUrl && refusalReason && (
                <>
                  <div className="absolute top-0 left-0 right-0 bg-red-500 text-white" data-pw="refusalImage">
                    {t("common:imageRefused")}
                  </div>
                  <div className="relative group-hover:opacity-100 opacity-0 transition ease-in duration-200
                  bg-red-500 text-white top-6 px-2 py-4"
                  >
                    <div className="font-semibold" data-pw="refusalReasonImage">{t("common:imageRefusalReason")}</div>
                    {refusalReason}
                  </div>
                </>
              )}

            </div>
          )}
          <label
            className="cursor-pointer p-3 border border-slate-500 rounded-lg"
            htmlFor={`${name}-cropper-upload`}
          >
            {(previewUrl || initialPreview) ? t("common:modifyImage") : t("common:chooseImage")}
            <input
              id={`${name}-cropper-upload`}
              className="hidden"
              type="file"
              max={1}
              accept={accept.join(", ")}
              onChange={(e) => {
                selectPicture(e.target.files[0]);
              }}
            />
          </label>
          {error && <p className="text-red-500 mt-6">{error}</p>}
        </div>
      )}

      {file && isCropping && (
        <div className="mt-4 text-center">
          <div className="relative w-full h-[300px] mb-6">
            <Cropper
              image={file}
              crop={crop}
              zoom={1}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
            />
          </div>
          <Button className="mr-6" size="medium" theme="redGhost" onClick={() => setIsCropping(false)} type="button">{t("cancel")}</Button>
          <Button size="medium" theme="blue" onClick={showCroppedImage} type="button" data-pw="croppedImageSubmit">{t("validate")}</Button>
        </div>
      )}
    </div>
  );
};

export default UploadAndCropImageField;
