import React from "react";
import { useTranslation } from "react-i18next";
import { useField } from "formik";
import { Question } from "@audacia-hq/shared/models";

import Input from "../../form/Input";
import Checkbox from "../../form/Checkbox";

interface Props {
  question: Question;
}

const MultipleChoiceQuestion: React.FC<Props> = ({
  question,
}) => {
  const { t } = useTranslation("survey");
  const [props, meta, helpers] = useField("value");

  const otherValue = props.value.find((value) => !question.settings.options.some((option) => option.key === value)) ?? null;
  const hasOtherValue = otherValue !== null;

  return (
    <>
      <div className="flex flex-col">
        <h1 className="text-xl font-semibold pb-3">{question.title}</h1>
        {question.content && question.showDescription && <p className="whitespace-pre-wrap text-gray-500 pb-6">{question.content}</p>}
        {/* Not type="number", see https://technology.blog.gov.uk/2020/02/24/why-the-gov-uk-design-system-team-changed-the-input-type-for-numbers/ */}
        <div className="flex flex-col gap-4">
          {question.settings.options.map((option, index) => (
            <Checkbox
              id={option.key}
              label={option.label}
              key={index}
              checked={props.value.includes(option.key)}
              onChange={(event) => {
                const { checked } = event.target;
                if (checked) {
                  helpers.setValue([...props.value, option.key]);
                } else {
                  helpers.setValue(props.value.filter((value) => value !== option.key));
                }
              }}
            />
          ))}
          {question.settings.allowOther && (
            <Checkbox
              id="allowOther"
              label={t("survey:other")}
              checked={hasOtherValue}
              onChange={(event) => {
                const { checked } = event.target;
                if (checked) {
                  helpers.setValue([...props.value, ""]);
                } else {
                  helpers.setValue(props.value.filter((value) => value !== otherValue));
                }
              }}
            />
          )}
          {hasOtherValue && (
            <Input
              type="text"
              name={`${props.name}.0`}
              className=""
              onChange={(event) => {
                // find the index of the other value, and replace it with the new value
                const index = props.value.findIndex((value) => value === otherValue);
                helpers.setValue([...props.value.slice(0, index), event.target.value, ...props.value.slice(index + 1)]);
              }}
              value={otherValue}
            />
          )}
        </div>
        {meta.error && <p className="text-red-500 pt-2">{t(meta.error)}</p>}
      </div>
    </>
  );
};

export default MultipleChoiceQuestion;
