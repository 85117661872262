import dayjs, { Dayjs } from "dayjs";
import React, {
  useEffect, useMemo, useState,
} from "react";

interface CalendarContextType {
  hourPx: number;
  dayPx: number;
  days: Dayjs[];
  getClickDate: (event) => Dayjs;
  boundsElement: Element;
  currentTime: Dayjs;
}

const CalendarContext = React.createContext<CalendarContextType>({
  hourPx: 72,
  dayPx: 120,
  days: [dayjs().startOf("day")],
  getClickDate: (event) => dayjs(),
  boundsElement: undefined,
  currentTime: dayjs(),
});

interface Props {
  hourPx: number;
  days: Dayjs[];
  boundsRef: React.MutableRefObject<any>;
  getClickDate: (event) => Dayjs,
  currentTime: Dayjs,
}

const CalendarProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children, hourPx, days, boundsRef, getClickDate, currentTime,
}) => {
  const [dayPx, setDayPx] = useState<number>(1);

  useEffect(() => {
    if (!boundsRef?.current) return;
    setDayPx(boundsRef.current.offsetWidth / days.length);
    const resizeObserver = new ResizeObserver((entries) => {
      if (!entries[0].contentRect.width) return;
      setDayPx(entries[0].contentRect.width / days.length);
    });
    resizeObserver.observe(boundsRef.current);
    // eslint-disable-next-line consistent-return
    return () => resizeObserver?.disconnect();
  }, [boundsRef, days]);

  const value = useMemo(() => ({
    hourPx,
    dayPx,
    days,
    getClickDate,
    boundsElement: boundsRef?.current,
    currentTime,
  }), [hourPx, dayPx, days, getClickDate, currentTime]);

  return (
    <CalendarContext.Provider value={value}>
      {children}
    </CalendarContext.Provider>
  );
};

const useCalendar = () => React.useContext(CalendarContext);

export default CalendarContext;
export { CalendarProvider, useCalendar };
