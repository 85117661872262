import React, { useEffect, useRef, useState } from "react";

interface Props {
  breakpoints?: {
    height?: ThresholdRule[],
    width?: ThresholdRule[],
  }
  children: (props: BreakpointProps) => React.ReactNode;
}

interface ThresholdRule {
    threshold: number;
    props: BreakpointProps;
}

interface BreakpointProps {
  [key: string]: any;
}

const SizeAwareWrapper: React.FC<Props> = ({ breakpoints, children }) => {
  const ref = useRef(null);
  const [breakpointProps, setBreakpointProps] = useState<BreakpointProps>({});

  useEffect(() => {
    if (!ref?.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      const heightProps = breakpoints?.height
        ? breakpoints.height.sort((a, b) => b.threshold - a.threshold).find((tr) => tr.threshold < entries[0].contentRect.height)?.props || {}
        : {};

      const widthProps = breakpoints?.width
        ? breakpoints.width.sort((a, b) => b.threshold - a.threshold).find((tr) => tr.threshold < entries[0].contentRect.width)?.props || {}
        : {};

      const propsCandidate = { ...heightProps, ...widthProps };
      //
      setBreakpointProps((prev) => (JSON.stringify(prev) === JSON.stringify(propsCandidate) ? prev : propsCandidate));
    });
    resizeObserver.observe(ref.current);
    // eslint-disable-next-line consistent-return
    return () => resizeObserver?.disconnect();
  }, [ref, breakpoints]);

  return (
    <div ref={ref}>
      {children(breakpointProps)}
    </div>
  );
};

export default SizeAwareWrapper;
