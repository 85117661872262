import { generateTraceContext } from "@audacia-hq/shared/utils";
import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import { Auth } from "firebase/auth";

const client = axios.create();

const createRequestInterceptor = (auth: Auth) => (req: InternalAxiosRequestConfig) => {
  if (req.headers && req.headers["no-auth"]) {
    req.headers.set("traceparent", generateTraceContext());
    req.headers.delete("no-auth");
    return req;
  }
  return auth.currentUser?.getIdToken(false)
    .then((idToken) => {
      req.headers.set("traceparent", generateTraceContext());
      req.headers.set("Authorization", `Bearer ${idToken}`);
      return req;
    });
};

const createResponseInterceptor = (auth: Auth) => (error: AxiosError) => {
  if (error.config && error.response && error.response.status === 401) {
    return auth.currentUser?.getIdToken(true)
      .then((idToken) => {
        const config = {
          ...error.config,
          headers: {
            ...error.config.headers,
            Authorization: `Bearer ${idToken}`,
            traceparent: generateTraceContext(),
          },
        };
        return client.request(config);
      });
  }
  return Promise.reject(error);
};

const addAuthInterceptor = (auth: Auth) => {
  client.interceptors.request.use(createRequestInterceptor(auth));
  client.interceptors.response.use(undefined, createResponseInterceptor(auth));
};

export default client;
export { addAuthInterceptor };
