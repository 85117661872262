/* eslint-disable consistent-return */
import { WSMessage } from "@audacia-hq/shared/models";
import type OT from "@opentok/client";
import React, {
  PropsWithChildren, useEffect, useMemo, useRef, useState,
} from "react";
import { Subject } from "rxjs";

export type VideoRoomStatus = "CREATED" | "OPEN" | "CONNECTED" | "INITIATED" | "STARTED" | "FINISHED" | "EXPIRED" | "CANCELED" | "CONNECTION_LOST";

export interface VideoRoomInfo {
  videoRoomUid: string;
  sessionId: string;
  participants: VideoRoomParticipant[];
  maxTime: number;
  application: string;
  createdAt: string;
  startDate: string;
  endDate?: string;
  status: VideoRoomStatus;
  token: string;
}

export interface VideoRoomParticipant {
  name: string;
  userUid: string;
  online: boolean;
  isMe: boolean;
  connectionIds: string[] | null
}

export interface VideoRoomParameters {
  videoDevice: string | null;
  audioDevice: string;
  videoToggle: boolean;
  audioToggle: boolean;
}

export interface SubscriberItem {
  id: string;
  element?: HTMLVideoElement;
  subscriber: OT.Subscriber;
  stream?: OT.Stream;
  data: {
    userUid: string;
    name: string;
  }
}

export const videoRoomIsClosed = (status: string) => ["FINISHED", "EXPIRED", "CANCELED"].includes(status);
export const videoRoomIsOpen = (status: string) => ["CREATED", "CONNECTED", "INITIATED", "STARTED", "CONNECTION_LOST"].includes(status);

const publisherProperties = {
  insertMode: "append" as const,
  fitMode: "cover" as const,
  showControls: false,
  width: "100%",
  height: "100%",
  insertDefaultUI: false,
};

const subscriberProperties = {
  insertMode: "append" as const,
  preferredResolution: { width: 1280, height: 720 },
  fitMode: "cover" as const,
  showControls: false,
  width: "100%",
  height: "100%",
  insertDefaultUI: false,
};

interface Props {
  appId: string; // vonage app id
  closeVideoRoomHandler: (videoRoomUid: string) => Promise<any>;
  telemetryHandler: (eventsJsonString: string) => Promise<any>;
  ws: Subject<WSMessage>;
  t: (key: string, params?: any) => string;
  namespace: string;
  connectivityDisabled?: boolean;
  forceCamera?: boolean;
}

interface VideoContextType {
  videoRoom?: VideoRoomInfo;
  videoRoomStatus?: VideoRoomStatus;
  initVideoRoom: (videoRoom: VideoRoomInfo) => void;
  showReadyUpModal?: VideoRoomInfo;
  onUserReady: (videoRoom: VideoRoomInfo, parameters: VideoRoomParameters) => void;
  userSettings?: VideoRoomParameters;
  updateUserSettings: (update: Partial<VideoRoomParameters>) => void;
  closeVideoRoom: () => void;
  resetVideoRoom: () => void;
  t: (key: string, params?: any) => string;
  namespace: string;
  publisherElement?: HTMLVideoElement;
  subscriberItems: SubscriberItem[];
  connectivityDisabled?: boolean;
  pushTelemetryEvent?: (event: any) => void;
  forceCamera: boolean;
}

const VideoContext = React.createContext<VideoContextType>({
  initVideoRoom: () => ({}),
  showReadyUpModal: undefined,
  onUserReady: () => ({}),
  userSettings: undefined,
  updateUserSettings: (update) => undefined,
  closeVideoRoom: () => ({}),
  resetVideoRoom: () => ({}),
  t: () => "",
  namespace: "",
  subscriberItems: [],
  forceCamera: false,
});

interface OTState {
  session?: OT.Session;
  publisher?: OT.Publisher;
  subscribers: OT.Subscriber[];
  userSettings?: VideoRoomParameters;
}

const VIDEO_SETTINGS_LS_KEY = "videoConsultationUserSettings";

const VideoProvider: React.FC<PropsWithChildren<Props>> = ({
  appId, closeVideoRoomHandler, telemetryHandler, t, ws, namespace, connectivityDisabled = false, forceCamera = false, children,
}) => {
  const [videoRoom, setVideoRoom] = useState<VideoRoomInfo>();
  const [videoRoomStatus, setStatus] = useState<VideoRoomStatus>();
  const [showReadyUpModal, setShowReadyUpModal] = useState<boolean>(false);
  const [userSettings, setUserSettings] = useState<VideoRoomParameters>();

  const [publisherElement, setPublisherElement] = useState<HTMLVideoElement>();
  const [subscriberItems, setSubscriberItems] = useState<SubscriberItem[]>([]);

  const telemetryBuffer = useRef<any[]>([]);
  const telemetryBufferTimeout = useRef<ReturnType<typeof setTimeout>>();

  const pushTelemetryEvent = (event: any) => {
    if (!telemetryHandler) return;
    telemetryBuffer.current.push(event);
    if (telemetryBufferTimeout.current) {
      clearTimeout(telemetryBufferTimeout.current);
      telemetryBufferTimeout.current = undefined;
    }
    telemetryBufferTimeout.current = setTimeout(() => {
      if (telemetryBuffer.current.length === 0) return;
      telemetryHandler(JSON.stringify(telemetryBuffer.current));
      telemetryBuffer.current = [];
    }, 3000);
  };

  const otRuntime = useRef<typeof OT>();
  const mutableState = useRef<OTState>({ subscribers: [] });

  const getRuntime = async () => {
    if (otRuntime.current) return otRuntime.current;
    const loaded = (await import("@opentok/client")).default;
    otRuntime.current = loaded;
    return loaded;
  };

  useEffect(() => {
    const storedUserSettings = JSON.parse(localStorage?.getItem(VIDEO_SETTINGS_LS_KEY) || "{}");
    mutableState.current.userSettings = storedUserSettings;
    setUserSettings(storedUserSettings);
  }, []);

  const updateSubscriberItem = (subscriber: OT.Subscriber, update: Partial<SubscriberItem>) => {
    setSubscriberItems((prev) => prev.map((s) => {
      if (s.subscriber.id !== subscriber.id) return s;
      return { ...s, ...update };
    }));
  };

  const startPublisher = (runtime: typeof OT) => {
    const pub = runtime.initPublisher(undefined, {
      ...publisherProperties,
      publishAudio: mutableState.current.userSettings?.audioToggle,
      publishVideo: mutableState.current.userSettings?.videoToggle,
      audioSource: mutableState.current.userSettings?.audioDevice,
      videoSource: mutableState.current.userSettings?.videoDevice,
    });

    pub.on("streamDestroyed", (event) => {
      setPublisherElement(undefined);
      // mutableState.current.session?.unpublish(pub);
      mutableState.current.publisher?.destroy();
    });

    pub.on("videoElementCreated", ({ element }) => {
      element.classList.add("absolute", "inset-0", "h-full", "w-full", "object-cover"); // video tag doesn't play nice otherwise...
      setPublisherElement(element as HTMLVideoElement);
    });

    return pub;
  };

  const cyclePublisher = async () => {
    if (!mutableState.current.publisher) return;
    const runtime = await getRuntime();
    mutableState.current.publisher.destroy();
    const pub = startPublisher(runtime);
    mutableState.current.publisher = pub;
    mutableState.current.session?.publish(pub);
  };

  const initSession = async (sessionId: string, token: string) => {
    if (!videoRoom?.sessionId || !videoRoom?.token || connectivityDisabled) return undefined;
    const runtime = await getRuntime();
    const sess = runtime.initSession(appId, sessionId);

    sess.on("sessionConnected", (event) => {
      if (!mutableState.current.session) return;

      const pub = startPublisher(runtime);

      mutableState.current.publisher = pub;
      mutableState.current.session?.publish(pub);
    });

    sess.on("sessionDisconnected", (event) => {
      mutableState.current.subscribers.forEach((s) => mutableState.current.session?.unsubscribe(s));
      mutableState.current.session = undefined;
    });

    sess.on("streamCreated", (event) => {
      if (!mutableState.current.session || !event.stream) return;
      const userData = JSON.parse(event.stream.connection.data);
      const self = videoRoom?.participants.find((p) => p.isMe);
      if (self?.userUid === userData.userUid) return;
      const subscriber = mutableState.current.session?.subscribe(event.stream, undefined, subscriberProperties);
      subscriber.on("videoElementCreated", ({ element }) => {
        element.classList.add("absolute", "inset-0", "h-full", "w-full", "object-cover"); // video tag doesn't play nice otherwise...
        updateSubscriberItem(subscriber, { element: element as HTMLVideoElement });
      });

      mutableState.current.subscribers.push(subscriber);
      setSubscriberItems((prev) => {
        const subData = JSON.parse(subscriber.stream?.connection?.data || "{}");
        return [...prev, {
          id: subscriber.stream?.streamId as string,
          subscriber,
          stream: event.stream,
          data: subData,
        }];
      });
    });

    sess.on("streamDestroyed", (event) => {
      if (!event.stream) return;
      const userData = JSON.parse(event.stream.connection.data);
      const self = videoRoom?.participants.find((p) => p.isMe);
      if (self?.userUid === userData.userUid) return;
      setSubscriberItems((prev) => {
        const subsToRemove = prev.filter((s) => !s.subscriber.stream || s.subscriber.stream.streamId === event.stream.streamId);
        const next = prev.filter((s) => !subsToRemove.find((sr) => sr.subscriber.id === s.subscriber.id));
        mutableState.current.subscribers = next.map((s) => s.subscriber);
        return next;
      });
    });
    // sess.on("sessionReconnected", (event) => console.log("sessionReconnected"));
    // sess.on("sessionReconnecting", (event) => console.log("sessionReconnecting"));

    mutableState.current.session = sess;
    sess.connect(token, (err) => {
      if (!err) return;
      console.error("Error connecting to session", err);
    });
    return sess;
  };

  const updateUserSettings = (update: Partial<VideoRoomParameters>) => {
    const currentDevices :(string|null)[] = [];
    setUserSettings((prev) => {
      if (!prev) return prev;
      currentDevices.push(prev.audioDevice, prev.videoDevice);
      const next = Object.entries(update).reduce<VideoRoomParameters>((acc, [key, value]) => {
        if (typeof value === "undefined" || acc[key as keyof VideoRoomParameters] === value) return acc;
        return { ...acc, [key]: value } as VideoRoomParameters;
      }, prev);
      mutableState.current.userSettings = next;
      return next;
    });

    if (update.audioToggle !== undefined) mutableState.current.publisher?.publishAudio(update.audioToggle);
    if (update.videoToggle !== undefined) mutableState.current.publisher?.publishVideo(update.videoToggle);

    if (mutableState.current.publisher) {
      if (update.audioDevice) {
        if (typeof currentDevices[0] !== "string" || currentDevices[0] !== update.audioDevice) {
          mutableState.current.publisher?.setAudioSource(update.audioDevice);
        }
      }
      if (update.videoDevice !== undefined) {
        if (typeof currentDevices[1] !== "string" || currentDevices[1] !== update.videoDevice) {
          if (update.videoDevice !== null && currentDevices[1] !== null) {
            mutableState.current.publisher?.setVideoSource(update.videoDevice);
          } else {
            cyclePublisher();
          }
        }
      }
    }
  };

  useEffect(() => {
    if (!videoRoom?.sessionId || !videoRoom?.token || showReadyUpModal) return;
    const s = initSession(videoRoom.sessionId, videoRoom.token);
    return () => {
      s.then((sess) => sess?.disconnect());
    };
  }, [videoRoom?.sessionId, videoRoom?.token, showReadyUpModal]);

  useEffect(() => {
    if (!videoRoomStatus) return;
    switch (videoRoomStatus) {
      case "CANCELED":
        resetVideoRoom();
        break;
      default:
        break;
    }
  }, [videoRoomStatus]);

  // check if user has a newer session (ex: other device or tab)
  const checkSessionReplaced = (participants: VideoRoomParticipant[]) => {
    const self = participants.find((p) => p.isMe);
    if (!self?.connectionIds) return;
    if (self.connectionIds.length > 1 && mutableState.current.session?.connection?.connectionId !== self.connectionIds.at(-1)) {
      mutableState.current.session?.disconnect();
      setShowReadyUpModal(true);
    }
  };

  useEffect(() => {
    if (!videoRoom) return;

    const sub = ws.subscribe((msg: WSMessage) => {
      const payload = JSON.parse(msg.payload);
      if (payload.videoRoomUid !== videoRoom.videoRoomUid) return;

      switch (msg.event) {
        case "videoRoom.participants.updated":
          setVideoRoom((prev) => {
            if (!prev) return prev;
            return { ...prev, participants: payload.participants };
          });
          checkSessionReplaced(payload.participants);
          break;
        case "videoRoom.status.closed":
          resetVideoRoom();
          break;
        default: break;
      }
    });

    return () => sub.unsubscribe();
  }, [ws, videoRoom]);

  const initVideoRoom = (videoRoom: VideoRoomInfo) => {
    if (!connectivityDisabled) setShowReadyUpModal(true);
    setVideoRoomAndStatus(videoRoom);
  };

  const onUserReady = (videoRoom: VideoRoomInfo, parameters: VideoRoomParameters) => {
    setUserSettings(parameters);
    localStorage.setItem(VIDEO_SETTINGS_LS_KEY, JSON.stringify(parameters));
    mutableState.current.userSettings = parameters;
    setShowReadyUpModal(false);
  };

  const setVideoRoomAndStatus = (videoRoom: VideoRoomInfo) => {
    setVideoRoom(videoRoom);
    setStatus(videoRoom.status);
  };

  const endSession = () => {
    if (!mutableState.current.session) return;
    mutableState.current.session?.disconnect();
  };

  const closeVideoRoom = () => {
    if (!videoRoom) return;
    closeVideoRoomHandler(videoRoom.videoRoomUid).then(() => {
      resetVideoRoom();
    }).catch((err) => {
      console.error("Error closing video room", err);
    });
  };

  const resetVideoRoom = () => {
    setVideoRoom(undefined);
    setStatus(undefined);
    setShowReadyUpModal(false);
    setPublisherElement(undefined);
    setSubscriberItems([]);
    endSession();
  };

  const value = useMemo(() => ({
    appId,
    t,
    namespace,
    videoRoom: showReadyUpModal ? undefined : videoRoom,
    videoRoomStatus: showReadyUpModal ? undefined : videoRoomStatus,
    initVideoRoom,
    showReadyUpModal: showReadyUpModal ? videoRoom : undefined,
    onUserReady,
    userSettings,
    updateUserSettings,
    closeVideoRoom,
    resetVideoRoom,
    publisherElement,
    subscriberItems,
    connectivityDisabled,
    pushTelemetryEvent,
    forceCamera: !!forceCamera,
  }), [
    videoRoom, videoRoomStatus,
    showReadyUpModal,
    userSettings, updateUserSettings,
    publisherElement,
    subscriberItems,
    forceCamera,
  ]);

  return (
    <VideoContext.Provider value={value}>
      {children}
    </VideoContext.Provider>
  );
};

const useVideo = () => React.useContext(VideoContext);

export default VideoContext;
export { VideoProvider, useVideo };
