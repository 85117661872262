import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { initializeApp } from "firebase/app";
import {
  Auth,
  browserSessionPersistence,
  getAuth,
  indexedDBLocalPersistence,
  signInWithCustomToken,
  User,
} from "firebase/auth";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

interface AppConfigGCP {
  apiKey: string;
  authDomain: string;
  recaptchaKey?: string;
}

interface AppConfig {
  gcp: AppConfigGCP;
  app: {
    login: string;
    clientId: string;
    address: string;
  };
  apiBaseHost: string;
}

interface AuthContextType {
  user?: User;
  roles: string[];
  loading: boolean;
  hasAccess: (access: string[]) => boolean;
  login: (query?: string, reason?: string, redirectPath?: string) => void;
  signup: (query?: string) => void;
  logout: () => Promise<void>;
}

const AuthContext = React.createContext<AuthContextType>({
  roles: [],
  loading: true,
  hasAccess: () => false,
  login: (query?: string, reason?: string, redirectPath?: string) => undefined,
  signup: (query?: string) => undefined,
  logout: () => Promise.resolve(),
});

interface Props {
  config: AppConfig;
  addAuthInterceptor: (auth: Auth) => void;
  children: React.ReactNode;
}

const AuthProvider: React.FC<Props> = ({
  config,
  addAuthInterceptor,
  children,
}) => {
  const app = initializeApp(config.gcp);
  const auth = getAuth(app);
  auth.useDeviceLanguage();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useState<User>();
  const [roles, setRoles] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingToken, setLoadingToken] = useState(true);

  const tmpSession = searchParams.get("tmpSession");
  if (tmpSession) sessionStorage.setItem("tmpSession", "true");
  const tmpSessionStored = sessionStorage.getItem("tmpSession");
  const authToken = searchParams.get("authToken");
  auth.setPersistence((tmpSession || tmpSessionStored) ? browserSessionPersistence : indexedDBLocalPersistence);

  useEffect(() => {
    if (user) setLoading(false);
  }, [user]);

  const defaultValue = useMemo(
    () => ({
      user,
      roles,
      loading: loading || loadingToken,
      hasAccess: (checkAccess: string[]) => checkAccess.some((a) => {
        const p = a.split(":");
        if (p.length === 2 && p[1] === "*") return roles.find((i) => i.split(":")[0] === p[0]);
        return roles.find((i) => i === a);
      }),
      login: (query?: string, reason?: string, redirectPath?: string) => {
        const url = new URL(`${config.app.login}/login`);
        url.searchParams.append("client_id", config.app.clientId);
        url.searchParams.append("redirect_url", `${config.app.address}${redirectPath || location.pathname}${query || ""}`);
        if (reason) {
          url.searchParams.append("reason", reason);
        }
        window.location.href = url.toString();
      },
      signup: (query?: string) => {
        const url = new URL(`${config.app.login}/signup`);
        url.searchParams.append("client_id", config.app.clientId);
        url.searchParams.append("redirect_url", `${config.app.address}${location.pathname}${query || ""}`);
        window.location.href = url.toString();
      },
      logout: () => auth.signOut().then(() => {
        navigate("/");
        window.location.reload();
      }),
    }),
    [user, roles, loading, loadingToken, auth, location.pathname, config, navigate],
  );

  useEffect(() => {
    auth.onAuthStateChanged((u) => {
      if (u) {
        u.getIdTokenResult().then((res) => {
          // eslint-disable-next-line dot-notation
          setRoles((res.claims.roles as string[]) || []);
          addAuthInterceptor(auth);
          setUser(u);
        });
      } else {
        setLoading(false);
      }
    });
  }, [auth, setRoles, setUser, addAuthInterceptor, config.apiBaseHost]);

  useEffect(() => {
    if (!authToken) {
      setLoadingToken(false);
      return;
    }
    const getCustomToken = (authToken: string) => axios.get<{ customToken: string }>(`${config.apiBaseHost}/auth-api/v1/sessions/token`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    getCustomToken(authToken as string).then(({ data }) => {
      signInWithCustomToken(auth, data.customToken).then((i) => {
        setUser(i.user);
        setLoadingToken(false);
        searchParams.delete("authToken");
        setSearchParams(searchParams);
      });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, authToken, config.apiBaseHost]);

  return (
    <AuthContext.Provider value={defaultValue}>{children}</AuthContext.Provider>
  );
};

const useAuth = () => React.useContext(AuthContext);

export default AuthContext;
export { AuthProvider, useAuth };
