/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

interface Props {
  options: Option[];
  icon?: IconProp;
  error?: string;
  vertical?: boolean;
}

interface Option {name: React.ReactNode; value: string, multiline?: boolean; disabled?: boolean}

const Radio: React.FC<
  Props &
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>
> = ({
  options, vertical, ...props
}) => (
  <>
    <div className="mb-1">
      {props.required && "*"}
      {props.title}
    </div>
    <div className={`flex gap-3 ${vertical ? "flex-col" : ""}`}>
      {options.map((option, index) => (
        <div
          className="flex items-center gap-1"
          key={`${props.name}-${option.value}`}
        >
          <input
            {...props}
            disabled={option.disabled}
            id={`${props.name}-${option.value}`}
            type="radio"
            value={option.value}
            checked={props.value === option.value}
            className={clsx("h-4 w-4 mr-1 checked:accent-gray-500", props.className, option.multiline && "mt-1 self-baseline", !option.disabled && "cursor-pointer", "shrink-0")}
          />
          <label htmlFor={`${props.name}-${option.value}`} className={`${!option.disabled ? "cursor-pointer" : ""}`}>
            {props.icon && <FontAwesomeIcon icon={props.icon} className="mr-2" />}
            {option.name}
          </label>
        </div>
      ))}
    </div>
    {props.error && <div className="text-red-500">{props.error}</div>}
  </>
);

export default Radio;
