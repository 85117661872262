import { useEffect, useState } from "react";

interface AudioRecorderContext {
  audioFile: Blob;
  isRecording: boolean;
  startRecording: () => void;
  stopRecording: () => void;
}

const useRecorder = (): AudioRecorderContext => {
  const [audioFile, setAudioFile] = useState<Blob>();
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState<MediaRecorder | null>(null);

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error);
      }
      return;
    }

    const handleData = (e: {data: Blob}) => {
      setAudioFile(e.data);
    };

    if (isRecording) {
      recorder.addEventListener("dataavailable", handleData);
      recorder.start();
    } else {
      recorder.stop();
      recorder.stream.getTracks().forEach((t) => t.stop());
      recorder.removeEventListener("dataavailable", handleData);
      setRecorder(null);
    }
  }, [recorder, isRecording]);

  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  return {
    audioFile,
    isRecording,
    startRecording,
    stopRecording,
  };
};

async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  return new MediaRecorder(stream);
}
export default useRecorder;
