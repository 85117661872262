import clsx from "clsx";
import React from "react";

interface Props {
  status: string;
  label: string;
}

const TicketStatusTag: React.FC<Props> = ({ status, label }) => (
  <div className={clsx(
    ["RETURNED", "ANSWERED"].includes(status) && "bg-blue-100 text-blue-600 border-blue-400",
    ["IN_PROGRESS", "PENDING"].includes(status) && "bg-orange-100 text-orange-600 border-orange-400",
    status === "RESOLVED" && "bg-green-100 text-green-600 border-green-400",
    "py-1 px-2 w-fit h-fit text-xs rounded-md border uppercase ml-auto",
  )}
  >
    {label}
  </div>
);

export default TicketStatusTag;
