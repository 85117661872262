import { UserSession } from "@audacia-hq/shared/models";
import { Axios } from "axios";

class MarketingApiClient {
  private baseUrl;

  private pathUrl = "/marketing-api/v1";

  private context: string;

  private app: string;

  private axiosClient: Axios;

  constructor(baseUrl: string, context: string, app:string, axiosClient: Axios) {
    this.baseUrl = baseUrl;
    this.context = context;
    this.axiosClient = axiosClient;
    this.app = app;
  }

  public postUserSession(userSessionUid?: string, attributes?: { campaignUid?: string, }) {
    return this.axiosClient.post<UserSession>(
      `${this.baseUrl}${this.pathUrl}/user-sessions`,
      undefined,
      {
        params: { userSessionUid, ...(attributes || {}) },
        headers: { "x-application-context": this.context, "x-application": this.app },
      },
    ).then((res) => res.data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public postEvents(userSessionUid: string, events: any[]) {
    return navigator.sendBeacon(`${this.baseUrl}${this.pathUrl}/user-sessions/${userSessionUid}/events`, JSON.stringify(events));
  }
}

export default MarketingApiClient;
