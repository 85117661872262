/* eslint-disable dot-notation */
/* eslint-disable import/prefer-default-export */
import React, {
  useState,
} from "react";
import { useVideo, videoRoomIsOpen } from "@audacia-hq/shared/contexts";

import DevicePicker from "../form/DevicePicker";
import Button from "../Button";
import AlertModal from "../modal/AlertModal";
import useNavigatorMediaDevices from "../hooks/useNavigatorMediaDevices";

import { VideoRoomCloseButton } from "./VideoRoomCloseButton";

interface Props {
  size?: "small"
  display?: "vertical"
}

const getPickerClass = (size: string, display: string) => {
  if (display === "vertical") return "w-full";
  switch (size) {
    case "small": return "w-40";
    default: return "max-w-72";
  }
};

const commonButtonClass = "whitespace-nowrap";
const getButtonClass = (size: string, display: string) => {
  if (display === "vertical") return "w-full mt-6";
  switch (size) {
    case "small": return `${commonButtonClass} max-w-20`;
    default: return "";
  }
};

export const VideoRoomControls: React.FC<Props> = ({ size, display }) => {
  const {
    t, videoRoom, userSettings, updateUserSettings, closeVideoRoom, videoRoomStatus,
  } = useVideo();
  const { devices } = useNavigatorMediaDevices(["AUDIO", "VIDEO"]);

  const [closeConfirm, setCloseConfirm] = useState<boolean>(false);

  const onDeviceChange = (mediaType: "AUDIO" | "VIDEO") => (deviceId: string) => {
    if (mediaType === "AUDIO") {
      updateUserSettings({ audioDevice: deviceId });
    } else {
      updateUserSettings({ videoDevice: deviceId });
    }
  };

  return (
    <>
      {videoRoomIsOpen(videoRoomStatus) && (
        <>
          <DevicePicker
            mediaType="AUDIO"
            initialValue={userSettings?.audioDevice}
            devices={devices["AUDIO"]}
            onChange={onDeviceChange("AUDIO")}
            className={getPickerClass(size, display)}
          />
          <DevicePicker
            mediaType="VIDEO"
            initialValue={userSettings?.videoDevice}
            devices={devices["VIDEO"]}
            onChange={onDeviceChange("VIDEO")}
            className={getPickerClass(size, display)}
          />
          <VideoRoomCloseButton className={getButtonClass(size, display)} />
        </>
      )}

    </>
  );
};
