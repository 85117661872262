import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js';

export const formatNumber = (
  number: string,
  format: 'NATIONAL' | 'INTERNATIONAL',
  iso2?: string
) => {
  try {
    const phoneNumber = parsePhoneNumberFromString(number, iso2 as CountryCode ) ;
    if (phoneNumber && phoneNumber.isValid()) {
      return format === 'NATIONAL'
        ? phoneNumber.formatNational()
        : phoneNumber.formatInternational();
    }
    return number;
  } catch (err) {
    return number;
  }
};
