import React, {
  Fragment, useEffect, useMemo, useRef, useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faInfo } from "@fortawesome/free-solid-svg-icons";

interface Props {
  open: boolean;
  title: string;
  validateLabel?: string;
  cancelLabel?: string;
  variant?: "info" | "warning";
  playsong?: boolean;
  disableClosingOverlayEvent?: boolean;
  fullWidth?: boolean;
  onValidate: () => void;
  onClose: () => void;
}

const AlertModal: React.FC<React.PropsWithChildren<Props>> = ({
  open, title, validateLabel, cancelLabel, onValidate, onClose, children,
  variant = "info", playsong = false, disableClosingOverlayEvent = false, fullWidth = false,
}) => {
  // eslint-disable-next-line no-undef
  const [audioInterval, setAudioInterval] = useState<NodeJS.Timeout>();
  const cancelButtonRef = useRef(null);

  const renderVariantIcon = () => {
    switch (variant) {
      case "warning":
        return (
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <FontAwesomeIcon icon={faExclamation} className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
        );
      case "info":
      default:
        return (
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
            <FontAwesomeIcon icon={faInfo} className="h-6 w-6 text-blue-600" aria-hidden="true" />
          </div>
        );
    }
  };

  const getVariantClass = () => {
    switch (variant) {
      case "warning":
        return "bg-red-600 hover:bg-red-500";
      case "info":
      default:
        return "bg-blue-600 hover:bg-blue-500";
    }
  };

  useEffect(() => {
    if (open && playsong) {
      const audio = new Audio("/assets/notification.wav");
      const interval = setInterval(() => {
        audio.load();
        audio.play().catch(() => ({}));
      }, 3000);
      setAudioInterval(interval);
    } else {
      if (audioInterval) clearInterval(audioInterval);
      setAudioInterval(undefined);
    }
    return () => audioInterval && clearInterval(audioInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, playsong]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        open={open}
        initialFocus={cancelButtonRef}
        onClose={() => !disableClosingOverlayEvent && onClose()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-[60] bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-[61] overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full ${fullWidth ? "mx-8" : "sm:max-w-lg"}`}>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 max-h-[500px] overflow-y-auto">
                  <div className="sm:flex sm:items-start">
                    {renderVariantIcon()}
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {title}
                      </Dialog.Title>
                      <div className="mt-2 text-sm text-gray-500">
                        {children}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  {onValidate && (
                    <button
                      type="button"
                      data-pw="modalAccept"
                      className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto ${getVariantClass()}`}
                      onClick={() => onValidate()}
                    >
                      {validateLabel}
                    </button>
                  )}
                  {cancelLabel && (
                    <button
                      type="button"
                      data-pw="modalCancel"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => onClose()}
                      ref={cancelButtonRef}
                    >
                      {cancelLabel}
                    </button>
                  )}

                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AlertModal;
