import React from "react";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Spinner: React.FC = () => (
  <div className="flex items-center h-16">
    <FontAwesomeIcon className="text-slate-500 mx-auto p-4 h-8" icon={faCircleNotch} spin size="3x" />
  </div>
);

export default Spinner;
