/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";

import { CalendarRange } from "./Calendar";
import { useCalendar } from "./CalendarContext";

interface Props {
  range: CalendarRange;
  isInteracting?: boolean;
  zIndex?: number;
}

const CalendarPosition: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  range,
  isInteracting,
  zIndex,
}) => {
  const { days } = useCalendar();
  const { start, end } = range;
  const rowStart = 1 + Math.floor((start.hour() * 60 + start.minute()) / 15);
  const rowSpan = Math.ceil(end.diff(start, "minutes") / 15) || 1;
  const colStart = 1 + (days.findIndex((d) => !start.isBefore(d) && !end.isAfter(d.add(1, "day"))) || 0);

  return (
    <div
      className="relative flex pointer-events-none"
      style={{
        gridRow: `${rowStart} / span ${rowSpan}`,
        gridColumn: `${colStart} / span 1`,
        zIndex: (isInteracting ? 20 : 0) + (zIndex || 0),
      }}
      onMouseDown={(e) => { e.stopPropagation(); }}
      onClick={(e) => { e.stopPropagation(); }}
    >
      {children}
    </div>
  );
};

export default CalendarPosition;
