import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import axios from "axios";

import config from "./modules/common/services/config";

const missingKeyHandler = (
  lngs: readonly string[],
  ns: string,
  key: string,
) => {
  axios.post(`${config.api.labelBaseHost}/label-api/v1/labels`, {
    namespace: ns,
    key,
    type: "label",
  }, {
    headers: {
      "x-application-context": "iv-expert",
      "x-label-api-key": config.labelApiKey,
    },
  });
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpApi)
  .init({
    supportedLngs: ["fr", "de"],
    react: {
      useSuspense: true,
    },
    backend: {
      loadPath: config.i18nBackend,
      customHeaders: () => ({
        "x-application-context": "iv-expert",
      }),
    },
    defaultNS: "common",
    load: "languageOnly",
    fallbackLng: "fr",
    missingKeyHandler: process.env.NODE_ENV === "development" ? missingKeyHandler : undefined,
    saveMissing: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
