import * as React from "react";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

interface TabContextType {
  tab?: string;
  subTab?: string;
  setTab: (tab: string) => void;
  setSubTab: (subTab: string) => void;
}

const TabContext = React.createContext<TabContextType>({
  tab: undefined,
  subTab: undefined,
  setTab: (tab: string) => { /* do nothing */ },
  setSubTab: (subTab: string) => { /* do nothing */ },
});

interface Props {
  children: React.ReactNode;
}

const TabProvider: React.FC<Props> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultValue = useMemo(
    () => ({
      tab: searchParams.get("tab") || undefined,
      subTab: searchParams.get("subTab") || undefined,
      setTab: (tab: string) => {
        if (!tab) {
          searchParams.delete("tab");
          searchParams.delete("subTab");
          setSearchParams(searchParams, { replace: true });
        } else {
          searchParams.set("tab", tab);
          searchParams.delete("subTab");
          setSearchParams(searchParams, { replace: true });
        }
      },
      setSubTab: (subTab: string) => {
        if (!subTab) {
          searchParams.delete("subTab");
          setSearchParams(searchParams, { replace: true });
        } else {
          searchParams.set("subTab", subTab);
          setSearchParams(searchParams, { replace: true });
        }
      },
    }),
    [searchParams, setSearchParams],
  );

  return (
    <TabContext.Provider value={defaultValue}>{children}</TabContext.Provider>
  );
};

const useTab = () => React.useContext(TabContext);

export default TabContext;
export { TabProvider, useTab };
