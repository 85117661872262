import clsx from "clsx";
import * as React from "react";

interface Props {
  variant?: "default" | "blue" | "yellow" | "pink";
  size?: "default" | "large" | "medium" | "small" | "tiny";
  backgroundColor?: string;
  progress: number;
  rounded?: boolean;
}

/**
 * Progress bar
 * @param variant Variant of the component
 * @param size Size of the component
 * @param backgroundColor Custom color for the background
 * @param progress Progress percentage (%) of the component. If > 100, the value will be 100
 * @returns React.FC<Props>
 */
const ProgressBar: React.FC<Props> = ({
  variant = "default",
  size = "default",
  backgroundColor = "bg-gray-200",
  progress = 0,
  rounded = true,
}) => (
  <div className={clsx(
    "w-full",
    rounded && "rounded-full",
    backgroundColor,
  )}
  >
    <div
      className={clsx(
        "text-xs font-medium text-blue-100 text-center leading-none",
        rounded && "rounded-full",
        variant === "default" && "bg-green-600",
        variant === "blue" && "bg-blue-600",
        variant === "yellow" && "bg-yellow-400",
        variant === "pink" && "bg-pink-500",
        size === "default" && "p-2",
        size === "tiny" && "p-0.5",
        size === "small" && "p-1",
        size === "medium" && "p-1.5",
        size === "large" && "p-3",
      )}
      style={{ width: `${progress > 100 ? 100 : progress}%` }}
    />
  </div>
);

export default ProgressBar;
