/* eslint-disable import/prefer-default-export */

export const unifiedEventListener = (event) => ({
  value: {
    x: event?.x,
    y: event?.y,
    pageX: event?.pageX,
    pageY: event?.pageY,
    visible: !document?.hidden,
  },
  page: {
    viewportWidth: window?.innerWidth,
    viewportHeight: window?.innerHeight,
    url: window?.location?.href,
    title: document?.title,
    userAgent: navigator?.userAgent?.toLowerCase(),
  },
});
