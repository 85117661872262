import React from "react";
import { useTranslation } from "react-i18next";
import { useField } from "formik";
import { Question } from "@audacia-hq/shared/models";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";

interface Props {
  question: Question;
}

const NumericQuestion: React.FC<Props> = ({
  question,
}) => {
  const { t } = useTranslation("survey");
  const [props, meta] = useField("value");

  return (
    <>
      <div className="flex flex-col">
        <h1 className="text-xl font-semibold pb-3">{question.title}</h1>
        {question.content && question.showDescription && <p className="whitespace-pre-wrap text-gray-500 pb-6">{question.content}</p>}
        <InputNumber
          name={`${props.name}.0`}
          onChange={props.onChange}
          error={t(meta.error, { min: question.settings.min, max: question.settings.max })}
        />
      </div>
    </>
  );
};

export default NumericQuestion;
