import React from "react";
import { useTranslation } from "react-i18next";
import { useField } from "formik";
import { Question } from "@audacia-hq/shared/models";

import Input from "../../form/Input";

interface Props {
  question: Question;
}

const ShortTextQuestion: React.FC<Props> = ({
  question,
}) => {
  const { t } = useTranslation("survey");
  const [props, meta] = useField("value");

  return (
    <>
      <div className="flex flex-col">
        <h1 className="text-xl font-semibold pb-3">{question.title}</h1>
        {question.content && question.showDescription && <p className="whitespace-pre-wrap text-gray-500 pb-6">{question.content}</p>}
        <Input
          type="text"
          name={`${props.name}.0`}
          className=""
          onChange={props.onChange}
          error={t(meta.error, { max: question.settings.max })}
          maxLength={question.settings.max}
        />
        {
          question.settings.max && (
            <p className="ml-auto text-gray-500 pt-2">
              {props.value?.[0]?.length || 0}
              /
              {question.settings.max}
            </p>
          )
        }
      </div>
    </>
  );
};

export default ShortTextQuestion;
