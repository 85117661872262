/* eslint-disable dot-notation */
import React, { useCallback, useEffect, useState } from "react";
import { SubscriberItem, useVideo } from "@audacia-hq/shared/contexts";
import type OT from "@opentok/client";

import Avatar from "../Avatar";

interface Props {
  user: { userUid: string; name: string; imageUrl?: string };
  subscriberItem?: SubscriberItem;
}

const VideoSubscriber: React.FC<Props> = ({ user, subscriberItem }) => {
  const {
    t,
  } = useVideo();

  const [videoEnabled, setVideoEnabled] = React.useState(false);
  const audioLevelElt = React.useRef<HTMLElement>(null);

  const videoOff = () => {
    setVideoEnabled(false);
  };

  const videoOn = () => {
    setVideoEnabled(true);
  };

  // log + clamp [0, 1]
  const transformAudioLevel = (level: number) => Math.min(
    Math.max(
      (Math.log(level) / Math.LN10) / 1.5 + 1,
      0,
    ),
    1,
  );

  useEffect(() => {
    setVideoEnabled(!!subscriberItem?.stream?.hasVideo);
  }, [subscriberItem?.stream?.hasVideo]);

  useEffect(() => {
    if (!subscriberItem?.subscriber) return;
    attachListeners(subscriberItem.subscriber);
    // eslint-disable-next-line consistent-return
    return () => {
      detachListeners(subscriberItem.subscriber);
    };
  }, [subscriberItem]);

  const updateAudioLevel = (event) => {
    if (!audioLevelElt?.current) return;
    audioLevelElt.current.style.boxShadow = `0 0 0 ${transformAudioLevel(event.audioLevel) * 15}px  #FFFFFF45`;
  };

  const attachListeners = (sub: OT.Subscriber | null) => {
    if (!sub) return;
    sub.on("videoDisabled", videoOff);
    sub.on("videoEnabled", videoOn);
    sub.on("audioLevelUpdated", updateAudioLevel);
  };

  const detachListeners = (sub: OT.Subscriber | null) => {
    if (!sub) return;
    sub.off("videoDisabled", videoOff);
    sub.off("videoEnabled", videoOn);
    sub.off("audioLevelUpdated", updateAudioLevel);
  };

  return (
    <div className="w-full h-full bg-black">
      { subscriberItem?.element && !!subscriberItem?.subscriber.stream?.hasVideo && (
        <div
          className="relative flex w-full h-full"
          ref={(elt) => {
            if (elt) {
              elt.append(subscriberItem.element);
            }
          }}
        />
      )}
      { (!subscriberItem?.element || !videoEnabled) && (
        <div className="w-full h-full flex place-content-center items-center">
          <div className="relative h-1/3 aspect-square">
            <div
              className="relative rounded-full h-full aspect-square transition-shadow duration-300 ease-out"
              ref={(elt) => { audioLevelElt.current = elt; }}
            >
              <Avatar
                uid={user.userUid}
                src={user.imageUrl}
                alt={user.name}
                name={user.name}
                className="rounded-full border-2 border-gray-200 aspect-square"
                height="100%"
                style={{
                  minHeight: "30px",
                }}
              />
            </div>
            {!subscriberItem?.element && (
              <div className="absolute left-0 right-0 -bottom-8 flex flex-row flex-nowrap gap-2 items-center justify-center">
                <div className="h-2 w-2 rounded-full bg-red-500" />
                <div className="whitespace-nowrap text-white">
                  {t("video.subscriber.offline")}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoSubscriber;
