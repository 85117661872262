import React from "react";
import { Question } from "@audacia-hq/shared/models";

interface Props {
  question: Question;
}

const InformationQuestion: React.FC<Props> = ({
  question,
}) => (
  <>
    <div className="flex flex-col">
      <h1 className="text-xl font-semibold text-center pb-3">{question.title}</h1>
      {question.showDescription && <p className="whitespace-pre-wrap text-gray-500">{question.content}</p>}
    </div>
  </>
);

export default InformationQuestion;
