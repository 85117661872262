import React, { useEffect } from "react";
import { useVideo } from "@audacia-hq/shared/contexts";

import VideoSubscriber from "./VideoSubscriber";

interface Props {
  users: Record<string, { userUid: string; name: string; imageUrl?: string }>;
}

const VideoSubscribers: React.FC<Props> = ({ users }) => {
  const { videoRoom, subscriberItems } = useVideo();

  return (
    <>
      {videoRoom.participants.map((p) => {
        if (p.isMe) return null;
        return (
          <VideoSubscriber
            key={p.userUid}
            user={users[p.userUid]}
            subscriberItem={subscriberItems.find((s) => s.data.userUid === p.userUid)}
          />
        );
      })}
    </>
  );
};

export default VideoSubscribers;
