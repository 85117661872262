import {
  faComments, faPen, faPhone, faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import * as React from "react";

interface Props {
  channel: "PHONE" | "LIVECHAT" | "WRITTEN" | "VIDEO"
}

const ChannelIcon: React.FC<Props & Omit<FontAwesomeIconProps, "icon">> = ({ channel, ...props }) => {
  const getConsultationIcon = (channel: string) => {
    switch (channel) {
      case "LIVECHAT":
        return faComments;
      case "PHONE":
        return faPhone;
      case "VIDEO":
        return faVideo;
      case "WRITTEN":
      default:
        return faPen;
    }
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <FontAwesomeIcon icon={getConsultationIcon(channel)} {...props} />;
};

export default ChannelIcon;
