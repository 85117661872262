"use client";

/* eslint-disable consistent-return */
import * as React from "react";
import { CSSTransition } from "react-transition-group";
import clsx from "clsx";

import ClientOnlyPortal from "./ClientOnlyPortal";

export interface ModalProps {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  show?: boolean;
  mobileFullscreen?: boolean;
  disableOverlayEvent?: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  size?: "extraSmall" | "small" | "medium" | "large" | "default";
  /**
   * @default 0
   * @description z-index delta, adds to the default value (50)
   */
  zDelta?: number;
}

const Modal: React.FC<ModalProps> = ({
  title, subtitle, show, mobileFullscreen = true, disableOverlayEvent = false, onClose, children, size = "default", zDelta = 0,
}) => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    const body = document.querySelector("body");

    if (!body) return null;

    if (show) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
    return () => { body.style.overflow = "auto"; };
  }, [show]);

  const close = () => {
    const body = document.querySelector("body");
    if (!body) return;
    body.style.overflow = "auto";

    if (onClose) onClose();
  };

  return (
    <ClientOnlyPortal selector="#modal">
      <CSSTransition
        in={show}
        nodeRef={ref}
        timeout={300}
        classNames="modal"
        unmountOnExit
      >
        <div
          className="fixed right-0 left-0 top-0 h-full"
          ref={ref}
          style={{ zIndex: 50 + zDelta }}
        >
          <div
            className="modal-bg w-full h-full absolute z-[51] bg-gray-800/75"
            onClick={!disableOverlayEvent && onClose ? close : () => ({})}
            onKeyDown={() => ({})}
          />
          <div
            className={clsx(
              "modal-block rounded-lg",
              mobileFullscreen ? "h-full sm:h-fit sm:max-h-[80%] sm:mt-10" : "h-fit mt-[40%] sm:mt-[20%] md:mt-10",
              "overflow-y-auto w-full absolute z-[52] right-0 left-0 mx-auto",
              size === "default" && "sm:max-w-2xl",
              size === "small" && "sm:max-w-xl",
              size === "medium" && "sm:max-w-4xl",
              size === "large" && "sm:max-w-6xl",
            )}
          >
            <div className="bg-white shadow rounded-lg z-[60] min-h-full flex-row relative">
              {onClose && (
                <button
                  type="button"
                  data-pw="modalClose"
                  className="text-gray-400 bg-transparent h-fit hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center absolute top-4 right-4"
                  onClick={close}
                >
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                </button>
              )}
              {(title || subtitle) && (
                <div className={`flex flex-col justify-between items-middle p-6 ${title && "border-b"}`}>
                  {title && (
                    <h3 className="text-xl font-semibold lg:text-2xl">
                      {title}
                    </h3>
                  )}
                  {subtitle && (
                    <p className="text-gray-400 text-sm font-light pt-1">
                      {subtitle}
                    </p>
                  )}
                </div>
              )}

              <div className="modal-body block overflow-y-scroll p-6">
                {children}
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </ClientOnlyPortal>
  );
};

export default Modal;
