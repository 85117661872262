import { useMutation } from "@apollo/client";
import SharedComponents, { visitorSubjects } from "@audacia-hq/shared/components";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ITicketInput } from "@audacia-hq/shared/models";
import { generateFileName } from "@audacia-hq/shared/utils";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useAuth } from "@audacia-hq/shared/contexts";
import { useNavigate } from "react-router-dom";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CREATE_TICKET } from "../../graphql/mutations";
import i18n from "../../i18n";
import { uploadPrivateFile } from "../common/services/apiClients/storageAPI";
import Logo from "../common/components/Logo";
import Loader from "../common/components/Loader";

const SupportForm: React.FC = () => {
  const { t } = useTranslation(["support", "common"]);
  const [createTicket, { data }] = useMutation<{ supportMutations: { createTicket: { ticketUid } } }>(CREATE_TICKET);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { user, loading } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) return;
    if (user) navigate("/support/request");
  }, [user, loading]);

  const handleCreateTicket = async (ticketInput: ITicketInput) => createTicket({
    variables: {
      createTicketInput: {
        ...ticketInput,
        ...({ recaptchaToken: await executeRecaptcha("createSupportTicket") }),
      },
    },
  });

  const uploadFiles = async (files: File[]) => {
    try {
      return await Promise.all(files.map((f) => uploadPrivateFile(f, generateFileName(f), "support/attachments", [])));
    } catch (e) {
      return [];
    }
  };

  if (loading) return <Loader />;

  return (

    <div className="max-w-3xl mx-auto my-auto space-y-6 p-6">
      <Logo className="w-[400px] mx-auto" domain={i18n.languages[0].toLowerCase().startsWith("fr") ? "idealvoyance.com" : "manticus.com"} />
      <h1 className="text-2xl text-center font-semibold leading-tight tracking-tight text-gray-900">{t("tickets.requestForm")}</h1>

      {data ? (
        <div className="wrapper max-w-screen-md py-3 px-6 space-x-4 flex items-center" data-pw="ticketSentSuccessfully">
          <div><FontAwesomeIcon icon={faPaperPlane} size="xl" /></div>
          <div data-pw="ticketSentSuccessfully">{t("tickets.sentSuccessfully")}</div>
        </div>
      ) : (
        <>
          <div className="text-xs mb-8 text-center">{t("tickets.createSubtitle")}</div>
          <SharedComponents.SupportRequestForm
            createTicket={handleCreateTicket}
            language={i18n.languages[0].toUpperCase()}
            subjects={visitorSubjects}
            uploadFiles={uploadFiles}
            t={t}
          />

        </>
      )}
    </div>
  );
};

export default SupportForm;
