import React, { useEffect, useRef, useState } from "react";

interface TabType {
  key: string;
  name: string;
  icon?: React.ReactNode;
  count?: number;
  children: React.ReactNode
}

interface TabsProps {
  active: string;
  tabs: TabType[];
  onSelect: (key: string) => void
  noWrap?: boolean;
}

const Tabs: React.FC<TabsProps> = ({
  active, tabs, onSelect, noWrap,
}) => {
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const tabsRef = useRef([]);

  useEffect(() => {
    const setTabPosition = () => {
      const currentTab = tabsRef.current[active];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    };

    setTabPosition();
    window.addEventListener("resize", setTabPosition);

    return () => window.removeEventListener("resize", setTabPosition);
  }, [active, tabs]);

  return (
    <div className="block">
      <div className="relative border-b border-slate-200">
        <nav className="-mb-px flex gap-x-3" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              type="button"
              key={tab.name}
              className={`${noWrap ? "mr-2" : "w-1/4"} border-b-2 py-2 px-1 text-center text-sm font-medium border-transparent 
              text-slate-500 hover:border-slate-300 hover:text-slate-700`}
              aria-current={tab.key === active ? "page" : undefined}
              ref={(el) => { tabsRef.current[tab.key] = el; }}
              onClick={() => onSelect(tab.key)}
            >
              <div className="flex gap-x-2 gap-y-3 justify-center items-center">
                {tab.icon && <div className="text-xl">{tab.icon}</div>}
                <div className={`${tab.icon ? "hidden md:inline-block" : ""} ${noWrap ? "whitespace-nowrap" : ""}`}>{tab.name}</div>
              </div>
              <div
                className={`
                    ${tab.key === active ? "bg-indigo-100 text-indigo-600" : "bg-gray-100 text-gray-900"} hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block`}
              >
                {tab.count ? tab.count : 0}
              </div>
            </button>
          ))}
          <span
            className="absolute bottom-0 block h-1 bg-indigo-600 transition-all duration-300"
            style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
          />
        </nav>
      </div>
      <div className="mt-3">
        {tabs.find((t) => t.key === active).children}
      </div>
    </div>
  );
};

export default Tabs;
