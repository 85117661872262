// Combines the new livechat system and the old @chatscope/chat-ui-kit-react system. This should be as generic as possible, but self contained.

import Message from "./Message";
import SystemMessage from "./SystemMessage";
import Header from "./Header";
import TypingIndicator from "./TypingIndicator";
import MessageInput from "./MessageInput";
// eslint-disable-next-line import/no-cycle
import ChatView from "./ChatView";

const Chat = {
  Message,
  SystemMessage,
  Header,
  TypingIndicator,
  MessageInput,
  ChatView,
};

export default Chat;
