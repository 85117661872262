/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

interface Props {
  label: string;
  icon?: IconProp;
  error?: string
}

const Checkbox: React.FC<
Props &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
> = ({ className, ...props }) => (
  <div className="flex items-center">
    <input
      {...props}
      type="checkbox"
      className={clsx("h-4 w-4 mr-1 checked:accent-gray-500", className)}
    />
    <label {...(props.id ? { htmlFor: props.id } : {})}>
      {props.icon && <FontAwesomeIcon icon={props.icon} className="mr-2" />}

      {props.label}
    </label>
    {props.error && <div className="text-red-500 ml-2">{props.error}</div>}
  </div>
);

export default Checkbox;
