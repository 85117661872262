/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import clsx from "clsx";

interface Props {
  label?: string;
  hint?: string;
  error?: string;
}

const inputBase = "text-sm focus:outline-none w-full py-2 pr-10 pl-3 border border-gray-300 rounded-md disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200";
const inputError = "border-red-300";
const inputDisabled = "bg-gray-50 text-gray-500 border-gray-200";

const Input: React.FC<
  Props &
    React.DetailedHTMLProps<
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >
> = ({
  label, hint, error, ...props
}) => (
  <label htmlFor={props.id}>
    <span className="block mb-1">{label}</span>
    {hint && <span className="mb-2 block text-xs">{hint}</span>}
    <textarea
      {...props}
      className={clsx(inputBase, error && inputError, props.disabled && inputDisabled, props.className)}
      rows={props.rows || 4}
    />
    {error && <div className="text-red-500">{error}</div>}
  </label>
);

export default Input;
