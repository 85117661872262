/* eslint-disable dot-notation */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */

import EventEmitter from "events";

import React, {
  useEffect, useMemo, useRef, useState,
} from "react";
import * as Twilio from "@twilio/voice-sdk";

type RTCEventEmitter = EventEmitter;

interface RTCContextValue {
  // Is silent mode
  isSilent: boolean;
  setIsSilent: (isSilent: boolean) => void;

  // Mute
  isMuted: boolean;
  mute: (isMuted: boolean) => void;

  // Device control
  device: Twilio.Device | null;
  setMode: (silent: boolean) => void;
  state: "ready" | "preparing" | "invalid"
  hasToken: boolean;

  // Call control
  activeCall: Twilio.Call | null;
  engageCall: () => void;
  refuseCall: () => void;
  hangupCall: () => void;
  callState: "ringing" | "connected" | "invalid";

  // Media device control
  inputDevices: MediaDeviceInfo[];
  audioDeviceId: string | null;
  setAudioDevice: (deviceId: string) => void;
  requestAudioPermission: () => Promise<boolean>;

  // Events
  events: RTCEventEmitter;
}

const empty = () => { /* empty */ };
const resolve = (v: any) => () => Promise.resolve(v);

const RTCContext = React.createContext<RTCContextValue>({
  isSilent: false,
  setIsSilent: empty,
  isMuted: false,
  mute: empty,
  device: null,
  hasToken: false,
  setMode: empty,
  state: "invalid",
  activeCall: null,
  engageCall: empty,
  refuseCall: empty,
  hangupCall: empty,
  callState: "invalid",
  inputDevices: [],
  setAudioDevice: empty,
  audioDeviceId: null,
  requestAudioPermission: resolve(false),
  events: new EventEmitter({ captureRejections: true }),
});

// Non-twilio debugging
type RTCTraceEventsGeneric = "requestAudioPermission" | "audioPermissionGranted" | "audioPermissionDenied";
// Call debugging
type RTCTraceEventsCall = "callRinging" | "callAccepted" | "callDeclined" | "callEnded" | "callMuted" | "callUnmuted";
// Device debugging
type RTCTraceEventsDevice = "deviceCreated" | "deviceRegistered" | "deviceUnregistered" | "deviceError" | "deviceDestroyed" | "deviceTokenWllExpire" | "deviceTokenAndOptionsUpdated" | "deviceNonceUpdated";
// Audio handle debugging
type RTCTraceEventsHandle = "audioHandleCreated" | "audioHandleDestroyed" | "audioHandleCloneCreated" | "audioHandleCloneDestroyed" | "contextResumed";
// Published state debugging
type RTCTraceEventsState = "publishedState";
// Every now and again, we poll with new debugging information
type RTCTraceEventsPoll = "polling";
type RTCTraceEvent = RTCTraceEventsGeneric | RTCTraceEventsCall | RTCTraceEventsDevice | RTCTraceEventsHandle | RTCTraceEventsState | RTCTraceEventsPoll;
interface RTCTraceObject {
  event: RTCTraceEvent;
  metadata?: object;
}

interface RTCProviderProps {
  token: string;
  onTokenAboutToExpire?: () => void;
  debug?: boolean;
  tracing?: boolean;
  onTrace?: (trace: RTCTraceObject) => void;
}

const RTCProvider: React.FC<React.PropsWithChildren<RTCProviderProps>> = ({
  token,
  onTokenAboutToExpire,
  children,
  debug,
  tracing,
  onTrace,
}) => {
  // Use refs and state to be safer?
  const [audioHandle, setAudioHandle] = useState<MediaStream | null>(null);
  const audioHandleRef = useRef<MediaStream | null>(null);

  const [audioDeviceId, setAudioDeviceId] = useState<string | null>(null);
  const audioDeviceIdRef = useRef<string | null>(null);

  const [isMuted, setIsMuted] = useState<boolean>(false);
  const isMutedRef = useRef<boolean>(false);

  const [activeDevice, setActiveDevice] = useState<Twilio.Device | null>(null);
  const activeDeviceRef = useRef<Twilio.Device | null>(null);

  const [activeCall, setActiveCall] = useState<Twilio.Call | null>(null);
  const activeCallRef = useRef<Twilio.Call | null>(null);

  const [isSilent, setIsSilent] = useState<boolean>(false);
  const [inputDevices, setInputDevices] = useState<MediaDeviceInfo[]>([]);
  const [state, setState] = useState<"ready" | "preparing" | "invalid">("invalid");
  const [callState, setCallState] = useState<"ringing" | "connected" | "invalid">("invalid");

  // Internal value to initiate a device creation.
  const _createNonce = () => Math.random().toString(36).substring(7);
  const [nonce, setNonce] = useState<string>(_createNonce());

  const nonceUpdate = () => {
    const nonceId = _createNonce();
    setNonce(nonceId);
  };

  const events = useMemo(() => new EventEmitter({ captureRejections: true }), []);

  // #region Debugging
  const _trace = (
    event: RTCTraceEvent,
    metadata?: object,
  ) => {
    if (!onTrace || !tracing) return;
    onTrace({
      event,
      metadata: metadata ? Object.fromEntries(Object.entries(metadata).map(([key, value]) => [key, value ?? ""])) : {},
    });
  };
  // #endregion

  // #region Boilerplate
  const getMediaStream = async (deviceId?: string | null) => {
    // ensure we cleanup before we attempt to capture a new stream
    if (audioHandleRef.current) stopMediaStream(audioHandleRef.current);
    const audioStream = await navigator.mediaDevices.getUserMedia({ audio: deviceId ? { deviceId } : true });
    setAudioHandle(audioStream);
    audioHandleRef.current = audioStream;
    _trace("audioHandleCreated", {
      id: audioStream.id,
      tracks: audioStream.getTracks().map((track) => track.id),
    });
    _maybeCreateVolumeEmitter(audioStream);
    return audioStream;
  };

  /** Wrapper for getMediaStream with no device ID. */
  const getDefaultMediaStream = async () => getMediaStream(null);

  const stopMediaStream = (mediaStream: MediaStream) => {
    mediaStream.getTracks().forEach((track) => {
      track.stop();
    });
    _trace("audioHandleDestroyed", {
      id: mediaStream.id,
      tracks: mediaStream.getTracks().map((track) => track.id),
    });
  };

  const changeMic = async (deviceId?: string | null) => {
    if (audioHandleRef.current) {
      stopMediaStream(audioHandleRef.current);
    }
    setAudioDeviceId(deviceId || null);
    audioDeviceIdRef.current = deviceId || null;

    if (deviceId) await getMediaStream(deviceId);
    else await getDefaultMediaStream();
  };

  const _toggleMic = async () => {
    if (!isMutedRef.current) {
      _trace("callMuted");
      setIsMuted(true);
      isMutedRef.current = true;
    } else {
      _trace("callUnmuted");
      setIsMuted(false);
      isMutedRef.current = false;
    }
  };

  const createSoundOptions = (silent = false) => {
    if (!silent) return { incoming: "/assets/notification.wav" };
    // Must be valid audio data
    const silentAudio = "data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA";
    return {
      incoming: silentAudio,
      outgoing: silentAudio,
      disconnect: silentAudio,
      // Twilio dtmf
      dtmf0: silentAudio,
      dtmf1: silentAudio,
      dtmf2: silentAudio,
      dtmf3: silentAudio,
      dtmf4: silentAudio,
      dtmf5: silentAudio,
      dtmf6: silentAudio,
      dtmf7: silentAudio,
      dtmf8: silentAudio,
      dtmf9: silentAudio,
      dtmfstar: silentAudio,
      dtmfhash: silentAudio,
    };
  };

  // Either get the current stream or the default one.
  const getUserMedia = async (constraints: MediaStreamConstraints) => {
    const audioStream = audioHandleRef.current || await getDefaultMediaStream();
    return audioStream;
  };

  const _createOptions = (silent: boolean) => ({
    closeProtection: true,
    enableImprovedSignalingErrorPrecision: true,
    logLevel: "silent" as const,
    sounds: createSoundOptions(silent),
    getUserMedia,
  });

  const _createDevice = (token: string, silent: boolean) => {
    const device = new Twilio.Device(token, _createOptions(silent));

    return device;
  };

  const _handleAudioDeviceChange = async (device: Twilio.Device, deviceId?: string | null) => {
    // Establish audio track
    await changeMic(deviceId);

    // Notify device with current
    const newDeviceId = audioHandleRef.current?.getAudioTracks()[0]?.getSettings().deviceId;
    if (device && newDeviceId) device.audio?.setInputDevice(newDeviceId);
  };

  const _handleAudioToggle = async (device?: Twilio.Device | null, call?: Twilio.Call | null) => {
    if (!call) return;

    // Toggle audio
    await _toggleMic();

    // Notify device
    if (call) call?.mute(isMutedRef.current);
  };

  const _repairActiveCall = () => {
    // If we have an active call
    if (activeCallRef.current?.status() === "closed") {
      setActiveCall(null);
      activeCallRef.current = null;
      setCallState("invalid");

      // We're busy, but our active call was closed.
      if (activeDeviceRef.current?.isBusy) {
        // Take control of the maintained, open/pending call.
        const controlledCall = activeDeviceRef.current?.calls?.find((call) => call.status() !== "closed");
        if (controlledCall) {
          setActiveCall(controlledCall);
          activeCallRef.current = controlledCall;
          setCallState(
            controlledCall.status() === "pending" ? "ringing" : "connected",
          );
        }
      }
    }
  };

  const _addDeviceListeners = (device?: Twilio.Device) => {
    if (!device) return empty;

    const handleDeviceRegistered = () => {
      // console.log("Device registered");
      setState("ready");
      setActiveDevice(device);
      activeDeviceRef.current = device;

      _trace("deviceRegistered", {
        identity: device.identity,
        state: device.state,
      });
    };

    const handleConnect = async (connection: Twilio.Call) => {
      setActiveCall(connection);
      activeCallRef.current = connection;
      setCallState("connected");

      _trace("callAccepted", {
        call: connection?.parameters["CallSid"],
        direction: connection?.direction,
        status: connection?.status(),
        replaces: activeCallRef.current?.parameters["CallSid"],
        by: "device",
      });

      // Ensure active device is configured properly
      await _handleAudioDeviceChange(device, audioDeviceIdRef.current);
    };

    const handleIncomingCall = (candidate: Twilio.Call) => {
      // Repairs any state that may have got bugged.
      _repairActiveCall();
      _trace("callRinging", {
        call: candidate?.parameters["CallSid"],
        direction: candidate?.direction,
        status: candidate?.status(),
      });

      // Do we have a truly active call already? Ignore it if so
      if (activeCallRef.current) {
        _trace("callDeclined", {
          call: candidate.parameters["CallSid"],
          direction: candidate.direction,
          status: candidate.status(),
          reason: "busy",
          busyCall: activeCallRef.current.parameters["CallSid"],
        });

        return candidate.reject();
      }

      // Set active call
      setActiveCall(candidate);
      activeCallRef.current = candidate;
      setCallState("ringing");
    };

    const handleDeviceRegistering = () => {
      // console.log("Device registering");
    };

    const handleDeviceError = (error: Error) => {
      // eslint-disable-next-line no-console
      // console.error("Twilio Device Error", error);
      _trace("deviceError", {
        message: error?.message,
        stack: error?.stack,
      });
    };

    const handleTokenWillExpire = () => {
      if (onTokenAboutToExpire) onTokenAboutToExpire();
      _trace("deviceTokenWllExpire");
    };

    const handleDeviceCleanup = (reason: string) => () => {
      setState("invalid");

      // Reset active device
      setActiveDevice(null);
      activeDeviceRef.current = null;

      // Kill any calls and streas (in case)
      if (activeCallRef.current) {
        // console.warn("Active call was found when the device was destroyed. Was the call not properly cleaned up?", activeCallRef.current);
        activeCallRef.current.disconnect();
        setActiveCall(null);
        activeCallRef.current = null;
        setCallState("invalid");
      }

      if (audioHandleRef.current) {
        // console.warn("Audio stream was found when the device and call was destroyed.", audioHandleRef.current);
        stopMediaStream(audioHandleRef.current);
        setAudioHandle(null);
        audioHandleRef.current = null;
      }

      _trace("deviceDestroyed", { reason });

      // Attempt to re-create device
      _trace("deviceNonceUpdated", { reason });
      nonceUpdate();
    };

    const handleDeviceDestroyed = handleDeviceCleanup("destroyed");
    const handleDeviceUnregistered = handleDeviceCleanup("unregistered");

    device.on("registered", handleDeviceRegistered);
    device.on("connect", handleConnect);
    device.on("incoming", handleIncomingCall);
    device.on("registering", handleDeviceRegistering);
    device.on("error", handleDeviceError);
    device.on("tokenWillExpire", handleTokenWillExpire);
    device.on("destroyed", handleDeviceDestroyed);
    device.on("unregistered", handleDeviceUnregistered);

    return () => {
      device.off("registered", handleDeviceRegistered);
      device.off("connect", handleConnect);
      device.off("incoming", handleIncomingCall);
      device.off("registering", handleDeviceRegistering);
      device.off("error", handleDeviceError);
      device.off("tokenWillExpire", handleTokenWillExpire);
      device.off("destroyed", handleDeviceDestroyed);
      device.off("unregistered", handleDeviceUnregistered);
    };
  };

  const _addCallListeners = (call?: Twilio.Call | null) => {
    if (!call) return empty;

    const handleCallClosed = (reason: string) => () => {
      // Reset active call
      setActiveCall(null);
      activeCallRef.current = null;
      setCallState("invalid");

      // Kill any streams (in case)
      if (audioHandleRef.current) {
        stopMediaStream(audioHandleRef.current);
        setAudioHandle(null);
        audioHandleRef.current = null;
      }

      _trace("callEnded", {
        call: call?.parameters["CallSid"],
        direction: call?.direction,
        status: call?.status(),
        reason,
      });
    };

    const handleCallOpened = (reason: string) => async (newCall?: Twilio.Call) => {
      // Ensure active device is configured properly
      setActiveCall(newCall ?? call);
      activeCallRef.current = newCall ?? call;
      setCallState("connected");

      _trace("callAccepted", {
        call: newCall?.parameters["CallSid"],
        direction: newCall?.direction,
        status: newCall?.status(),
        reason,
        by: "call",
      });
    };

    const handleCallDisconnected = handleCallClosed("disconnected");
    const handleCallAccepted = handleCallOpened("accept");
    const handleCallReconnected = handleCallOpened("reconnected");
    const handleCallRejected = handleCallClosed("reject");
    const handleCallCancel = handleCallClosed("cancel");

    call.on("disconnect", handleCallDisconnected);
    call.on("accept", handleCallAccepted);
    call.on("reconnected", handleCallReconnected);
    call.on("reject", handleCallRejected);
    call.on("cancel", handleCallCancel);

    return () => {
      call.off("disconnect", handleCallDisconnected);
      call.off("accept", handleCallAccepted);
      call.off("reconnected", handleCallReconnected);
      call.off("reject", handleCallRejected);
      call.off("cancel", handleCallCancel);
    };
  };

  const _maybeResumeAudioContext = (device: Twilio.Device) => {
    // Resume audio context
    const audioHelper = (device?.audio as unknown as {_audioContext: AudioContext});
    if (!audioHelper || audioHelper._audioContext.state === "running") return;
    // console.log("Resumed audio context");
    audioHelper._audioContext.resume();
    _trace("contextResumed");
  };

  const _getInputDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    setInputDevices(devices.filter((device) => device.kind === "audioinput"));
    return devices;
  };

  const _preventUnknownDevices = (mediaDevices: MediaDeviceInfo[]) => {
    if (!audioDeviceIdRef.current) return;

    const found = mediaDevices.find((device) => device.deviceId === audioDeviceIdRef.current);
    if (!found) {
      // console.warn("Current device was lost, reverting to default");
      changeMic(null);
    }
  };

  const requestAudioPermission = async () => {
    try {
      _trace("requestAudioPermission");
      const media = await navigator.mediaDevices.getUserMedia({ audio: true });
      media.getTracks().forEach((track) => track.stop());
      _trace("audioPermissionGranted");
      return true;
    } catch (error) {
      _trace("audioPermissionDenied");
      // console.error("Failed to get audio permission", error);
      return false;
    }
  };

  // #endregion

  // #region Volume information (extra/optional)
  const _maybeCreateVolumeEmitter = (audioStream: MediaStream) => {
    let lastVolume = "0.00";

    // Create a clone so that when Twilio pauses the "main" stream (audioHandleRef) we can still sample volume.
    const audioClone = audioStream.clone();
    try {
      audioStream.addEventListener("inactive", () => {
        // Main stream became inactive (switched microphone, end call, etc) so we must discard of this one.
        stopMediaStream(audioClone);
        _trace("audioHandleCloneDestroyed", {
          id: audioClone.id,
          tracks: audioClone.getTracks().map((track) => track.id),
        });
      });

      const audioContext = new AudioContext();
      const source = audioContext.createMediaStreamSource(audioClone);
      const analyser = audioContext.createAnalyser();
      source.connect(analyser);

      const sampleVolume = () => {
        if (!audioHandleRef.current) return;

        const dataArray = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(dataArray);

        const averageVolume = dataArray.reduce((sum, v) => sum + v, 0) / dataArray.length;
        const newVolume = averageVolume.toFixed(2);
        if (lastVolume !== newVolume) {
          events.emit("new-volume", parseFloat(averageVolume.toFixed(2)));
        }

        events.emit("volume", parseFloat(averageVolume.toFixed(2)));
        lastVolume = averageVolume.toFixed(2);

        requestAnimationFrame(sampleVolume);
      };
      _trace("audioHandleCloneCreated", {
        id: audioClone.id,
        tracks: audioClone.getTracks().map((track) => track.id),
      });
      sampleVolume();
    } catch (error) {
      // console.error("Failed to create volume emitter. Killing audio clone", error);
      // Kill the audio clone as we may have failed before we could attach any listeners, or something else.
      stopMediaStream(audioClone);
    }
  };
  // #endregion

  // #region Effects
  useEffect(() => {
    if (!token) {
      return empty;
    }
    // Update token on device, or set new nonce to trigger device creation
    if (activeDeviceRef.current && activeDeviceRef.current.state === "registered") {
      _trace("deviceTokenAndOptionsUpdated", { nonce, token, isSilent });
      activeDeviceRef.current.updateToken(token);
      activeDeviceRef.current.updateOptions(_createOptions(isSilent));
      setActiveDevice(activeDeviceRef.current);
    } else {
      _trace("deviceNonceUpdated", { reason: "tokenOrSilentChanged" });
      nonceUpdate();
    }
  }, [token, isSilent]);

  useEffect(() => {
    if (!token) {
      setState("invalid");
      return empty;
    }
    setState("preparing");
    _trace("deviceCreated", { nonce });
    const device = _createDevice(token, isSilent);
    setActiveDevice(device);
    activeDeviceRef.current = device;

    const cleanupListeners = _addDeviceListeners(device);
    device.register().then(
      () => {
        // console.log("Device registered");
      },
      (error) => {
        // console.error("Failed to register device", error);
        setState("invalid");
      },
    );
    return () => {
      // This will clean up listeners. This is CRUCIAL as otherwise it will loop where the "destroy" event creates a new nonce
      // which then creates a new device, which then destroys the old device, etc.
      cleanupListeners();
      device.destroy();
    };
  }, [
    nonce,
  ]);

  useEffect(() => {
    if (!activeDeviceRef.current) return empty;
    const cleanupListeners = _addCallListeners(activeCallRef.current);

    // Ensure call is muted when it should be
    if (activeCallRef.current) activeCallRef.current.mute(isMuted);
    return () => {
      cleanupListeners();
    };
  }, [
    activeCall,
  ]);

  useEffect(() => {
    const handler = () => {
      if (activeDeviceRef.current) _maybeResumeAudioContext(activeDeviceRef.current);
    };

    ["click", "focus"].forEach((event) => {
      window.addEventListener(event, handler);
    });

    return () => {
      ["click", "focus"].forEach((event) => {
        window.removeEventListener(event, handler);
      });
    };
  }, []);

  useEffect(() => {
    const handler = async () => {
      const mediaDevices = await _getInputDevices();
      _preventUnknownDevices(mediaDevices);
    };

    // Get initial devices
    handler();

    // Listen for changes
    navigator.mediaDevices.addEventListener("devicechange", handler);

    return () => {
      navigator.mediaDevices.removeEventListener("devicechange", handler);
    };
  }, []);

  useEffect(() => {
    _trace("publishedState", {
      device: activeDevice?.identity,
      state,
      call: activeCall?.parameters["CallSid"],
      callState,
      internal: {
        activeDeviceRef: activeDeviceRef.current?.identity,
        activeCallRef: activeCallRef.current?.parameters["CallSid"],
      },
    });
  }, [
    state,
    callState,
  ]);
  // #endregion

  const value = useMemo(() => ({
    isSilent,
    setIsSilent,
    isMuted,
    mute: () => _handleAudioToggle(activeDeviceRef.current, activeCallRef.current),
    device: activeDevice,
    setMode: (silent: boolean) => {
      setIsSilent(silent);
    },
    hasToken: !!token,
    state,
    activeCall,
    engageCall: () => {
      if (activeCallRef.current) activeCallRef.current.accept();
    },
    refuseCall: () => {
      if (activeCallRef.current) activeCallRef.current.reject();
    },
    hangupCall: () => {
      if (activeCallRef.current) activeCallRef.current.disconnect();
    },
    callState,
    inputDevices,
    audioDeviceId: audioDeviceId || "default",
    setAudioDevice: async (deviceId: string) => {
      if (activeDeviceRef.current) await _handleAudioDeviceChange(activeDeviceRef.current, deviceId);
    },
    requestAudioPermission,
    events,
  }), [
    isSilent,
    setIsSilent,
    isMuted,
    activeCall,
    activeDevice,
    _handleAudioToggle,
    inputDevices,
    audioDeviceId,
    token,
    state,
    callState,
    events,
  ]);

  const debugInfo: ((React.ReactNode) | (React.ReactNode)[])[] = [
    "RTC Context (remove debug param or twilioDbg localStorage to hide)",
    "-----------",
    ["(published state)", "device", state, "call", callState],
    ["(device)", activeDevice?.identity, activeDeviceRef.current?.isBusy ? "busy" : "free", activeDevice?.state, isSilent ? "silent" : "normal", "nonce", nonce],
    ["(managed calls)", activeDeviceRef.current?.calls?.length],
    ["(call)", activeCall?.direction, activeCall?.status(), "(managed streams)", audioHandleRef.current ? 1 : 0],
    ["(handle)", audioHandleRef.current?.getAudioTracks()[0]?.enabled, audioHandleRef.current?.id, audioHandleRef.current?.active],
    ["(audio)", audioDeviceId, isMuted],
  ];

  return (
    <RTCContext.Provider value={value}>
      {children}
      {(debug || localStorage.getItem("twilioDbg")) && (
        <div className="hover:opacity-20 fixed bottom-0 right-0 bg-red-400 p-1 font-mono text-sm" style={{ zIndex: 1000 }}>
          {debugInfo.map((info, idx) => (
            <span className="block">
              {Array.isArray(info) ? (
                info.map(String).join("; ")
              ) : (
                String(info)
              )}
            </span>
          ))}
        </div>
      )}
    </RTCContext.Provider>
  );
};

const useRTC = () => React.useContext(RTCContext);

export { RTCProvider, useRTC };
export default RTCContext;
