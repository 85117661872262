import { gql } from "@apollo/client";
import { MessageType } from "@audacia-hq/shared/components";

import { ExpertProfile } from "../models/expert";
import { Written } from "../models/written";

export const CREATE_EXPERT = gql`
  mutation CreateExpert($input: ExpertInput!) {
    expertMutations {
      createExpert(input: $input) {
        expertUid
      }
    }
  }
`;

export const UPDATE_EXPERT = gql`
  mutation UpdateExpert($input: ExpertEditInput!) {
    expertMutations {
      updateExpert(input: $input) {
        expertUid
      }
    }
  }
`;

export const UPDATE_EXPERT_DOCUMENT = gql`
mutation UpdateExpertDocument($docUid: ID!, $links: [String]!) {
  expertMutations {
    updateExpertDocument(docUid: $docUid, links: $links) {
      docUid
        type
        links
        status
        refusalReason
        validatedAt
        createdAt
        updatedAt
      }
    }
  }
`;

export const SEND_RECRUITMENT_ACTION = gql`
  mutation SendRecruitmentAction($action: RecruitmentAction) {
    expertMutations {
      sendRecruitmentAction(action: $action)
    }
  }
`;

export const CREATE_EXPERT_PROFILE = gql`
  mutation CreateExpertProfile($input: ExpertProfileInput!, $resubmit: Boolean) {
    expertMutations {
      createExpertProfile(input: $input, resubmit: $resubmit) {
        expertProfileUid
        expertCode
        picture
        pictureUid
        pseudo
        quote
        description
        writtenMessage
        skills
        vocalMessage
        spokenLanguages
        parameters {
          phoneEnabled
          videoEnabled
          livechatEnabled
          writtenEnabled
          writtenLimit
        }
        status
        profileLanguage
      }
    }
  }
`;

export const ADD_EXPERT_PICTURE = gql`
  mutation AddExpertPicture($url: String!) {
    expertMutations {
      addExpertPicture(url: $url)
    }
  }
`;

export const DELETE_EXPERT_PICTURE = gql`
  mutation ExpertMutations($pictureUid: String!) {
    expertMutations {
      deleteExpertPicture(pictureUid: $pictureUid)
    }
  }
`;

export const UPDATE_EXPERT_PROFILE = gql`
  mutation UpdateExpertProfile($profileLanguage: String!, $input: ExpertProfileEditInput!) {
    expertMutations {
      updateExpertProfile(profileLanguage: $profileLanguage, input: $input) {
        expertProfileUid
      }
    }
  }
`;

export const ACKNOWLEDGE_VALIDATION = gql`
  mutation AcknowledgeValidations($validationUids: [String]!) {
    expertMutations {
      acknowledgeValidations(validationUids: $validationUids)
    }
  }
`;

export const TRIGGER_QUEUE = gql`
  mutation TriggerQueue {
    expertMutations {
      triggerQueue
    }
  }
`;

export const SET_ACTIVITY_STATUS = gql`
  mutation SetActivityStatus($ignoreStatus: Boolean, $activityStatus: Boolean!, $action: ActivityActionInput) {
    expertMutations {
      setActivityStatus(ignoreStatus: $ignoreStatus, activityStatus: $activityStatus, action: $action) {
        success
        updates {
          activityStatus
          lastActiveAt
        }
        requiredActions
      }
    }
  }
`;

export const UPDATE_EXPERT_PARAMETERS = gql`
  mutation UpdateExpertParameters($parameters: EditableExpertParameters!) {
    expertMutations {
      updateExpertParameters(parameters: $parameters)
    }
  }
`;

export const UPDATE_EXPERT_PRESENCE_STATUSES = gql`
  mutation UpdateExpertPresenceStatuses($statuses: EditableExpertPresenceStatuses!) {
    expertMutations {
      updateExpertPresenceStatuses(statuses: $statuses)
    }
  }
`;

export const CREATE_PHONE = gql`
  mutation CreateExpertPhone($phoneNumber: String!) {
    expertPhoneMutations {
      createExpertPhone(phoneNumber: $phoneNumber) {
        phoneUid
        verified
      }
    }
  }
`;

export const DELETE_PHONE = gql`
  mutation DeleteExpertPhone($phoneUid: String!) {
    expertPhoneMutations {
      deleteExpertPhone(phoneUid: $phoneUid)
    }
  }
`;

export const VERIFY_PHONE = gql`
  mutation VerifyExpertPhone($input: VerifyExpertPhoneInput!) {
    expertPhoneMutations {
      verifyExpertPhone(input: $input) {
        phoneUid
        digits
      }
    }
  }
`;

export const START_RTC_VERIFICATION = gql`
  mutation StartRtcVerification($language: String!) {
    rtcValidationCall(language: $language)
  }
`;

export const SET_USAGE_PHONE = gql`
  mutation SetUsageExpertPhone($input: SetUsageExpertPhoneInput!) {
    expertPhoneMutations {
      setUsageExpertPhone(input: $input)
    }
  }
`;

export const UPDATE_EXPERT_BANKING = gql`
  mutation UpdateExpertBanking($input: ExpertBankingInput!) {
    expertMutations {
      updateExpertBanking(input: $input) {
        beneficiary
        address
        zip
        city
        country
        bankCountry
        IBAN
        BIC
        SEPA
      }
    }
  }
`;

export const CREATE_EXPERT_BANKING = gql`
  mutation CreateExpertBanking($input: ExpertBankingInput!) {
    expertMutations {
      createExpertBanking(input: $input) {
        beneficiary
        address
        zip
        city
        country
        bankCountry
        IBAN
        BIC
        SEPA
      }
    }
  }
`;

export const ADD_EXPERT_DOCUMENT = gql`
  mutation AddExpertDocument($input: ExpertDocumentInput!) {
    expertMutations {
      addExpertDocument(input: $input) {
        docUid
      }
    }
  }
`;

export const DELETE_EXPERT_AUDIO = gql`
  mutation DeleteExpertAudio($profileLanguage: String!) {
    expertMutations {
      deleteExpertAudio(profileLanguage: $profileLanguage)
    }
  }
`;

export const VALIDATE_EXPERT_EMAIL = gql`
  mutation ValidateEmail($code: String!) {
    expertMutations {
      validateEmail(code: $code)
    }
  }
`;

export const CHANGE_EXPERT_EMAIL = gql`
  mutation ChangeEmail($email: String!) {
    expertMutations {
      changeEmail(email: $email)
    }
  }
`;

export const ANSWER_RATING = gql`
  mutation AnswerRating($input: AnswerRatingInput!) {
    answerRating(input: $input) {
      ratingUid
      rate
      memberPseudo
      comment
      ratingAnswer {
        ratingAnswerUid
        ratingUid
        expertUid
        comment
        validationComment
        language
        status
        validationRules
        context
        createdAt
        updatedAt
        publishAt
      }
      createdAt
    }
  }
`;

export const DELETE_MESSAGE = gql`
  mutation DeleteMessage($messageUid: String!) {
    messagingMutations {
      deleteMessage(messageUid: $messageUid)
    }
  }
`;

export const CREATE_MESSAGES_VIEWS = gql`
  mutation CreateMessagesViews($messages: [String!]!) {
    messagingMutations {
      createMessagesViews(messages: $messages)
    }
  }
`;

export const CREATE_MESSAGE = gql`
  mutation CreateMessage($recipient: String!, $content: String!, $language: String!) {
    messagingMutations {
      createMessage(recipient: $recipient, content: $content, language: $language) {
        messageUid
      }
    }
  }
`;

export const CREATE_BLOCKING = gql`
  mutation CreateBlocking($action: String!, $sender: String!) {
    messagingMutations {
      createBlocking(action: $action, sender: $sender)
    }
  }
`;

export const ANSWER_WRITTEN = gql`
  mutation AnswerWritten($writtenUid: ID!, $answer: String!) {
    writtenMutations {
      answerWritten(writtenUid: $writtenUid, answer: $answer) {
        writtenUid
      }
    }
  }
`;

export const REFUSE_WRITTEN = gql`
  mutation RefuseWritten($writtenUid: ID!, $refusalReason: RefusalReason!, $refusalComment: String!) {
    writtenMutations {
      refuseWritten(writtenUid: $writtenUid, refusalReason: $refusalReason, refusalComment: $refusalComment) {
        writtenUid
      }
    }
  }
`;

export const CLEAR_EFFECTIVE_RANGES = gql`
  mutation ClearEffectiveRanges($ranges: [RangeInput]!) {
    planningMutations {
      clearEffectiveRanges(ranges: $ranges)
    }
  }
`;

export const EDIT_EXPERT_RELATION = gql`
  mutation EditExpertRelation($relationUid: ID!, $expertNotes: String!, $customData: InputExpertRelationCustomData) {
    editExpertRelation(relationUid: $relationUid, expertNotes: $expertNotes, customData: $customData) {
      relationUid
      memberUid
      memberPseudo
      memberBirthday
      phoneNbr
      videoNbr
      livechatNbr
      writtenNbr
      ratingNbr
      messageNbr
      expertNotes
      customData {
        name
      }
    }
  }
`;

export const SET_MODEL_RANGES = gql`
  mutation SetModelRanges($ranges: [SlotRangeInput]!, $timezone: String) {
    planningMutations {
      setModelRanges(ranges: $ranges, timezone: $timezone)
    }
  }
`;

export const SET_EFFECTIVE_RANGES = gql`
  mutation SetEffectiveRanges($ranges: [SlotRangeInput]!, $timezone: String) {
    planningMutations {
      setEffectiveRanges(ranges: $ranges, timezone: $timezone)
    }
  }
`;

export const SET_VACTIONS = gql`
  mutation SetVacations($ranges: [RangeInput]!, $timezone: String) {
    planningMutations {
      setVacations(ranges: $ranges, timezone: $timezone)
    }
  }
`;

export const CLEAR_VACATIONS = gql`
  mutation ClearVacations($ranges: [RangeInput]!, $timezone: String) {
    planningMutations {
      clearVacations(ranges: $ranges, timezone: $timezone)
    }
  }
`;

export const TRIAL_OFFER = gql`
  mutation TrialOffer {
    trialOffer
  }
`;

export const CANCEL_TRIAL_OFFER = gql`
  mutation CancelTrialOffers {
    cancelTrialOffers
  }
`;

export const CREATE_NOTIFICATION_ACK = gql`
  mutation CreateNotificationAck($notificationUid: String!) {
    notificationMutations {
      createNotificationAck(notificationUid: $notificationUid) {
        notificationUid
      }
    }
  }
`;

export const ACK_ALL_NOTIFICATIONS = gql`
  mutation AckAllNotifications {
    notificationMutations {
      ackAllNotifications
    }
  }
`;

export const STOP_CALL = gql`
  mutation StopCall($callLegUid: String, $ivrCallUid: String) {
    stopCall(callLegUid: $callLegUid, ivrCallUid: $ivrCallUid)
  }
`;

export const STOP_VIDEO = gql`
  mutation StopVideo($videoRoomUid: String) {
    stopVideo(videoRoomUid: $videoRoomUid)
  }
`;

export const CREATE_EXPERT_SPONSOR_LINK = gql`
  mutation createExpertSponsorLink($sponsorCode: String!) {
    createExpertSponsorLink(sponsorCode: $sponsorCode)
  }
`;

export const SUBMIT_INVOICE = gql`
  mutation SubmitInvoice($invoiceUid: ID!, $file: String!, $hash: String!) {
    submitInvoice(invoiceUid: $invoiceUid, file: $file, hash: $hash) {
      invoiceUid
      month
      year
      amount
      file
      status
      refusalReason
      createdAt
      exportedAt
    }
  }
`;

export const CREATE_TICKET = gql`
mutation CreateTicket($createTicketInput: CreateTicketInput!) {
  supportMutations {
    createTicket(createTicketInput: $createTicketInput) {
      ticketUid
    }
  }
}
`;

export const ADD_TICKET_ANSWER = gql`
mutation AddTicketAnswer($ticketAnswer: TicketAnswerInput!, $ticketUid: String!) {
  supportMutations {
    addTicketAnswer(ticketAnswer: $ticketAnswer, ticketUid: $ticketUid)
  }
}
`;

export const ADD_ADVISORY = gql`
mutation AddAdvisory($targetUid: String, $targetType: String, $type: String, $actionable: Boolean) {
  advisoryMutations {
    addAdvisory(targetUid: $targetUid, targetType: $targetType, type: $type, actionable: $actionable)
  }
}
`;

export const ACK_ANNOUNCEMENTS = gql`
mutation AckAnnouncements($announcementUids: [String]!) {
  announcementMutations {
    ackAnnouncements(announcementUids: $announcementUids)
  }
}
`;

export const RESPOND_TO_SURVEY = gql`
mutation RespondToSurvey($questionUid: ID!, $response: [String]!, $responseUid: ID!) {
  surveyMutations {
    createResponse(questionUid: $questionUid, response: $response, responseUid: $responseUid) {
      questionResponseUid
    }
  }
}
`;

export const RTC_TRACE = gql`
mutation RtcTrace($event: String!, $metadata: String!) {
  rtcTrace(event: $event, metadata: $metadata)
}
`;

export const SEND_EVENT_LOG = gql`
mutation SendEventLog($subject: String!, $event: String!, $level: String!, $ts: String!, $metadata: String) {
  logsMutations {
    sendEventLog(subject: $subject, event: $event, level: $level, ts: $ts, metadata: $metadata) 
  }
}
`;

export interface SurveyMutations {
  surveyMutations: {
    createResponse: void;
  }
}

export interface ExpertMutations {
  expertMutations: {
    createExpert?: { expertUid: string };
    createExpertProfile: ExpertProfile;
    addExpertPicture: boolean;
    deleteExpertPicture: boolean;
    updateExpertProfile: ExpertProfile;
    acknowledgeValidation: boolean;
    triggerQueue: boolean;
    setActivityStatus: {
      success: boolean;
      updates?: {
        activityStatus?: boolean;
        lastActiveAt?: string;
      };
      requiredActions?: string[]
    };
    updateExpertParameters: boolean;
    validateEmail: boolean;
    changeEmail: boolean;
  }
}

export interface ExpertPhoneMutations {
  expertPhoneMutations: {
    createExpertPhone?: { phoneUid: string, verified: boolean; };
    deleteExpertPhone: boolean;
    verifyExpertPhone: { phoneUid: string; digits: string };
    setUsageExpertPhone: boolean;
    createExpertBanking: boolean;
    addExpertDocument: { docUid: string };
  };
}

export interface MessagingMutations {
  messagingMutations: {
    deleteMessage: boolean;
    createMessagesViews: boolean;
    createMessage: MessageType;
    createBlocking: boolean;
  };
}

export interface WrittenMutations {
  writtenMutations: {
    answerWritten: Written;
    refuseWritten: Written;
  };
}

export interface PlanningMutations {
  planningMutations: {
    setModelRanges: boolean;
    setEffectiveRanges: boolean;
    clearEffectiveRanges: boolean;
    setVacations: boolean;
    clearVacations: boolean;
  };
}

export interface NotificationMutations {
  notificationMutations: {
    createNotificationAck: Notification;
  };
}
