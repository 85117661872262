import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { QuestionSettings, QuestionTypes, SurveyType } from "@audacia-hq/shared/models";

import Button from "../Button";

import QuestionRenderer from "./questions";

export interface SurveyProps {
  survey: SurveyType;
  onResponse?: (questionUid: string, response: string[]) => void;
  onFinish: () => void;
}

export const createValidation = (type: keyof typeof QuestionTypes, settings: QuestionSettings) => {
  switch (type) {
    case "NUMERIC":
      return Yup.string()
        .matches(/-?[0-9]+/g, { message: "common:errors.numberRequired" })
        .required("common:errors.required")
        // Min-max values must be tested in a custom function, as we need to parse the value
        .test("min", "common:errors.min", (value) => {
          if (!value || Number.isNaN(Number(value)) || typeof settings.min !== "number") return true;
          return Number(value) >= settings.min;
        })
        .test("max", "common:errors.max", (value) => {
          if (!value || Number.isNaN(Number(value)) || typeof settings.max !== "number") return true;
          return Number(value) <= settings.max;
        });

    case "INTRODUCTION":
    case "CONCLUSION":
    case "EMAIL":
      return Yup.string().required("common:errors.required").email("common:errors.email.invalid");
    case "SHORT_TEXT":
      return Yup.string().required("common:errors.required")
        .test("max", "common:errors.maxLength", (value) => {
          if (!value || Number.isNaN(Number(value)) || typeof settings.max !== "number") return true;
          return value.length <= settings.max;
        });
    case "LIKERT_SCALE":
    case "MULTIPLE_CHOICE":
    case "UNIQUE_CHOICE":
    case "LONG_TEXT":
    default:
      return Yup.string().required("common:errors.required");
  }
};

const Survey = ({
  survey, onResponse, onFinish,
}: SurveyProps) => {
  const { t } = useTranslation("survey");
  // this is ARRAY index. not question index
  const [index, setIndex] = useState<number>(0);

  // Got to slice as the GraphQL doesn't like us touching the original array
  const questions = survey?.questions.slice().filter((a) => a.showQuestion).sort((a, b) => a.order - b.order) ?? [];
  const currentQuestion = questions[index];
  const total = questions.length;
  const standardQuestionsTotal = questions.filter((q) => ![QuestionTypes.INTRODUCTION, QuestionTypes.CONCLUSION].includes(q.type as QuestionTypes)).length;

  if (total <= 0 || !currentQuestion) return <></>;

  return (
    <Formik
      key={currentQuestion.questionUid}
      onSubmit={(values: { value: string[] }) => {
        // Deal with action logic here
        const currentOptions = currentQuestion.settings.options?.filter((option) => values.value.includes(option.key));
        const response = values.value.reduce((acc, value) => {
          if (!value) return acc;
          const option = currentQuestion.settings.options?.find((o) => o.key === value);
          acc.push(String(option?.label ?? value));
          return acc;
        }, []);

        if (currentOptions?.length) {
          // Any end ones?
          const end = currentOptions.find((option) => option.action.type === "END");
          if (end) {
            // here end does not mean CLOSE, it means the end of the survey
            setIndex(total - 1);
            return onResponse?.(currentQuestion.questionUid, response);
          }

          // Any go to ones?
          const goTo = currentOptions.find((option) => option.action.type === "GOTO");
          if (goTo) {
            const targetQuestionUid = goTo.action.questionUid;
            const targetIndex = questions.findIndex((question) => question.questionUid === targetQuestionUid);
            if (targetIndex !== -1) {
              setIndex(targetIndex);
            }
            return onResponse?.(currentQuestion.questionUid, response);
          }
        }
        // Any NEXT ones, we just continue as below
        setIndex(index + 1);
        if (index === total - 1) onFinish();
        return onResponse?.(currentQuestion.questionUid, response);
      }}
      initialValues={{ value: [] as string[] }}
      validationSchema={Yup.object().shape({
        value: currentQuestion.required
          ? Yup.array().of(
            createValidation(currentQuestion.type, currentQuestion.settings),
          ).min(1, "common:errors.required")
          : Yup.array().of(
            createValidation(currentQuestion.type, currentQuestion.settings),
          ),
      })}
      validateOnChange
      validateOnBlur
      validateOnMount={false}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit} className="flex flex-col">
          <QuestionRenderer question={questions[index]} />
          <Button
            disabled={!formik.isValid}
            type="button"
            className="mt-8 mx-auto"
            onClick={async () => {
              await formik.submitForm();

              if (currentQuestion.settings.button?.link) {
                window.location.href = currentQuestion.settings.button.link;
              }
            }}
          >
            {currentQuestion.settings.button?.text ?? (index < total - 1 ? t("continue") : t("finish"))}
          </Button>
          {currentQuestion.type !== QuestionTypes.CONCLUSION && (
            <div className="w-100 bg-gray-300 flex h-16 mt-6 -mx-6 -mb-6 h">
              <p className="m-auto text-slate-700">
                {currentQuestion.type === QuestionTypes.INTRODUCTION ? t("questionTotal", { total: standardQuestionsTotal })
                  : t("questionProgress", { index: (index + 2) - (total - standardQuestionsTotal), total: standardQuestionsTotal })}
              </p>
            </div>
          )}
        </form>
      )}
    </Formik>
  );
};

export default Survey;
