import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface Props {
  className?: string;
  onClick?: () => void;
  loading?: boolean;
}

const Badge: React.FC<React.PropsWithChildren<Props>> = ({
  children, className, onClick, loading,
}) => (
  <button
    className={`rounded p-2 ${onClick ? "cursor-pointer" : ""} ${className || ""}`}
    onClick={onClick}
    type="button"
  >
    {loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : children}
  </button>
);

export default Badge;
