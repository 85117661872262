/* eslint-disable jsx-a11y/media-has-caption */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStop, faMicrophone, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

import useRecorder from "../contexts/useRecorder";
import Button from "../Button";

interface Props {
  value?: string;
  initialPreview?: string;
  label: string;
  hint: string;
  error?: string;
  onChange: (audioUrl: string, audioFile: Blob) => void;
  disabled?: boolean;
  onDelete?: () => void;
}

const AudioInput = ({
  value, initialPreview, label, hint, onChange, error, disabled, onDelete,
}: Props) => {
  const [audioURL, setAudioURL] = useState<string>("");
  const {
    audioFile, isRecording, startRecording, stopRecording,
  } = useRecorder();

  useEffect(() => {
    setAudioURL(value);
  }, [value]);

  useEffect(() => {
    if (!audioFile) return;
    const url = URL.createObjectURL(audioFile);
    setAudioURL(url);
    onChange(audioURL, audioFile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioFile]);

  return (
    <div>
      <span className="block">{label}</span>
      {hint && <span className="block mb-2 text-xs">{hint}</span>}
      <div className="flex items-center gap-3">
        <audio src={audioURL || initialPreview} controls />
        {!disabled && (
          <>
            {!isRecording && (
              <button type="button" onClick={startRecording} disabled={isRecording}>
                <FontAwesomeIcon icon={faMicrophone} />
              </button>
            )}
            {isRecording && (
              <>
                <button type="button" onClick={stopRecording} disabled={!isRecording}>
                  <FontAwesomeIcon icon={faStop} />
                </button>
                <span className="relative flex h-3 w-3">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-300 opacity-75" />
                  <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500" />
                </span>
              </>
            )}
            {onDelete && (
              <Button
                className="border-0"
                theme="redGhost"
                size="small"
                onClick={() => onDelete()}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            )}
          </>
        )}
      </div>
      {error && <div className="text-red-500">{error}</div>}
    </div>
  );
};

export default AudioInput;
