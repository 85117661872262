import React from "react";

interface Props {
  active?: boolean;
  value: string;
  onClick: (value: string) => void;
}

const LargeButton: React.FC<React.PropsWithChildren<Props>> = ({
  active, value, onClick, children,
}) => (
  <>
    <button
      className={`w-full h-16  border ${active ? "bg-gray-200 border-gray-300" : "bg-gray-50 border-gray-300"} text-slate-600 hover:bg-gray-200 hover:border-gray-300`}
      type="button"
      onClick={() => onClick(value)}
    >
      {children}
    </button>
  </>
);

export default LargeButton;
