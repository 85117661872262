const getCurrencyForCountry = (country: string) => {
  switch (country) {
    case "CH":
    case "LI":
      return "CHF";
    case "CA":
      return "CAD";
    default:
      return "EUR";
  }
};

const getCurrencySign = (currency: string) => {
  switch (currency) {
    case "EUR":
      return "€";
    case "USD":
      return "$";
    default:
      return currency;
  }
};

const canadianRegion = [
  ["AB", "Alberta"],
  ["BC", "Britsh Columbia"],
  ["MB", "Manitoba"],
  ["NB", "New Brunswick"],
  ["NL", "Newfoundland and Labrador"],
  ["NS", "Nova Scotia"],
  ["NT", "North Territories"],
  ["NU", "Nunavut"],
  ["ON", "Ontario"],
  ["PE", "Prince Edward Island"],
  ["QC", "Quebec"],
  ["SK", "Saskatchewan"],
  ["YT", "Yukon"],
];

export {
  getCurrencyForCountry,
  getCurrencySign,
  canadianRegion,
};
