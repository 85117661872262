/* eslint-disable react/no-danger */
import { Announcement } from "@audacia-hq/shared/models";
import * as React from "react";

import Alert from "../Alert";

interface Props {
  announcement: Announcement;
  language: string;
  thin?: boolean;
}

const AnnounceBanner: React.FC<Props> = ({
  announcement, language, thin,
}) => {
  const getAlertType = (level: string) => {
    switch (level) {
      case "warning":
        return "warning";
      case "error":
      case "alert":
        return "alert";
      default: return "info";
    }
  };

  return (
    announcement ? (
      <Alert thin={thin} type={getAlertType(JSON.parse(announcement.typeData).level)} icon>
        <div dangerouslySetInnerHTML={{ __html: JSON.parse(announcement.content)[language] }} />
      </Alert>
    ) : <></>
  );
};

export default AnnounceBanner;
