import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faInfo, faXmark } from "@fortawesome/free-solid-svg-icons";

interface Alternative {
  className?: string;
  label: string;
  action: () => void;
}

interface Props {
  open: boolean;
  title: string;
  alternatives: Alternative[];
  onClose?: () => void;
  showCloseX?: boolean;
  icon?: "info" | "warning";
}

const AlternativesModal: React.FC<React.PropsWithChildren<Props>> = ({
  open, title, alternatives, onClose, showCloseX, icon = "info", children,
}) => {
  const firstAlternativeRef = useRef(null);

  const renderVariantIcon = () => {
    switch (icon) {
      case "warning":
        return (
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <FontAwesomeIcon icon={faExclamation} className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
        );
      case "info":
      default:
        return (
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
            <FontAwesomeIcon icon={faInfo} className="h-6 w-6 text-blue-600" aria-hidden="true" />
          </div>
        );
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={firstAlternativeRef} onClose={onClose || (() => null)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                {showCloseX && (
                  <FontAwesomeIcon
                    className="text-slate-400 absolute top-4 right-4 text-xl cursor-pointer"
                    onClick={onClose}
                    icon={faXmark}
                  />
                )}

                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    {renderVariantIcon()}
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-xl font-semibold leading-6 text-gray-900">
                        {title}
                      </Dialog.Title>
                      <div className="mt-2 text-sm text-gray-500">
                        {children}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 pb-8 gap-6 flex flex-col-reverse sm:flex sm:flex-row-reverse sm:px-6">
                  {alternatives.map((alt, idx) => (
                    <button
                      key={alt.label}
                      ref={idx === 0 ? firstAlternativeRef : undefined}
                      type="button"
                      className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semiboldshadow-sm sm:w-auto ${alt.className || ""}`}
                      onClick={() => alt.action()}
                    >
                      {alt.label}
                    </button>
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AlternativesModal;
