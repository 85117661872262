import * as React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { retry, Subject } from "rxjs";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { useAuth } from "@audacia-hq/shared/contexts";
import { WSMessage } from "@audacia-hq/shared/models";
import { useTranslation } from "react-i18next";

import config from "../modules/common/services/config";

export type { WSMessage } from "@audacia-hq/shared/models";

interface WSOutgoingMessage {
  type: "authentify";
  context: string;
  token: string;
}
const subject = new Subject<WSMessage | WSOutgoingMessage>();
const WSContext = React.createContext<Subject<WSMessage | WSOutgoingMessage>>(subject);

const WSProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const { t } = useTranslation("common");
  const { user } = useAuth();
  const [wsSubject, setWSSubject] = useState<WebSocketSubject<WSMessage | WSOutgoingMessage>>();
  const [ts, setTS] = useState(0);
  const alreadyConnected = React.useRef(false);

  useEffect(() => {
    if (user) {
      const sub = webSocket<WSMessage | WSOutgoingMessage>({
        url: `${config.wsBaseHost}/rtc-api/events`,
        openObserver: {
          next: (v) => setTS(v.timeStamp),
        },
      });
      sub.pipe(retry({ delay: 2000 })).subscribe({
        next: (msg) => subject.next(msg),
        error: (err) => toast.error(t("errors.wsError"), { autoClose: false }),
      });
      setWSSubject(sub);
      return () => sub.unsubscribe();
    }
    return () => ({});
  }, [user]);

  React.useEffect(() => {
    if (ts && user && wsSubject) {
      user.getIdToken().then((token) => {
        wsSubject.next({ type: "authentify", context: "idealvoyance", token });
        // Avoid firing reconnected on first load
        if (alreadyConnected.current) {
          subject.next({
            type: "direct", subject: null, ts, event: "ws.reconnected", payload: "{}",
          });
        }
        alreadyConnected.current = true;
      });
    }
  }, [ts, user]);

  return (
    <WSContext.Provider value={subject}>
      {children}
    </WSContext.Provider>
  );
};

const useWS = () => React.useContext(WSContext);

export default WSContext;
export { WSProvider, useWS };
