import React, {
  PropsWithChildren, useCallback, useEffect, useState,
} from "react";
import { Announcement } from "@audacia-hq/shared/models";
import SharedComponents from "@audacia-hq/shared/components";
import { useMutation, useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { GET_ANNOUNCEMENTS } from "../graphql/query";
import { ACK_ANNOUNCEMENTS } from "../graphql/mutations";

import { useExpert } from "./ExpertContext";
import { WSMessage, useWS } from "./WSContext";

const ACKED_ANNOUNCEMENTS_LS_KEY = "acknowledgedAnnoucementUids";

interface AnnouncementContextType {
  renderAnnounceBanner: (location: "main" | "support" | "payment", className?: string) => React.ReactNode;
  renderReleaseNote: () => React.ReactNode;
  ackAnnouncements: (announcementUids: string[], ackType: "LS" | "DB" | "BOTH") => void;
  unackAnnouncementsLS: (announcementUids: string[]) => void;
  notifiedAnnouncements: Announcement[];
}

const AnnouncementContext = React.createContext<AnnouncementContextType>({
  renderAnnounceBanner: () => <></>,
  renderReleaseNote: () => <></>,
  ackAnnouncements: () => null,
  unackAnnouncementsLS: () => null,
  notifiedAnnouncements: [],
});

interface Props {
  initialAnnouncements?: Announcement[];
}

const AnnouncementProvider: React.FC<PropsWithChildren<Props>> = ({ children, initialAnnouncements }) => {
  const [announcements, setAnnouncements] = useState<Announcement[]>(initialAnnouncements);
  const [getAnnouncementsQuery] = useLazyQuery<{ announcements: Announcement[] }>(GET_ANNOUNCEMENTS);
  const [ackAnnouncementsMutation] = useMutation(ACK_ANNOUNCEMENTS);
  const { expert } = useExpert();
  const ws = useWS();

  const [ls, setLs] = useState<string[]>(localStorage?.getItem(ACKED_ANNOUNCEMENTS_LS_KEY)?.split(",") || []);

  const { i18n } = useTranslation();

  const getAnnouncements = () => {
    if (!expert) return;
    getAnnouncementsQuery().then((res) => {
      setAnnouncements(res.data.announcements);
    });
  };

  useEffect(() => {
    if (!announcements) getAnnouncements();
  }, []);

  useEffect(() => {
    if (ls.length) {
      localStorage?.setItem(ACKED_ANNOUNCEMENTS_LS_KEY, ls.join(","));
    } else {
      localStorage?.removeItem(ACKED_ANNOUNCEMENTS_LS_KEY);
    }
  }, [ls]);

  const handleAnnouncementEvents = (msg: WSMessage) => {
    const payload = JSON.parse(msg.payload) as Announcement & { apps: string[] };
    switch (msg.event) {
      case "ws.reconnected":
        getAnnouncements();
        break;
      case "announcement.current.updated":
        setAnnouncements((prev) => {
          const typeMatch = prev.find((a) => a.type === payload.type);
          const uidMatch = typeMatch?.announcementUid === payload.announcementUid;
          const appMatch = payload.apps?.includes("iv-expert");
          if (uidMatch && !appMatch) {
            return prev.filter((a) => a.announcementUid !== payload.announcementUid);
          }
          if (!appMatch) return prev;

          if (!typeMatch || uidMatch || !dayjs(typeMatch.startDate).isBefore(payload.startDate)) {
            return [...prev.filter((a) => a.announcementUid !== typeMatch?.announcementUid), payload];
          }
          return prev;
        });
        break;
      case "announcement.current.deleted":
        setAnnouncements((prev) => prev.filter((a) => a.announcementUid !== payload.announcementUid));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const sub = ws.subscribe((msg: WSMessage) => handleAnnouncementEvents(msg));
    return () => sub.unsubscribe();
  }, [ws]);

  const ackAnnouncements = useCallback(async (announcementUids: string[], ackType: "LS" | "DB" | "BOTH") => {
    if (["LS", "BOTH"].includes(ackType)) {
      const current = localStorage?.getItem(ACKED_ANNOUNCEMENTS_LS_KEY)?.split(",") || [];
      setLs([...new Set([...current, ...announcementUids])]);
    }

    if (expert && ["DB", "BOTH"].includes(ackType)) {
      await ackAnnouncementsMutation({
        variables: {
          announcementUids,
        },
        refetchQueries: [GET_ANNOUNCEMENTS],
      });
    }
  }, [expert]);

  const unackAnnouncementsLS = (announcementUids: string[]) => {
    const current = localStorage?.getItem(ACKED_ANNOUNCEMENTS_LS_KEY)?.split(",") || [];
    setLs(current.filter((c) => !announcementUids.includes(c)));
  };

  const renderAnnounceBanner = useCallback((location: "main" | "support" | "payment", className?: string) => {
    const announcement = announcements?.find((a) => {
      const { display } = JSON.parse(a.typeData);
      return display?.includes(location) && a.type === "announceBanner";
    });
    return announcement ? (
      <div className={className}>
        <SharedComponents.Announcements.AnnounceBanner announcement={announcement} language={i18n.languages[0]} />
      </div>
    )
      : <></>;
  }, [announcements, i18n.languages, ls]);

  const renderReleaseNote = useCallback(() => {
    const announcement = announcements?.find((a) => a.type === "releaseNote");
    return expert && announcement && !localStorage.getItem(ACKED_ANNOUNCEMENTS_LS_KEY)?.includes(announcement.announcementUid) ? (
      <SharedComponents.Modal
        mobileFullscreen
        show
        onClose={() => ackAnnouncements([announcement.announcementUid], "BOTH")}
        // Display above other modals
        zDelta={1}
      >
        <SharedComponents.Announcements.ReleaseNote announcement={announcement} language={i18n.languages[0]} />
      </SharedComponents.Modal>
    )
      : <></>;
  }, [announcements, i18n.languages, ls, expert]);

  const value = React.useMemo(() => ({
    renderAnnounceBanner,
    renderReleaseNote,
    ackAnnouncements,
    unackAnnouncementsLS,
    notifiedAnnouncements: announcements?.filter((a) => a.notify) || [],
  }), [renderAnnounceBanner, renderReleaseNote, announcements, ls]);

  return (
    <AnnouncementContext.Provider value={value}>
      {children}
      {renderReleaseNote()}
    </AnnouncementContext.Provider>
  );
};

const useAnnouncements = () => React.useContext(AnnouncementContext);

export default AnnouncementContext;
export { AnnouncementProvider, useAnnouncements };
