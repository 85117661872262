import { useQuery } from "@apollo/client";
import * as React from "react";
import { useEffect, useState } from "react";
import { faSlash, faWifi } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";

import { Global } from "../models/global";
import { GET_GLOBAL } from "../graphql/query";

interface AppContextType {
  ipCountry?: string | null;
  online: boolean;
}

interface Props {
  children: React.ReactNode;
}

const AppContext = React.createContext<AppContextType>({
  online: true,
});

const AppContextProvider: React.FC<Props> = ({ children }) => {
  const { data } = useQuery<{ global: Global }>(GET_GLOBAL);
  const [online, setOnline] = useState(true);

  useEffect(() => {
    setOnline(window.navigator.onLine);
    window.addEventListener("online", () => setOnline(true));
    window.addEventListener("offline", () => setOnline(false));
  }, []);

  const value = React.useMemo(() => ({
    ipCountry: data?.global?.ipCountry,
    online,
  }), [data, online]);

  return (
    <AppContext.Provider value={value}>
      {children}
      {!online && (
        <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 mb-10 bg-blue-600 border border-blue-700 px-4 py-2 rounded-lg shadow-lg">
          <div className="text-white flex space-x-2">
            <div className="relative">
              <div className="absolute"><FontAwesomeIcon icon={faSlash} /></div>
              <div><FontAwesomeIcon icon={faWifi} /></div>
            </div>
            <div>{t("offline")}</div>
          </div>
        </div>
      )}
    </AppContext.Provider>
  );
};

const useAppContext = () => React.useContext(AppContext);

export default AppContext;
export { AppContextProvider, useAppContext };
