/* eslint-disable react/button-has-type */
/* eslint-disable import/prefer-default-export */
import React, {
  useEffect, useLayoutEffect, useRef, useState,
} from "react";
import {
  VideoRoomInfo, VideoRoomStatus, useVideo, videoRoomIsOpen,
} from "@audacia-hq/shared/contexts";
import { Rnd } from "react-rnd";

import VideoSubscribers from "./VideoSubscribers";
import VideoPublisher from "./VideoPublisher";
import VideoRoomButtons from "./VideoRoomButtons";

interface UserImage {
  userUid: string;
  url: string;
}

interface Props {
  videoRoom: VideoRoomInfo;
  className?: string;
  userImages?: UserImage[]
}

export const VideoRoomArchive: React.FC<Props> = ({
  videoRoom, className, userImages,
}) => {
  const self = videoRoom?.participants?.find((p) => p.isMe);
  const interlocutors = videoRoom?.participants?.filter((p) => !p.isMe) || [];

  return (
    <div className={className}>
      test
      {/* <div id="video-container" className={`draggable-bounds relative w-full h-full overflow-hidden ${className || ""}`}>
        <VideoSubscribers users={
          interlocutors.reduce((acc, p) => ({
            ...acc,
            [p.userUid]: { ...p, imageUrl: userImages?.find((ui) => ui.userUid === p.userUid)?.url },
          }), {})
        }
        />
        <div className={`z-[1] absolute bottom-24 right-4 w-1/5 h-1/5 ${overlay ? "hidden sm:block" : ""}`}>
          <Rnd
            enableResizing={false}
            bounds=".draggable-bounds"
            className="z-10 p-2"
            size={{ width: "100%", height: "100%" }}
          >
            <VideoPublisher
              user={{
                ...self,
                imageUrl: userImages?.find((ui) => ui.userUid === self.userUid)?.url,
              }}
            />
          </Rnd>
        </div>

        <div className="absolute z-20 flex flex-row justify-center gap-4 bottom-4 right-0 left-0">
          <VideoRoomButtons fullscreenEltId="video-container" />
        </div>

      </div> */}
    </div>

  );
};
