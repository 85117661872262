import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useField } from "formik";
import { Question } from "@audacia-hq/shared/models";

import Input from "../../form/Input";
import Radio from "../../form/Radio";
import LargeButton from "../LargeButton";

interface Props {
  question: Question;
}

const UniqueChoiceQuestion: React.FC<Props> = ({
  question,
}) => {
  const { t } = useTranslation("survey");
  const [props, meta, helpers] = useField("value");
  const [isOtherValue, setIsOtherValue] = useState(false);

  const value = props.value[0];

  // If only 2 options and we don't allow other, show it like a likert scale. Otherwise, use radios
  if (question.settings.options.length <= 2 && !question.settings.allowOther) {
    return (
      <div className="flex flex-col">
        <h1 className="text-xl font-semibold pb-3">{question.title}</h1>
        {question.content && question.showDescription && <p className="whitespace-pre-wrap text-gray-500 pb-6">{question.content}</p>}
        <div className="flex flex-row gap-2">
          {question.settings.options.map((option) => (
            <LargeButton
              active={option.key === value}
              key={option.key}
              onClick={(value) => {
                helpers.setValue([value]);
              }}
              value={option.key}
            >

              {option.label}
            </LargeButton>
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col">
        <h1 className="text-xl font-semibold pb-3">{question.title}</h1>
        {question.content && <p className="whitespace-pre-wrap text-gray-500 pb-6">{question.content}</p>}
        <div className="flex flex-col gap-4">
          <Radio
            vertical
            value={isOtherValue ? "other" : value}
            options={[
              ...question.settings.options.map((option) => ({
                name: option.label,
                value: option.key,
              })),
              ...(question.settings.allowOther ? [
                {
                  name: t("survey:other"),
                  value: "other",
                },
              ] : []),
            ]}
            onChange={(event) => {
              if (event.target.value === "other") {
                setIsOtherValue(true);
                helpers.setValue([""]);
              } else {
                setIsOtherValue(false);
                helpers.setValue([event.target.value]);
              }
            }}
          />
        </div>

        {isOtherValue && (
          <Input
            type="text"
            name={`${props.name}.0`}
            className=""
            onChange={props.onChange}
            value={value}
          />
        )}
        {meta.error && <p className="text-red-500 pt-2">{t(meta.error)}</p>}
      </div>
    </>
  );
};

export default UniqueChoiceQuestion;
