import {
  faAnglesLeft, faChevronLeft, faChevronRight, faAnglesRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import Button from "./Button";

interface Props {
  pageCount: number;
  currentPage: number;
  goToPage: (page: number) => void;
  goToNext: () => void;
  goToPrevious: () => void
}

const Pagination: React.FC<Props> = ({
  pageCount, currentPage, goToPage, goToNext, goToPrevious,
}) => {
  const [pages, setPages] = useState<number[]>([]);

  useEffect(() => {
    setPages([...Array(pageCount)].map((i, v) => v + 1));
  }, [pageCount]);

  const shouldHidePageButton = (i: number) => {
    if (!pageCount) return true;
    if (currentPage === 1 && pageCount >= 3 && i === 3) return false;
    if (currentPage === pageCount && (i === currentPage - 1 || i === currentPage - 2)) return false;
    if (i > currentPage + 1 || i < currentPage - 1) return true;
    return false;
  };

  const shouldDisplayPageAfter = () => {
    if (!pageCount) return false;
    if (pageCount === 3) return false;
    if (currentPage + 1 < pageCount) return true;
    return false;
  };

  return (
    <div className="flex mt-3">
      <div className="flex ml-auto space-x-2">
        <div className="w-10">
          <Button size="small" theme="transparent" disabled={currentPage === 1} onClick={() => goToPage(1)}>
            <FontAwesomeIcon icon={faAnglesLeft} />
          </Button>
        </div>
        <div className="w-10">
          <Button size="small" theme="transparent" disabled={currentPage === 1} onClick={goToPrevious}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </Button>
        </div>
      </div>
      <div className="flex m-auto space-x-2">
        {(currentPage >= 3 && pageCount > 3) && (<div className="text-slate-500">...</div>)}
        {pages.map((i) => (
          <div className={`w-10 ${shouldHidePageButton(i) ? "hidden" : ""}`} key={i}>
            <Button
              size="small"
              theme="transparent"
              onClick={() => goToPage(i)}
              disabled={currentPage === i}
            >
              {i}
            </Button>
          </div>
        ))}
        {shouldDisplayPageAfter() && (<div className="text-slate-500">...</div>)}
      </div>
      <div className="flex mr-auto space-x-2">
        <div className="w-10">
          <Button size="small" theme="transparent" disabled={currentPage === pageCount} onClick={goToNext}>
            <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        </div>
        <div className="w-10">
          <Button size="small" theme="transparent" disabled={currentPage === pageCount} onClick={() => goToPage(pageCount)}>
            <FontAwesomeIcon icon={faAnglesRight} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
