export enum SurveyStatuses {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  PULLED = "PULLED",
  CLOSED = "CLOSED",
}

export enum SurveyLanguages {
  FR = "FR",
  DE = "DE",
}

export enum SurveyUserTypes {
  MEMBER = "MEMBER",
  EXPERT = "EXPERT",
}

export enum QuestionTypes {
  SHORT_TEXT = "SHORT_TEXT",
  LONG_TEXT = "LONG_TEXT",
  NUMERIC = "NUMERIC",
  LIKERT_SCALE = "LIKERT_SCALE",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  UNIQUE_CHOICE = "UNIQUE_CHOICE",
  INTRODUCTION = "INTRODUCTION",
  CONCLUSION = "CONCLUSION",
  EMAIL = "EMAIL",
}

export enum QuestionActions {
  NEXT = "NEXT",
  GOTO = "GOTO",
  END = "END",
}

export interface PostAction {
  action?: string;
  amount?: number;
}

export interface SurveyType {
  surveyUid?: string;
  title: string;
  description?: string;
  language: keyof typeof SurveyLanguages;
  country?: string;
  createdAt?: string;
  startDate: string;
  endDate: string;
  targetQuery?: string;
  singleResponse: boolean;
  status?: keyof typeof SurveyStatuses;
  context?: string;
  userType?: keyof typeof SurveyUserTypes;
  questions?: Question[]
  maxOrder?: number;
  responsesCount?: number;
  postActions: PostAction[];
  advertised?: boolean;
}

export interface SurveyResponse {
  userUid: string,
  responses: QuestionResponse[];
  createdAt: string;
}

interface QuestionResponse {
  questionResponseUid: string;
  questionUid: string;
  responseUid: string;
  userUid: string;
  response: string[];
}

export interface Question {
  questionUid?: string;
  title: string;
  content?: string;
  required: boolean;
  showQuestion: boolean;
  showDescription: boolean;
  type: keyof typeof QuestionTypes;
  settings: QuestionSettings;
  surveyUid?: string;
  order: number;
}

export interface QuestionSettings {
  allowOther?: boolean;
  options?: { key: string, label: string, action: { type: keyof typeof QuestionActions, questionUid?: string } }[]
  min?: number;
  max?: number;
  step?: number;
  minLabel?: string;
  midLabel?: string;
  maxLabel?: string;
  button?: { text?: string, link?: string };
}
