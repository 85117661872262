import * as React from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
  fromPromise,
  NormalizedCacheObject,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { User } from "firebase/auth";
import { generateTraceContext } from "@audacia-hq/shared/utils";

import { useAuth } from "./AuthContext";

declare global {
  interface Window {
    __APOLLO_STATE__?: unknown;
  }
}

const link = (apiBaseHost: string) => createHttpLink({ uri: `${apiBaseHost}/graphql` });
const setAuthLink = (user?: User) => setContext(async (request, prevContext) => {
  if (!user) {
    return {
      headers: {
        ...prevContext.headers,
        traceparent: generateTraceContext(),
      },
    };
  }
  // eslint-disable-next-line no-unused-vars
  const token = await user.getIdToken();
  return {
    headers: {
      ...prevContext.headers,
      authorization: `Bearer ${token}`,
      traceparent: generateTraceContext(),
    },
  };
});

const logoutLink = (user?: User) => onError(({
  graphQLErrors, operation, forward,
}) => {
  if (graphQLErrors?.some((err) => err.extensions.code === "UNAUTHENTICATED") && user) {
    return fromPromise(user.getIdToken(true))
      .filter((value) => Boolean(value))
      .flatMap((token) => {
        operation.setContext({
          headers: {
            ...operation.getContext().headers,
            authorization: `Bearer ${token}`,
            traceparent: generateTraceContext(),
          },
        });
        return forward(operation);
      });
  }
  return undefined;
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        member: {
          merge: true,
        },
      },
    },
    Expert: {
      keyFields: ["expertUid"],
    },
    LivechatRoom: {
      keyFields: ["livechatRoomUid"],
    },
  },
});

if (typeof window !== "undefined" && window.__APOLLO_STATE__) cache.restore(window.__APOLLO_STATE__ as NormalizedCacheObject);

export const initializeApolloClient = (apiBaseHost: string, user?: User) => new ApolloClient({
  link: from([setAuthLink(user), logoutLink(user), link(apiBaseHost)]),
  cache,
});

export const ApolloWrapper: React.FC<{children: React.ReactNode, apiBaseHost: string}> = ({ children, apiBaseHost }) => {
  const { user, loading } = useAuth();
  const [client, setClient] = React.useState<ApolloClient<NormalizedCacheObject>>();

  React.useEffect(() => {
    if ((!user && loading) || loading) return;
    setClient(initializeApolloClient(apiBaseHost, user));
  }, [user, loading]);

  return (
    <>
      {client && (
        <ApolloProvider client={client}>
          {children}
        </ApolloProvider>
      )}
    </>
  );
};
