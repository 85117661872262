import React from "react";

interface Props {
  message: string | React.JSX.Element;
  className?: string;
  groupClassName?: string;
  bgColor?: string;
  position?: "top" | "bottom" | "right" | "left";
  positionOffset?: string;
  arrow?: boolean;
  openDelay?: number;
  closeDelay?: number;
}

const Tooltip: React.FC<React.PropsWithChildren<Props>> = ({
  message, className, groupClassName, bgColor, position, positionOffset, arrow, openDelay, closeDelay, children,
}) => {
  const arrowSize = "5px";
  const defaultOffset = "8px";
  const defaultBgColor = "black";

  const positionStyle = (pos) => {
    switch (pos) {
      case "right": return { top: "50%", right: `-${positionOffset || defaultOffset}` };
      case "left": return { top: "50%", left: `-${positionOffset || defaultOffset}` };
      case "bottom": return { bottom: `-${positionOffset || defaultOffset}`, left: "50%" };
      case "top":
      default: return { top: `-${positionOffset || defaultOffset}`, left: "50%" };
    }
  };

  const positionClass = (pos) => {
    switch (pos) {
      case "right": return "translate-x-full translate-y-[-50%]";
      case "left": return "translate-x-[-100%] translate-y-[-50%]";
      case "bottom": return "translate-x-[-50%] translate-y-full";
      case "top":
      default: return "translate-x-[-50%] translate-y-[-100%]";
    }
  };

  const arrowPositionStyle = (pos) => {
    switch (pos) {
      case "right": return {
        top: "50%",
        right: "100%",
        marginTop: `-${arrowSize}`,
        borderColor: `transparent ${bgColor || defaultBgColor} transparent transparent`,
      };
      case "left": return {
        top: "50%",
        left: "100%",
        marginTop: `-${arrowSize}`,
        borderColor: `transparent transparent transparent ${bgColor || defaultBgColor}`,
      };
      case "bottom": return {
        bottom: "100%",
        left: "50%",
        marginLeft: `-${arrowSize}`,
        borderColor: `transparent transparent ${bgColor || defaultBgColor} transparent`,
      };
      case "top":
      default: return {
        top: "100%",
        left: "50%",
        marginLeft: `-${arrowSize}`,
        borderColor: `${bgColor || defaultBgColor} transparent transparent transparent`,
      };
    }
  };

  return (
    <div className={`group relative inline-block w-fit ${groupClassName || ""}`}>
      {children}
      <div
        className={`absolute scale-0 transition-all group-hover:scale-100 rounded p-3 text-xs text-white z-50
        ${className || ""} ${positionClass(position)}`}
        style={{ ...positionStyle(position), backgroundColor: bgColor || defaultBgColor }}
      >
        {message}
        {arrow && (
          <div
            className="absolute border"
            style={{
              ...arrowPositionStyle(position),
              borderWidth: arrowSize,
            }}
          >
            {" "}
          </div>
        )}
      </div>

    </div>
  );
};

export default Tooltip;
