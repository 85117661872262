import React, { useState } from "react";
import { DateUtils } from "@audacia-hq/shared/utils";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { ITicket } from "@audacia-hq/shared/models";

import Form from "../form";
import Button from "../Button";

import TicketStatusTag from "./TicketStatusTag";
import TicketExchange from "./TicketExchange";

interface Props {
  ticket: ITicket;
  refetch: () => void
  addTicketAnswer: (content: string, attachments: File[]) => Promise<any>;
  handleBackButtonClick: () => void;
  getFile:(string) => Promise<string>;
  t: (key: string, params?: any) => string;
}

const mimeTypes = {
  "image/jpeg": [".jpeg", ".jpg"],
  "image/png": [".png"],
  "image/bmp": [".bmp"],
  "image/webp": [".webp"],
  "video/mp4": [".mp4"],
  "video/quicktime": [".mov"],
  "application/pdf": [".pdf"],
};

const TicketItem: React.FC<Props> = ({
  ticket, refetch, addTicketAnswer, handleBackButtonClick, getFile, t,
}) => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      content: "",
      attachments: [],
    },
    validationSchema: Yup.object().shape({
      content: Yup.string().required(t("common:errors.required")).min(2, t("common:errors.minLength", { length: 200 })),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      setLoading(true);
      addTicketAnswer(values.content, values.attachments).then(refetch).then(() => {
        formik.resetForm();
        setLoading(false);
      }).catch(() => setLoading(false));
    },
  });

  const isAnswerDisabled = ticket.status === "RESOLVED";

  return (
    <div className="space-y-4">
      <button onClick={handleBackButtonClick} type="button" className="flex gap-1 items-center mb-9">
        <FontAwesomeIcon className="text-2xl fa-solid fa-fw -ml-2" icon={faAngleLeft} />
        <span>{t("common:back")}</span>
      </button>
      <div className="flex flex-wrap gap-3 items-center justify-between">
        <div className="text-2xl font-semibold leading-tight tracking-tight text-gray-900">
          {t("support:tickets.detailTitle")}
        </div>
      </div>

      <div className="my-4 shadow p-4 text-sm rounded-md grid md:grid-cols-2 gap-4">
        <div>
          <span className="font-semibold">{`${t("common:date")}: `}</span>
          <span>{DateUtils.from(ticket.createdAt).formatDateTime()}</span>
        </div>
        {ticket.subject && (
          <div>
            <span className="font-semibold">{`${t("common:subject")}: `}</span>
            <span>{t(`support:subjects.${ticket.subject}`)}</span>
          </div>
        )}
        {ticket.userPseudo && (
          <div>
            <span className="font-semibold">{`${t("common:pseudo")}: `}</span>
            <span>{ticket.userPseudo}</span>
          </div>
        )}
        {ticket.userEmail && (
          <div>
            <span className="font-semibold">{`${t("common:email")}: `}</span>
            <span>{ticket.userEmail}</span>
          </div>
        )}
        {ticket.userFirstname && (
          <div>
            <span className="font-semibold">{`${t("common:firstname")}: `}</span>
            <span>{ticket.userFirstname}</span>
          </div>
        )}
        {ticket.userLastname && (
          <div>
            <span className="font-semibold">{`${t("common:lastname")}: `}</span>
            <span>{ticket.userLastname}</span>
          </div>
        )}

      </div>
      {!isAnswerDisabled && (
        <div className="my-4 rounded-md space-y-4">
          <FormikProvider value={formik}>
            <Form.InputTextArea
              label={t("common:answer")}
              name="content"
              onChange={formik.handleChange}
              value={formik.values.content}
              className="w-full"
              error={formik.errors.content}
            />
            <Form.InputFile
              name="attachments"
              value={formik.values.attachments}
              setFieldValue={formik.setFieldValue}
              setFieldTouched={formik.setFieldTouched}
              maxFiles={4}
              maxFileSize={10 * 1024 ** 2}
              t={t}
              mimeTypes={mimeTypes}
            />
            <div className="text-right">
              <Button onClick={() => !loading && formik.submitForm()} loading={loading} data-pw="ticketSubmit">{t("common:send")}</Button>
            </div>
          </FormikProvider>
        </div>
      )}
      <hr />
      {ticket.exchanges.map((ex) => (
        <TicketExchange key={`ticket-exchange-${ex.ticketExchangeUid}`} isAnswer={ex.origin !== "USER"} ticketExchange={ex} getFile={getFile} t={t} />
      )).reverse()}
    </div>
  );
};

export default TicketItem;
