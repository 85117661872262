import React, { useMemo } from "react";

export const detectWebRTCSupport = (): boolean => {
  // Must TS ignore otherwise it complains that getUserMedia is always defined so should be called instead. This is
  // a compatability check so we can't make that assumption.
  
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Boolean(navigator.mediaDevices && navigator.mediaDevices.getUserMedia && window.RTCPeerConnection && window.RTCDataChannel);
}

interface Lock {
  captured: boolean;

  // Allow forcing
  forceCapture: () => void;
}

/**
 * Tries to obtain a lock, to avoid several tabs doing the same thing (in this case, RTC)
 * @param name Identifier, must be unique to avoid overlap
 */
export const useTabLock = (name: string): Lock => {
  const tabUuid = useMemo(() => Math.random().toString(36).substring(2), []);
  const [captured, setCaptured] = React.useState(false);
  const channel = useMemo(() => new BroadcastChannel(`lock-${name}`), [name]);
  const lockCheckTimeout = React.useRef<number>();

  const forceCapture = () => {
    setCaptured(true);

    channel.postMessage({verb: "refuse"});
  };

  React.useEffect(() => {
    if (!captured) {
      // Try to capture the lock
      channel.postMessage({verb: "lock", tabUuid});
      clearTimeout(lockCheckTimeout.current);
      lockCheckTimeout.current = window.setTimeout(() => {
        // console.warn("Lock claimed", tabUuid);
        // Tab has 1 second to refuse our lock. If it doesn't, we assume we have it.
        setCaptured(true);

        clearTimeout(lockCheckTimeout.current);
      }, 1000);
    }
    
    const listener = (event: MessageEvent) => {
      // Ignore our own messages
      if (event.data.tabUUid === tabUuid) return;

      if (event.data.verb === "lock" && captured) {
        // console.warn("Refusing lock request from", event.data.tabUuid, "as we hold the lock at", tabUuid);
        // If we hold the lock, refuse it. And store their ID to be given it next
        channel.postMessage({
          verb: "refuse", tabUuid
        });
      }

      if (event.data.verb === "refuse") {
        // console.warn("Lock refused by", event.data.tabUuid);
        clearTimeout(lockCheckTimeout.current);
        // Someone else has the lock. Give it up.
        setCaptured(false);
      }

      if (event.data.verb === "release") {
        // console.warn("Lock was released from", event.data.tabUuid, ". Attempting to claim");
        // Someone else has released the lock. Broadcast a request to lock.
        channel.postMessage({verb: "lock", tabUuid});
        clearTimeout(lockCheckTimeout.current);
        lockCheckTimeout.current = window.setTimeout(() => {
          // console.warn("Lock claimed", tabUuid);
          // Tab has 1 second to refuse our lock. If it doesn't, we assume we have it.
          setCaptured(true);

          // Refuse all other potential locks
          channel.postMessage({verb: "refuse", tabUuid});

          clearTimeout(lockCheckTimeout.current);
        }, 1000);
      }
    };

    channel.addEventListener("message", listener);

    const releaser = () => {
      channel.postMessage({verb: "release", tabUuid});
    }
    // On window close, release the lock
    window.addEventListener("beforeunload", releaser);

    return () => {
      // Release lock
      clearTimeout(lockCheckTimeout.current);
      channel.postMessage({verb: "release", tabUuid});
      channel.removeEventListener("message", listener);
      window.removeEventListener("beforeunload", releaser);
    };
  }, [captured, channel, tabUuid]);

  // console.log("Lock state", captured, tabUuid);
  return {captured, forceCapture};
};