import * as React from "react";
import { Listbox } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  Field, FieldProps,
} from "formik";
import clsx from "clsx";

interface Props {
  value?: string | string[];
  name?: string;
  error?: boolean;
  disabled?: boolean;
  renderSelected?: (value: string | string[]) => React.ReactNode;
  onChange: (value: string) => void;
  inline?: boolean;
  fit?: boolean;
  fitOptions?: boolean;
  className?: string;
  children: React.ReactNode;
  multiple?: boolean;
  label?: string;
  dataPW?: string;
  hint?:string;
  alignTo?: "right" | "left";
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const Select: React.FC<Props> = ({
  value,
  name,
  error,
  disabled,
  renderSelected,
  onChange,
  inline,
  fit,
  fitOptions,
  className,
  children,
  multiple,
  label,
  dataPW,
  hint,
  alignTo,
}) => (name ? (

  <Field name={name}>
    {({ meta }: FieldProps) => (
      <div>
        {label && (<span className="mb-2 block">{label}</span>)}
        {hint && <span className="mb-2 block text-xs">{hint}</span>}
        <Listbox defaultValue={value} onChange={onChange} disabled={disabled} multiple={!!multiple}>
          <div className={`mt-1 relative ${inline ? "inline-block" : ""}`}>
            <Listbox.Button
              data-pw={dataPW}
              className={`relative min-h-[40px] w-full border ${
                error ? "border-red-300" : "border-gray-300"
              } rounded-lg shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm ${disabled ? "bg-gray-100 bg-clip-padding opacity-70 text-gray-600" : "bg-white"} ${className}`}
            >
              <span className="flex items-center">
                {((value || value === "") && renderSelected ? renderSelected(value) : value)}
              </span>
              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <FontAwesomeIcon icon={faSort} />
              </span>
            </Listbox.Button>
            <Listbox.Options
              data-pw={`${dataPW}-options`}
              className={
                clsx(
                  "absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 min-w-fit rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm",
                  alignTo === "right" ? "right-0" : "",
                )
              }
            >
              {children}
            </Listbox.Options>
          </div>
        </Listbox>
        {meta.error && meta.touched && (
          <span className="text-red-500">{meta.error}</span>
        )}
      </div>
    )}
  </Field>
) : (
  <>
    {label && (<span className="mb-2 block">{label}</span>)}
    {hint && <span className="mb-2 block text-xs">{hint}</span>}
    <Listbox value={value} onChange={onChange} disabled={disabled} multiple={!!multiple}>
      <div className={`mt-1 relative ${fit ? "" : "w-full"} ${inline ? "inline-block" : ""}`}>
        <Listbox.Button
          className={`relative w-full  border ${
            error ? "border-red-500" : "border-gray-300"
          } rounded-lg shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm ${disabled ? "bg-gray-100 bg-clip-padding opacity-70 text-gray-600" : "bg-white"} ${className}`}
          data-pw={dataPW}
        >
          <span className="flex items-center h-5">
            {((value || value === "") && renderSelected ? renderSelected(value) : value)}
          </span>
          <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <FontAwesomeIcon icon={faSort} />
          </span>
        </Listbox.Button>
        <Listbox.Options
          className={
            clsx(
              "absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm",
              alignTo === "right" ? "right-0" : "",
              fitOptions ? "min-w-fit" : "w-full",
            )
          }
          data-pw={`${dataPW}-options`}
        >
          {children}
        </Listbox.Options>
      </div>
    </Listbox>
  </>
));

interface OptionProps {
  value: string;
  text: string;
  icon?: React.ReactNode;
  hidden?: boolean;
  disabled?: boolean;
  dataPW?: string;
}
const Option: React.FC<OptionProps> = ({
  value, text, icon, disabled, hidden, dataPW,
}) => (
  <Listbox.Option
    className={({ active }) => classNames(
      active && "bg-slate-100 ",
      "cursor-default select-none relative py-2 pl-3 pr-9 text-slate-800",
    )}
    value={value}
    disabled={disabled}
    hidden={hidden}
    data-pw={dataPW}
  >
    {(i) => (
      <>
        <div className="flex items-center">
          {icon}
          <span
            className={`${
              i.selected ? "font-semibold" : "font-normal"
            } ml-3 block truncate`}
          >
            {text}
          </span>
        </div>
        {i.selected ? (
          <span
            className={`${
              i.active ? "text-white" : "text-blue-600"
            } absolute inset-y-0 right-0 flex items-center pr-4`}
          >
            <FontAwesomeIcon icon={faCheck} />
          </span>
        ) : null}
      </>
    )}
  </Listbox.Option>
);

export default Select;
export { Option };
