import React from "react";

interface Props {
  volume: number;
  threshold?: number;
  className?: string;
}

const VolumeActivityBadge: React.FC<Props> = ({
  volume, threshold, className,
}) => {
  const activityPercent = threshold && volume < threshold ? 0 : Math.min(volume, 100);

  const leftRef = React.useRef<HTMLDivElement>(null);
  const rightRef = React.useRef<HTMLDivElement>(null);
  const centreRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (leftRef.current && rightRef.current && centreRef.current) {
      leftRef.current.style.height = `${16 + (activityPercent * 0.4)}%`;
      centreRef.current.style.height = `${16 + (activityPercent) * 0.8}%`;
      rightRef.current.style.height = `${16 + (activityPercent * 0.4)}%`;
    }
  }, [activityPercent]);

  return (
    <div className={`relative flex flex-row items-center justify-center rounded-full bg-green w-10 h-10 gap-[2px] ${className || ""}`}>
      <div className="bg-white rounded-full w-1/6" ref={leftRef} />
      <div className="bg-white rounded-full w-1/5" ref={centreRef} />
      <div className="bg-white rounded-full w-1/6" ref={rightRef} />
    </div>
  );

  // return <div className="rounded-full w-10 h-10" style={{ border: `${activityPercent / 5}px solid red` }} />;
};

export default VolumeActivityBadge;
