import React, { lazy, useEffect } from "react";
import {
  BrowserRouter as Router, Route, Routes,
} from "react-router-dom";
import {
  ApolloWrapper, AuthProvider, TabProvider,
} from "@audacia-hq/shared/contexts";
import { useTranslation } from "react-i18next";
import { setDayjsGlobalLocale } from "@audacia-hq/shared/utils";
import TagManager from "react-gtm-module";
import "./App.css";
import "../sentry.config";

import config from "./modules/common/services/config";
import { addAuthInterceptor } from "./modules/common/services/axios";
import { ExpertProvider } from "./context/ExpertContext";
import { WSProvider } from "./context/WSContext";
import ConsultationManager from "./modules/common/components/ConsultationManager";
import { PlanningProvider } from "./context/PlanningContext";
import { AppContextProvider } from "./context/AppContext";
import Support from "./modules/support/Support";
import { AnnouncementProvider } from "./context/AnnouncementContext";
import { SurveyProvider } from "./context/SurveyContext";

const LandingPage = lazy(() => import("./modules/landingPage/LandingPage"));
const Recruitment = lazy(() => import("./modules/recruitment/Recruitment"));
const Dashboard = lazy(() => import("./modules/dashboard/Dashboard"));

TagManager.initialize({ gtmId: config.gtmId });

function App() {
  const { i18n } = useTranslation(["common", "expert", "planning", "survey"]);
  setDayjsGlobalLocale(i18n.languages[0]);

  useEffect(() => {
    document.querySelector("html")?.setAttribute("lang", i18n.languages[0]);
    document.title = i18n.languages[0]?.toLowerCase().startsWith("de") ? "SatinaCenter.com - Manticus Admin" : "SatinaCenter.com - IdealVoyance Admin";
  }, [i18n.languages[0]]);

  return (
    <div className="App">
      <Router>
        <AuthProvider config={config} addAuthInterceptor={addAuthInterceptor}>
          <WSProvider>
            <ApolloWrapper apiBaseHost={`${config.apiBaseHost}/iv-expert-gw`}>
              <AppContextProvider>
                <ExpertProvider>
                  <PlanningProvider>
                    <AnnouncementProvider>
                      <SurveyProvider>
                        <TabProvider>
                          <ConsultationManager>
                            <Routes>
                              <Route path="/become-expert" element={<LandingPage />} />
                              <Route path="/recruitment" element={<Recruitment />} />
                              <Route path="/support" element={<Support />} />
                              <Route path="/*" element={<Dashboard />} />
                            </Routes>
                          </ConsultationManager>
                        </TabProvider>
                      </SurveyProvider>
                    </AnnouncementProvider>
                  </PlanningProvider>
                </ExpertProvider>
              </AppContextProvider>
            </ApolloWrapper>
          </WSProvider>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
