import * as React from "react";
import { FormikErrors, FormikValues, useField } from "formik";
import { Accept, FileRejection, useDropzone } from "react-dropzone";
import {
  faXmarkCircle, faPaperclip, faFilePdf, faFileVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface MimeAccept {
  "image/jpeg"?: string[];
  "image/png"?: string[];
  "image/bmp"?: string[];
  "application/json"?: string[];
  "application/pdf"?: string[];
  "text/csv"?: string[];
  "text/plain"?: string[];
  "audio/mp3"?: string[];
  "audio/mpeg"?: string[];
}

interface Props {
  className?: string;
  name: string;
  value: File[];
  setFieldValue: (field: string, value: File[], shouldValidate?: boolean | undefined) => Promise<FormikErrors<FormikValues | void>>;
  setFieldTouched: (field: string, touched?: boolean, shouldValidate?: boolean | undefined) => Promise<FormikErrors<FormikValues | void>>;
  maxFiles?: number,
  maxFileSize?: number,
  mimeTypes?: MimeAccept,
  hidePreview?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t: (key: string, params?: any) => string
}

interface PreviewProps {
  className?: string;
  file: File;
  fileIdx: number;
  removeCallback: (idx: number) => void;
}

const InputFile: React.FC<Props> = ({
  className, name, value, setFieldValue, setFieldTouched, maxFiles, maxFileSize, mimeTypes,
  hidePreview, t,
}) => {
  const [field, meta, helpers] = useField(name);
  const [errors, setErrors] = React.useState<string[]>([]);

  const onDrop = (droppedFiles: File[]) => {
    const files = [...value, ...droppedFiles];
    if (maxFiles && files.length > maxFiles) {
      setFieldValue(name, files.slice(0, maxFiles), false);
      setErrors([t("common:errors.files.max", { maxFiles })]);
    } else {
      setFieldValue(name, files, false);
      setErrors([]);
    }
    setFieldTouched(name, true);
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    const rejectedErrors: string[] = [];
    if (mimeTypes && fileRejections.filter((evt) => evt.errors.filter((err) => err.code === "file-invalid-type").length > 0).length > 0) {
      rejectedErrors.push(t("common:errors.files.mime", {
        mimeTypes: Object.values(mimeTypes).reduce((allExts, mimeExts) => allExts.concat(mimeExts), []).join(", "),
      }));
    }
    if (maxFileSize && fileRejections.filter((evt) => evt.errors.filter((err) => err.code === "file-too-large").length > 0).length > 0) {
      rejectedErrors.push(t("common:errors.files.size", { maxSize: fileSizeApproximation(maxFileSize) }));
    }

    if (rejectedErrors.length) {
      setErrors(rejectedErrors);
    }
  };

  const fileSizeValidator = (file: File) => (maxFileSize && (file.size >= maxFileSize)
    ? {
      code: "file-too-large",
      message: `File is larger than ${fileSizeApproximation(maxFileSize)}`,
    }
    : null);

  const onRemove = (idx: number) => {
    value.splice(idx, 1);
    setFieldValue(name, value);
    setFieldTouched(name, true);
  };

  const {
    getRootProps, getInputProps, isDragActive,
  } = useDropzone({
    onDrop,
    onDropRejected,
    accept: mimeTypes as Accept || undefined,
    validator: fileSizeValidator,
  });

  const inputBase = "p-3 py-2 text-sm cursor-pointer focus:outline-none w-full border border-gray-300 rounded-md disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200";

  return (
    <div>
      <div
        className={`${inputBase} ${className} relative ${(meta.error && meta.touched) ? "border-red-500" : ""}`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getRootProps()}
      >
        <input
          name={name}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getInputProps()}
        />

        <div className={`h-full pr-6 ${meta.error && meta.touched && "text-red-500"}`}>
          {(value.length === 0 || hidePreview)
            && (isDragActive
              ? t("common:dropzone.duringDrag")
              : t("common:dropzone.initial"))}
          <div className="absolute right-4 top-1/2 translate-y-[-50%] text-xl"><FontAwesomeIcon icon={faPaperclip} /></div>
        </div>

        {
          !hidePreview
          && (
            <div id="previews" className="flex space-x-2">
              {value.map((f, idx) => (
                <FilePreview
                  className="aspect-square rounded-lg w-[5em] border p-1"
                  file={f}
                  fileIdx={idx}
                  key={f.name}
                  removeCallback={onRemove}
                />
              ))}
            </div>
          )
        }

      </div>
      {meta.error && meta.touched && (<div className="text-red-500 text-sm">{meta.error}</div>)}
      {errors.map((err, idx) => <div key={idx} className="text-red-500 text-sm">{err}</div>)}
    </div>
  );
};

const fileSizeApproximation = (size: number) => {
  const units = ["B", "KB", "MB", "GB"];
  const unit = units.find((val, idx, array) => size < 1024 ** (idx + 1)) || "GB";
  return `${Math.ceil(size / (1024 ** units.indexOf(unit)))} ${unit}`;
};

const FilePreview: React.FC<PreviewProps> = ({
  file, fileIdx, className, removeCallback,
}) => {
  const [url, setUrl] = React.useState<string>(null);

  React.useEffect(() => {
    setUrl(URL.createObjectURL(file));
  }, [file]);

  return (
    <div className={`relative group ${className}`}>
      <FontAwesomeIcon
        onClick={(evt) => {
          evt.stopPropagation();
          removeCallback(fileIdx);
        }}
        className="text-neutral-400 fa-solid hidden group-hover:block absolute -top-1 -right-1 z-20"
        icon={faXmarkCircle}
      />
      <div className="relative w-full h-full overflow-hidden">
        <div className="absolute hidden group-hover:flex inset-0 w-full h-full flex-col justify-items-center place-content-evenly items-center bg-white/70 text-[.75rem] z-10">
          <div className="max-w-full text-center break-words">{`${file.name.slice(0, 20)}${file.name.length > 20 ? "..." : ""}`}</div>
          <div className="whitespace-nowrap">{fileSizeApproximation(file.size)}</div>
        </div>
        {file.type.startsWith("image") && (
          <img
            className="absolute w-full"
            src={url}
            alt={file.name}
          />
        )}
        {file.type.endsWith("pdf") && (
          <FontAwesomeIcon icon={faFilePdf} className="h-full w-full" />
        )}
        {(file.name.endsWith("mov") || file.name.endsWith("mp4")) && (
          <FontAwesomeIcon icon={faFileVideo} className="h-full w-full" />
        )}
      </div>
    </div>
  );
};

export default InputFile;
export {
  fileSizeApproximation,
  FilePreview,
};
