"use client";

import React, {
  useEffect, useMemo, useRef, useState,
} from "react";
import { debounce } from "@audacia-hq/shared/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { UserSessionFeatureFlag } from "@audacia-hq/shared/models";

import { useEventTracker } from "./EventTrackerContext";
import { unifiedEventListener } from "./helpers";

export interface ABTestVersion {
  key: string;
  render: React.ReactNode;
}
interface Props {
  children: React.ReactNode;
  identifier: string;
  versions?: ABTestVersion[];
  renderAfterLoading?: boolean;
}

const EventTrackerWrapper: React.FC<Props> = ({
  children, identifier, versions, renderAfterLoading = false,
}) => {
  const { addEvent, userSession, loading } = useEventTracker();
  const domRef = useRef<HTMLDivElement>(null);
  const [selectedFeatureFlag, setFeatureFlag] = useState<UserSessionFeatureFlag>(userSession?.featureFlagVersions?.find((ffv) => ffv.identifier === identifier));

  const versionImplemented = versions?.find((v) => v.key === selectedFeatureFlag?.version.key);

  const handleEvent = (filterSelectors: string[], listenerType) => (e) => {
    try {
      const filterSelector = !filterSelectors?.length ? null : filterSelectors.find((s) => !!e.target?.closest(s));
      if (!filterSelectors?.length || filterSelector) {
        addEvent({
          ...unifiedEventListener(e),
          date: new Date(),
          event: e.type,
          identifier,
          key: versionImplemented ? selectedFeatureFlag.version.key : null,
          target: {
            listenerType,
            refWidth: domRef?.current?.offsetWidth,
            refHeight: domRef?.current?.offsetHeight,
            ...(filterSelector && { filterSelector }),
          },
          context: selectedFeatureFlag.context,
          app: selectedFeatureFlag.app,
        });
      }
    } catch (error) { /*  */ }
  };

  useEffect(() => {
    if (!userSession) return;
    setFeatureFlag(userSession?.featureFlagVersions?.find((ffv) => ffv.identifier === identifier));
  }, [userSession]);

  useEffect(() => {
    if (!selectedFeatureFlag || !domRef?.current || !userSession) return undefined;

    // Add listeners on the dom element
    selectedFeatureFlag.listeners?.filter((l) => l.type === "ELEMENT").forEach(({
      event,
      debounceDelay,
      filterSelectors,
      type,
    }) => domRef?.current.addEventListener(event, debounce(handleEvent(filterSelectors, type), debounceDelay || 0)));

    // Add window listeners
    selectedFeatureFlag.listeners?.filter((l) => l.type === "WINDOW").forEach(({
      event,
      debounceDelay,
      filterSelectors,
      type,
    }) => window?.addEventListener(event, debounce(handleEvent(filterSelectors, type), debounceDelay || 0)));

    addEvent({
      date: new Date(),
      event: "init",
      identifier,
      key: versionImplemented ? selectedFeatureFlag.version.key : null,
      context: selectedFeatureFlag.context,
      app: selectedFeatureFlag.app,
    });

    return () => {
      if (!selectedFeatureFlag || !domRef?.current || !userSession) return;

      // Remove listeners on the dom element
      selectedFeatureFlag.listeners?.filter((l) => l.type === "ELEMENT").forEach(({
        event,
        debounceDelay,
        filterSelectors,
        type,
      }) => domRef?.current.removeEventListener(event, debounce(handleEvent(filterSelectors, type), debounceDelay || 0)));

      // Remove window listeners
      selectedFeatureFlag.listeners?.filter((l) => l.type === "WINDOW").forEach(({
        event,
        debounceDelay,
        filterSelectors,
        type,
      }) => window?.removeEventListener(event, debounce(handleEvent(filterSelectors, type), debounceDelay || 0)));
    };
  }, [domRef, userSession, selectedFeatureFlag]);

  const renderFeature = () => {
    if (renderAfterLoading && loading) {
      return (
        <div className="w-full flex justify-center items-center">
          <FontAwesomeIcon className="text-3xl text-slate-500" icon={faCircleNotch} spin />
        </div>
      );
    }
    if (!selectedFeatureFlag || !versions?.length) return children;
    const version = versions.find((v) => v.key === selectedFeatureFlag.version.key);
    return version?.render || children;
  };

  return (<div ref={domRef}>{renderFeature()}</div>);
};

export default EventTrackerWrapper;
