import * as React from "react";
import { useEffect, useState } from "react";
import {
  Field, FieldProps, FormikErrors, FormikValues,
} from "formik";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import clsx from "clsx";
import { range } from "@audacia-hq/shared/utils";

interface Props {
  className?: string;
  name: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  initialValue: string | undefined;
  setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => Promise<FormikErrors<FormikValues | void>>;
  setFieldTouched: (field: string, touched?: boolean, shouldValidate?: boolean | undefined) => Promise<FormikErrors<FormikValues | void>>;
}

const inputBase = "text-sm focus:outline-none w-full py-2 px-3 border bg-white border-gray-300 rounded-md disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200";
const inputError = "border-red-300";
const inputDisabled = "bg-gray-50 text-gray-500 border-gray-200";
const inputPlaceholder = "text-gray-400";

const InputDateField: React.FC<Props> = ({
  className, name, label, disabled, required, initialValue, setFieldValue, setFieldTouched,
}) => {
  const { t } = useTranslation("common");

  const [day, setDay] = useState<number>(NaN);
  const [month, setMonth] = useState<number>(NaN);
  const [year, setYear] = useState<number>(NaN);

  useEffect(() => {
    const dateString = `${year}-${`0${month}`.slice(-2)}-${`0${day}`.slice(-2)}`;
    if (day && month && year && !Number.isNaN(Date.parse(dateString))) {
      setFieldValue(name, dateString);
    } else if (day || month || year) {
      setFieldTouched(name, true);
    }
  }, [day, month, name, setFieldTouched, setFieldValue, year]);
  useEffect(() => {
    if (initialValue) {
      const date = dayjs(initialValue);
      setDay(date.date());
      setMonth(date.month() + 1);
      setYear(date.year());
    }
  }, [initialValue]);

  return (
    <div className="w-full">
      {label && (
        <label
          htmlFor={name}
          className="block mb-1"
        >
          {required && "*"}
          {label}
        </label>
      )}
      <Field name={name}>

        {({ meta }: FieldProps) => (
          <>
            <div className="flex gap-x-2">
              <select
                name="day"
                className={clsx(
                  inputBase,
                  meta.error && inputError,
                  disabled && inputDisabled,
                  !day && inputPlaceholder,
                  className,
                )}
                value={day || ""}
                onChange={(e) => {
                  setDay(parseInt(e.target.value, 10));
                }}
                disabled={disabled}
              >
                <option disabled hidden value="">{t("dayPlaceholder")}</option>
                {range(1, 31).map((v) => (
                  <option key={`day-select-${v}`} value={v}>
                    {v.toLocaleString("fr-CH", { minimumIntegerDigits: 2 })}
                  </option>
                ))}
              </select>
              <select
                name="month"
                className={clsx(
                  inputBase,
                  meta.error && inputError,
                  disabled && inputDisabled,
                  !month && inputPlaceholder,
                  className,
                )}
                value={month || ""}
                onChange={(e) => {
                  setMonth(parseInt(e.target.value, 10));
                }}
                disabled={disabled}
              >
                <option disabled hidden value="">{t("monthPlaceholder")}</option>
                {range(1, 12).map((v) => (
                  <option key={`month-select-${v}`} value={v}>
                    {v.toLocaleString("fr-CH", { minimumIntegerDigits: 2 })}
                  </option>
                ))}
              </select>
              <select
                name="year"
                className={clsx(
                  inputBase,
                  meta.error && inputError,
                  disabled && inputDisabled,
                  !year && inputPlaceholder,
                  className,
                )}
                value={year || ""}
                onChange={(e) => {
                  setYear(parseInt(e.target.value, 10));
                }}
                disabled={disabled}
              >
                <option disabled hidden value="">{t("yearPlaceholder")}</option>
                {(range(new Date().getFullYear() - 100, new Date().getFullYear())).sort((a, b) => b - a).map((v) => (
                  <option key={`year-select-${v}`} value={v}>
                    {v}
                  </option>
                ))}
              </select>
            </div>
            {meta.error && (
              <span className="text-red-500">{t(meta.error)}</span>
            )}
          </>
        )}
      </Field>
    </div>
  );
};

export default InputDateField;
