import React from "react";
import { ITicket } from "@audacia-hq/shared/models";

import Pagination from "../Pagination";

import TicketListItem from "./TicketListItem";

interface Props {
  tickets: ITicket[];
  totalRows: number;
  page: number;
  pageSize: number;
  handlePageChange: (page:number) => void;
  selectTicket: (ticketUid: string) => void
  t: (key: string, params?: any) => string;
}

const TicketList: React.FC<Props> = ({
  tickets, page, pageSize, totalRows, handlePageChange, selectTicket, t,
}) => (
  <div>
    {!tickets || totalRows === 0
      ? <div className="text-lg my-6">{t("support:emptyTicketList")}</div>
      : (
        <>
          {tickets.map((ticket: ITicket) => (<TicketListItem key={`TicketListItem-${ticket.ticketUid}`} ticket={ticket} t={t} selectTicket={selectTicket} />))}
          <Pagination
            currentPage={page}
            goToNext={() => handlePageChange(page + 1)}
            goToPage={(value: number) => handlePageChange(value)}
            goToPrevious={() => handlePageChange(page - 1)}
            pageCount={Math.ceil(totalRows / pageSize)}
          />
        </>
      )}
  </div>
);

export default TicketList;
