const urlifyPseudo = (pseudo: string): string => pseudo.replace(/\s/g, "-").toLowerCase();

const unurlifyPseudo = (urlParam: string): string => decodeURI(urlParam).replace(/-/g, " ").toUpperCase();

interface UserCodeConfig {
  padLength?: number;
  padChar?: string;
  format?: string;
}

const formatUserCode = (code: number | string, options: UserCodeConfig): string | undefined => {
  const padded = options.padLength && options.padChar 
    ? String(code).padStart(options.padLength, options.padChar)
    : String(code);
  
  if(!options.format) return padded;
  const tokens = options.format.match(/\d+|\D+/g);
  const res = tokens?.reduce<{result: string; offset: number}>((aggr, token) => {
    const nbChars = parseInt(token, 10);
    if(nbChars) return {
      result: aggr.result + padded.slice(aggr.offset, aggr.offset + nbChars),
      offset: aggr.offset + nbChars,
    }
    return {
      ...aggr,
      result: ""+ aggr.result + token,
    }

  }, {result: "", offset: 0}) ;

  return res?.result;
}

const capitalizeFirst = (str: string): string => (typeof str === "string"
  ? str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)
  : str);

const capitalize = (str: string): string => (typeof str === "string"
  // eslint-disable-next-line no-useless-escape
  ? [...str.matchAll(/([\s\t\-]+)|([^\s\t\-]+)/g)]
    .map(([v, ...rest]) => capitalizeFirst(v)).join("")
  : str);

/**
 * Make sure that punctuation is not separated from the word it follows. More useful for French and German text where colons are preceded by a space.
 */
const nonBreakingPunctuation = (str: string): string => {
  const regex = /(\s)([.,;:!?])/g;
  return str.replace(regex, "\u00A0$2");
}

export {
  urlifyPseudo,
  unurlifyPseudo,
  formatUserCode,
  capitalizeFirst,
  capitalize, 
  nonBreakingPunctuation,
};
