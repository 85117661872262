/* eslint-disable react/no-danger */
import { Announcement } from "@audacia-hq/shared/models";
import * as React from "react";
import { TFunction as NextTFunction } from "next-i18next";
import { TFunction } from "i18next";
import {
  faAward, faCircleCheck, faShield, faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  announcement: Announcement;
  language: string;
  cdnUrl: string;
  t: TFunction | NextTFunction;
}

const PromoBanner: React.FC<Props> = ({
  announcement, language, cdnUrl, t,
}) => {
  const typeData = JSON.parse(announcement.typeData);

  const renderGaranties = () => (
    <>
      <div className="bg-white rounded-lg max-w-4xl m-auto hidden mt-12 py-6 px-9 gap-6 sm:grid grid-cols-4">
        <div>
          <div className="text-xl font-medium">
            <FontAwesomeIcon icon={faAward} className="inline mr-3" />
            {t("banner.quality")}
          </div>
          <div className="mt-6">
            {t("banner.qualityText")}
          </div>
        </div>
        <div>
          <div className="text-xl font-medium">
            <FontAwesomeIcon icon={faUserTie} className="inline mr-3" />
            {t("banner.experts")}
          </div>
          <div className="mt-6">
            {t("banner.expertsText")}
          </div>
        </div>
        <div>
          <div className="text-xl font-medium">
            <FontAwesomeIcon icon={faShield} className="inline mr-3" />
            {t("banner.security")}
          </div>
          <div className="mt-6">
            {t("banner.securityText")}
          </div>
        </div>
        <div>
          <div className="text-xl font-medium">
            <FontAwesomeIcon icon={faCircleCheck} className="inline mr-3" />
            {t("banner.reviews")}
          </div>
          <div className="mt-6">
            {t("banner.reviewsText")}
          </div>
        </div>
      </div>
    </>
  );

  return (
    announcement ? (
      <div
        className="flex bg-no-repeat bg-cover bg-center flex-col relative"
        style={{
          minHeight: "18rem",
          backgroundImage: typeData.url ? `url("${cdnUrl}/${typeData.url}")` : undefined,
        }}
      >
        <div className="grow py-12" style={{ backgroundColor: typeData.overlayColor }}>
          <div dangerouslySetInnerHTML={{ __html: JSON.parse(announcement.content)[language] }} />
          {typeData.displayInsuranceBlock && renderGaranties()}
        </div>
      </div>
    ) : <></>
  );
};

export default PromoBanner;
