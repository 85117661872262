import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./index.css";
import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import Loader from "./modules/common/components/Loader";

const container = document.querySelector("#root");

if (!container) throw Error();

const root = createRoot(container);
root.render(
  <Suspense fallback={<Loader />}>
    <ToastContainer autoClose={2000} />
    <App />
  </Suspense>,
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
