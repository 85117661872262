import React, { useEffect, useMemo, useState } from "react";

const MAX_MOBILE_SIZE = 768;

interface LayoutContextType {
  headerHeight: number;
  mainHeight: number;
  footerHeight: number;
  isMobile: boolean;
}

interface Props {
  headerHeight: number;
  mainHeight: number;
  footerHeight: number;
}
const defaultLayoutData = {
  headerHeight: 0,
  mainHeight: 0,
  footerHeight: 0,
  isMobile: false,
};

const LayoutContext = React.createContext<LayoutContextType>(defaultLayoutData);

const LayoutProvider: React.FC<React.PropsWithChildren<Props>> = ({
  headerHeight, mainHeight, footerHeight, children,
}) => {
  const [responsive, setResponsive] = useState<{isMobile: boolean}>({ isMobile: defaultLayoutData.isMobile });

  useEffect(() => {
    const handleResize = () => {
      setResponsive({
        isMobile: window.innerWidth < MAX_MOBILE_SIZE,
      });
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const value = useMemo(() => ({
    headerHeight,
    mainHeight,
    footerHeight,
    isMobile: responsive.isMobile,
  }), [headerHeight, mainHeight, footerHeight, responsive]);

  return (
    <LayoutContext.Provider value={value}>
      {children}
    </LayoutContext.Provider>
  );
};

const useLayout = () => React.useContext(LayoutContext);

export default LayoutContext;
export { LayoutProvider, useLayout };
