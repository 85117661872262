/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

interface Props {
  level?: "info" | "error";
}

/**
 * For system messages in chat, such as finished chat, blocked, expired, etc.
 */
const SystemMessage: React.FC<React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement> & Props>> = ({ children, level, ...attrs }) => {
  const getColors = () => {
    switch (level) {
      case "error":
        return "text-white bg-red-500";
      case "info":
      default:
        return "text-slate-600 bg-slate-200";
    }
  };

  return (
    <div className="flex justify-center my-3">
      <div className={`rounded-xl py-2 px-3 ${getColors()}`} {...attrs}>{children}</div>
    </div>
  );
};

export default SystemMessage;
