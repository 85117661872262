/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable import/prefer-default-export */
import React, { useRef, useState } from "react";
import { useVideo, VideoRoomInfo } from "@audacia-hq/shared/contexts";
import Draggable from "react-draggable";
import clsx from "clsx";
import { faMicrophoneSlash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AlertModal from "../modal/AlertModal";

import VideoSubscribers from "./VideoSubscribers";
import VideoPublisher from "./VideoPublisher";
import VideoRoomButtons from "./VideoRoomButtons";
import MutedMicrophoneWarning from "./MutedMicrophoneWarning";

interface UserImage {
  userUid: string;
  url: string;
}

interface Props {
  videoRoom: VideoRoomInfo;
  className?: string;
  overlay?: boolean;
  headerNode?: React.ReactNode;
  userImages?: UserImage[]
  overlayBarLeftNode?: React.ReactNode;
  fullViewPortOverlayCn?: string;
}

export const VideoRoom: React.FC<Props> = ({
  videoRoom, className, overlay = false, headerNode, userImages, overlayBarLeftNode, fullViewPortOverlayCn,
}) => {
  const { closeVideoRoom, userSettings, t } = useVideo();
  const [isFullScreen, setFullScreen] = React.useState<boolean>(false);
  const [isFullViewport, setFullViewport] = React.useState<boolean>(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const draggableEltRef = React.useRef<HTMLDivElement>(null);
  const fullScreenEltId = "video-container";
  const self = videoRoom?.participants?.find((p) => p.isMe);
  const interlocutors = videoRoom?.participants?.filter((p) => !p.isMe) || [];
  const [showOverlayBar, setShowOverlayBar] = useState<boolean>(false);
  const overlayBarTimeout = useRef<ReturnType<typeof setTimeout>>();
  const [closeConfirm, setCloseConfirm] = useState<boolean>(false);

  const fullScreenEventHandler = () => {
    if (!document.fullscreenEnabled) return;
    setFullScreen(document.fullscreenElement?.id === fullScreenEltId);
  };

  const toggleFullscreen = (fullscreenValue: boolean) => {
    if (!containerRef.current) return;
    if (!containerRef.current.requestFullscreen) { // iOS does not allow <div> to be fullscreen, only <video> elements
      // setFullViewport(fullscreenValue); // commented since it doesn't really change the display on iOS
      return;
    }
    if (fullscreenValue) {
      containerRef.current.requestFullscreen();
    } else {
      document.exitFullscreen().catch((err) => {
        // ignore
      });
    }
  };

  const toggleOverlayBar = () => {
    if (overlayBarTimeout.current) {
      clearTimeout(overlayBarTimeout.current);
      overlayBarTimeout.current = undefined;
    }
    setShowOverlayBar(true);
    overlayBarTimeout.current = setTimeout(() => {
      setShowOverlayBar(false);
    }, 3000);
  };

  return (
    <div className="h-full w-full flex flex-col relative">

      <div className="flex-none">
        {headerNode || <></>}
      </div>
      <div
        id="video-container"
        ref={(elt) => {
          if (!elt) return;
          containerRef.current = elt;
          elt.addEventListener("fullscreenchange", () => fullScreenEventHandler());
        }}
        className={clsx(
          "grid grid-cols-1 grid-rows-[1fr_auto] max-h-full",
          // "flex flex-col-reverse overflow-hidden",
          className || "",
          isFullViewport ? "fixed inset-0 z-[100]" : "relative w-full h-full",
          "mobile_landscape:fixed mobile_landscape:inset-0 mobile_landscape:z-[100]",
        )}
        onMouseMove={() => toggleOverlayBar()}
        onClick={() => toggleOverlayBar()}
      >
        <div
          className={clsx(
            "absolute top-0 left-0 right-0 z-50 px-4 py-3 bg-black bg-opacity-50 text-white",
            "hover:opacity-100 transition-opacity ease-in-out duration-300",
            "flex flex-row-reverse justify-between",
            showOverlayBar ? "opacity-100" : "pointer-events-none opacity-0",
          )}
        >
          <button
            type="button"
            aria-label="Close"
            onClick={() => {
              toggleFullscreen(false);
              setCloseConfirm(true);
            }}
            data-pw="close_video"
          >
            <div className="flex flex-row items-center gap-2">
              <div>{t("video.finishVideo")}</div>
              <FontAwesomeIcon icon={faXmark} className="w-5 h-5" />
            </div>
          </button>
          {overlayBarLeftNode}
        </div>

        <div className="draggable-bounds relative grow shrink min-h-0">
          <VideoSubscribers users={
            interlocutors.reduce((acc, p) => ({
              ...acc,
              [p.userUid]: { ...p, imageUrl: userImages?.find((ui) => ui.userUid === p.userUid)?.url },
            }), {})
          }
          />
          <div className={`z-[1] ${overlay ? "hidden sm:block" : ""}`}>
            <Draggable
              nodeRef={draggableEltRef}
              axis="both"
              bounds=".draggable-bounds"
            >
              {/* <div className="absolute right-4 bottom-24 w-[calc(20%+8px)] h-[calc(20%+8px)] z-10 p-2"> */}
              <div
                ref={draggableEltRef}
                className="absolute right-4 bottom-24 aspect-[4/5] max-w-[calc(25%+20px)] max-h-[calc(25%+20px)] w-auto h-full z-10 p-2"
              >
                <VideoPublisher
                  user={{
                    ...self,
                    imageUrl: userImages?.find((ui) => ui.userUid === self.userUid)?.url,
                  }}
                />
              </div>
            </Draggable>
          </div>

          <MutedMicrophoneWarning
            deviceId={userSettings.audioDevice}
            isMuted={!userSettings.audioToggle}
            className="absolute z-[11] bottom-2 left-4 right-4"
          />

        </div>
        <div className="w-full z-10 grow-0 shrink-0">
          <VideoRoomButtons
            onFullscreenChange={containerRef.current?.requestFullscreen ? (fullScreenVal) => toggleFullscreen(fullScreenVal) : undefined}
            isFullscreen={containerRef.current?.requestFullscreen ? (isFullScreen || isFullViewport) : undefined}
          />
        </div>
        <AlertModal
          open={closeConfirm}
          title={t("video.finishVideoAlertTitle")}
          validateLabel={t("video.finishVideo")}
          cancelLabel={t("common:cancel")}
          variant="warning"
          onValidate={() => {
            closeVideoRoom();
            setCloseConfirm(false);
          }}
          onClose={() => setCloseConfirm(false)}
        >
          <div>{t("video.finishVideoAlertContent", { pseudo: videoRoom?.participants?.find((p) => !p.isMe)?.name || "" })}</div>
        </AlertModal>
      </div>
    </div>

  );
};
