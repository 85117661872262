declare global {
  interface Window {
    config: Config
  }
}

export interface Config {
  labelApiKey?: string;
  apiBaseHost: string;
  wsBaseHost: string;
  cdnBaseHost: string;
  i18nBackend: string;
  api: {
    authBaseHost: string;
    labelBaseHost: string;
    storageBaseHost: string;
    ivBaseHost: string;
    invoiceBaseHost: string;
    memberBaseHost: string;
  },
  gcp: {
    apiKey: string;
    authDomain: string;
    recaptchaPublicKey: string;
  },
  app: {
    clientId: string;
    address: string;
    login: string;
    website: {
      FR: string;
      DE: string;
    };
    consultations: {
      phone: {
        expertPhoneTypes: ("FIXED_LINE" | "MOBILE" |"FIXED_LINE_OR_MOBILE")[]
        expertPhoneCountries: string[];
      }
      written: {
        timeoutHours: number;
      },
      video: {
        appId: string;
      }
    };
    contract: {
      blocking: {
        excludedRoutePrefixes: string[];
      }
    };
  }
  showExperimentalFeatures?: boolean;
  gtmId: string;
}

const { config } = window;

export const remunerationScale = [
  { minutes: 0, amount: 0.3 },
  { minutes: 500, amount: 0.35 },
  { minutes: 1000, amount: 0.4 },
  { minutes: 2000, amount: 0.42 },
  { minutes: 3000, amount: 0.44 },
  { minutes: 4000, amount: 0.46 },
  { minutes: 5000, amount: 0.48 },
  { minutes: 6000, amount: 0.5 },
  { minutes: 7000, amount: 0.52 },
  { minutes: 8000, amount: 0.53 },
  { minutes: 9000, amount: 0.54 },
  { minutes: 10000, amount: 0.55 },
  { minutes: 11000, amount: 0.56 },
  { minutes: 12000, amount: 0.57 },
  { minutes: 13000, amount: 0.58 },
  { minutes: 14000, amount: 0.59 },
  { minutes: 15000, amount: 0.6 },
  { minutes: 16000, amount: 0.61 },
  { minutes: 17000, amount: 0.62 },
  { minutes: 18000, amount: 0.63 },
  { minutes: 19000, amount: 0.64 },
  { minutes: 20000, amount: 0.65 },
  { minutes: 21000, amount: 0.66 },
];

export const seniorityScale = {
  1: 1.5,
  2: 3,
  3: 4.5,
  4: 6,
  5: 7.5,
  6: 9,
  7: 10.5,
  8: 12,
  9: 13.5,
  10: 15,
  max: 10,
  afterIncrease: 1,
};

export default config;
