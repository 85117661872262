export interface OverflowCheckResult {
  isOverflowing: boolean;
  overflowPx: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  }
}

export const checkOverflow = (element: HTMLElement, container: HTMLElement): OverflowCheckResult => {
  const elementRect = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  const overflowPx = {
    top: containerRect.top - elementRect.top,
    right: elementRect.right - containerRect.right,
    bottom: elementRect.bottom - containerRect.bottom,
    left: containerRect.left - elementRect.left,
  };

  return {
    isOverflowing: overflowPx.top > 0 || overflowPx.right > 0 || overflowPx.bottom > 0 || overflowPx.left > 0,
    overflowPx,
  };

}