/* eslint-disable jsx-a11y/anchor-is-valid */
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dayjs } from "dayjs";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
  currentDate: Dayjs;
  handleGoNext: () => void;
  handleGoPrevious: () => void;
  handleGoToToday: () => void;
  lockDays?: boolean
}

const CalendarHeader: React.FC<Props> = ({
  className,
  currentDate,
  handleGoNext,
  handleGoPrevious,
  handleGoToToday,
  lockDays,
}) => {
  const { t } = useTranslation("planning");

  return (
    <header className={`flex flex-none items-center justify-between border-b border-gray-200 py-4 px-6 ${className}`}>
      <h1 className="text-lg font-semibold text-gray-900">
        <time dateTime="2022-01">
          {t(`months.${currentDate.month() + 1}`)}
          {" "}
          {currentDate.year()}
        </time>
      </h1>
      {!lockDays && (
        <div className="flex items-center">
          <div className="ml-4 flex items-center">
            <button
              onClick={handleGoToToday}
              type="button"
              className="ml-6 rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              {t("today")}
            </button>
            <button
              onClick={handleGoPrevious}
              type="button"
              className="ml-6 rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <button
              onClick={handleGoNext}
              type="button"
              className="ml-6 rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div>
      )}

    </header>
  );
};

export default CalendarHeader;
