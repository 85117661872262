/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type ThemeType = "simple" | "primary" | "violet" | "orange" | "simple" | "red" | "blue" | "grayGhost" | "violetGhost" | "blueGhost"| "orangeGhost" | "redGhost" | "transparent" | "shadow" | "link" ;
interface Props {
  size?: "xs" | "small" | "medium" | "large";
  theme?: ThemeType
  active?: boolean;
  loading?: boolean
  icon?: React.ReactNode;
}

const Button: React.FC<Props & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>> = ({
  size = "large", loading, icon, children, theme = "primary", active, className, ...props
}) => {
  const sizeClasses = () => {
    switch (size) {
      case "xs":
        return "px-1.5 py-1 text-xs rounded";
      case "small":
        return "px-2 py-1.5 text-sm rounded";
      case "medium":
        return "px-4 py-2 text-sm rounded font-semibold";
      case "large":
      default:
        return "text-lg rounded-lg px-6 py-3";
    }
  };

  const renderClasses = {
    primary: {
      disabled: "bg-blue-300",
      active: "",
      common: "text-white bg-blue-600",
      normal: "hover:cursor-pointer hover:bg-blue-500 transition-colors duration-300",
    },
    blue: {
      disabled: "bg-blue-300",
      active: "",
      common: "text-white bg-blue-600",
      normal: "hover:cursor-pointer hover:bg-blue-500 transition-colors duration-300",
    },
    violet: {
      disabled: "bg-violet-400",
      active: "",
      common: "text-white bg-violet-700",
      normal: "hover:cursor-pointer hover:bg-violet-500 transition-colors duration-300",
    },
    orange: {
      disabled: "bg-orange-300",
      active: "",
      common: "text-white bg-orange-500",
      normal: "hover:cursor-pointer hover:bg-orange-600 transition-colors duration-300",
    },
    red: {
      disabled: "",
      active: "",
      common: "rounded-lg bg-red-600 text-white text-md",
      normal: "",
    },
    simple: {
      disabled: "",
      active: "",
      common: "rounded-lg border border-blue-600 hover:border-blue-700 text-blue-600 hover:text-blue-700",
      normal: "",
    },
    blueGhost: {
      disabled: "",
      active: "",
      common: "rounded-lg border border-blue-600 hover:border-blue-700 text-blue-600 hover:text-blue-700",
      normal: "",
    },
    orangeGhost: {
      disabled: "",
      active: "",
      common: "rounded-lg border border-orange-500 hover:border-orange-600 text-orange-500 hover:text-orange-600",
      normal: "",
    },
    violetGhost: {
      disabled: "border-violet-300 text-violet-300 ",
      active: "border-violet-700 text-violet-700",
      common: "text-violet-700 border border-violet-700 bg-white hover:bg-violet-700 hover:text-white",
      normal: "hover:cursor-pointer hover:transition-colors duration-300",
    },
    grayGhost: {
      disabled: "border-slate-400 text-slate-400 ",
      active: "border-slate-600 text-slate-600",
      common: "text-slate-800 border border-slate-600 bg-white hover:bg-slate-800 hover:border-slate-800 hover:text-white",
      normal: "hover:cursor-pointer hover:transition-colors duration-300",
    },
    redGhost: {
      disabled: "border-red-300 text-red-300",
      active: "border-red-600 text-red-600",
      common: "text-red-500 border border-red-500 font-semibold bg-white",
      normal: "hover:cursor-pointer hover:transition-colors duration-300",
    },
    transparent: {
      disabled: "text-slate-500",
      active: "text-slate-900 font-bold hover:bg-slate-500 hover:text-white",
      common: "bg-white",
      normal: "hover:cursor-pointer hover:transition-colors duration-300",
    },
    shadow: {
      disabled: "text-slate-400",
      active: "font-bold",
      common: "text-slate-900 bg-white rounded-lg shadow-md hover:bg-slate-100",
      normal: "hover:cursor-pointer hover:transition-colors duration-300",
    },
    link: {
      disabled: "!border-blue-300 !text-blue-300 ",
      active: "border-blue-600 text-blue-600",
      common: "text-blue-600 border-none bg-transparent underline",
      normal: "hover:cursor-pointer hover:transition-colors duration-300",
    },
  };

  const getRenderClasses = () => {
    const classes = renderClasses[theme];
    if (props.disabled || loading) return `${classes.common} ${classes.disabled}`;
    if (active) return `${classes.common} ${classes.active}`;
    return `${classes.common} ${classes.normal}`;
  };

  return (
    <button
      type="button"
      disabled={props.disabled || loading || active}
      className={`${sizeClasses()} text-sm md:text-md self-center ${getRenderClasses()} ${className || ""}`}
      {...props}
    >
      <div className="flex space-x-2 justify-center">
        {loading && <div><FontAwesomeIcon className="mr-3" icon={faCircleNotch} spin /></div>}
        {!loading && icon && <div>{icon}</div>}
        {children && <div>{children}</div>}
      </div>
    </button>
  );
};

export default Button;
