import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import React from "react";

import Button from "../Button";
import InputFile from "../form/InputFile";
import Modal from "../modal/Modal";

const maxFiles = 2;
const maxFileSize = 4 * 1024 ** 2; // 4MB
const mimeTypes = {
  "image/jpeg": [".jpeg", ".jpg"],
  "image/png": [".png"],
  "image/bmp": [".bmp"],
  "application/pdf": [".pdf"],
};

interface UploadAttachmentsModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (attachments: File[]) => void;
  t: (key: string) => string;
}

const UploadAttachmentsModal: React.FC<UploadAttachmentsModalProps> = ({
  open, onClose, onSubmit, t,
}) => {
  const formik = useFormik<{attachments: File[]}>({
    initialValues: {
      attachments: [],
    },
    validationSchema: Yup.object().shape({
      attachments: Yup.mixed().test("fileName", "errors.required", (value: File[]) => value && value.length > 0),
    }),
    onSubmit: (form) => {
      onSubmit(form.attachments);
      formik.resetForm();
      onClose();
    },
  });

  return (
    <Modal
      show={open}
      onClose={onClose}
      title={t("messages:uploadAttachments")}
    >
      <FormikProvider value={formik}>
        <form className="p-5" onSubmit={formik.handleSubmit}>
          <InputFile
            key="fileInput"
            className="w-full mt-5 p-2 border rounded-lg"
            name="attachments"
            value={formik.values.attachments}
            setFieldValue={formik.setFieldValue}
            setFieldTouched={formik.setFieldTouched}
            maxFiles={maxFiles}
            maxFileSize={maxFileSize}
            mimeTypes={mimeTypes}
            t={t}
          />
          <Button
            type="submit"
            theme="orange"
            className="mt-5"
            data-pw="fileAttachSubmit"
          >
            {t("common:send")}
          </Button>
        </form>
      </FormikProvider>
    </Modal>
  );
};

export default UploadAttachmentsModal;
