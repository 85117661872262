/* eslint-disable dot-notation */
/* eslint-disable import/prefer-default-export */
import React, {
  useState,
} from "react";
import { useVideo, videoRoomIsOpen } from "@audacia-hq/shared/contexts";

import Button from "../Button";
import AlertModal from "../modal/AlertModal";

interface Props {
  className?: string;
  /** Show regardless of the room's status */
  alwaysShow?: boolean;
  /** Provide a fallback value when the participants list is not up to date */
  expertPseudo?: string;
}

export const VideoRoomCloseButton: React.FC<Props> = ({ className, alwaysShow = false, expertPseudo }) => {
  const {
    t, videoRoom, closeVideoRoom, videoRoomStatus,
  } = useVideo();

  const [closeConfirm, setCloseConfirm] = useState<boolean>(false);

  return (
    <>
      {(videoRoomIsOpen(videoRoomStatus) || alwaysShow) && (
        <>
          <Button
            theme="red"
            data-pw="videoFinish"
            size="medium"
            onClick={() => setCloseConfirm(true)}
            className={className}
          >
            {t("video.finishVideo")}
          </Button>
          <AlertModal
            open={closeConfirm}
            title={t("video.finishVideoAlertTitle")}
            validateLabel={t("video.finishVideo")}
            cancelLabel={t("common:cancel")}
            variant="warning"
            onValidate={() => {
              closeVideoRoom();
              setCloseConfirm(false);
            }}
            onClose={() => setCloseConfirm(false)}
          >
            <div>{t("video.finishVideoAlertContent", { pseudo: videoRoom?.participants?.find((p) => !p.isMe)?.name || expertPseudo || "" })}</div>
          </AlertModal>
        </>
      )}

    </>
  );
};
