import React, { useEffect, useState } from "react";
import { LivechatMessage, useLayout } from "@audacia-hq/shared/contexts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import Modal from "../modal/Modal";

interface Props {
  message: LivechatMessage;
  getAttachment: (url: string) => Promise<string>;
}

const AttachmentMessage: React.FC<Props> = ({ message, getAttachment }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<string>();
  const [view, setView] = useState(false);
  const { mainHeight } = useLayout();

  useEffect(() => {
    if (!message || message.kind !== "attachment") return;
    setLoading(true);
    getAttachment(message.content).then((d) => {
      setData(d);
      setLoading(false);
    }).catch(() => ({}));
  }, [message, getAttachment]);

  return (
    <div className="relative">
      {data && !loading && (
        <>
          <Modal
            show={view}
            onClose={() => setView(false)}
          >
            <div className="flex-row justify-between">
              <div className="w-full grow">
                <img src={data} alt="attachments" style={{ maxHeight: mainHeight - 130 }} className="object-cover m-auto" />
              </div>
            </div>
          </Modal>
          <button
            type="button"
            onClick={() => setView(true)}
            className="absolute z-10 w-full h-full backdrop-blur-sm bg-white/30 opacity-0 transition-opacity duration-150 hover:opacity-100 flex items-center justify-center"
          >
            <div className="text-slate-600 text-xl"><FontAwesomeIcon icon={faEye} /></div>
          </button>
          <img src={data} alt="attachment" className="max-w-[200px] sm:max-w-[300px]" />
        </>
      )}
    </div>
  );
};

export default AttachmentMessage;
