import React from "react";
import { RadioGroup } from "@headlessui/react";
import { ISupportRelation } from "@audacia-hq/shared/models";

import RadioSelectOption from "../form/RadioSelectOption";

interface Props {
  supportRelations: ISupportRelation[];
  handleRelationChange?: ({ relationRef, relationType } : ISupportRelation) => void;
}

const SupportRelationList: React.FC<Props> = ({
  supportRelations, handleRelationChange,
}) => (
  <div>
    <RadioGroup
      value={null}
      onChange={(rel) => handleRelationChange(rel)}
      className="grid grid-cols-1 md:grid-cols-2 gap-6"
    >
      {supportRelations.map((supportRelation: ISupportRelation) => (
        <RadioGroup.Option
          key={`order-option-${supportRelation.relationRef}`}
          value={supportRelation}
        >
          <RadioSelectOption checked={false}>
            <div className="space-y-2">
              {supportRelation.title && <div className="font-semibold">{supportRelation.title}</div>}
              {supportRelation.children && <div>{supportRelation.children}</div>}
            </div>
          </RadioSelectOption>
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  </div>
);
export default SupportRelationList;
