import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Transition } from "@headlessui/react";

interface Props {
  title: string;
  titleClassName?: string;
  className?: string;
  appear?: boolean;
  defaultOpen?: boolean;
  afterOpen?: () => void;
  noCarret?: boolean;
  children: React.ReactNode;
}

const Collapse: React.FC<Props> = ({
  title, titleClassName, className, appear, defaultOpen, afterOpen, noCarret, children,
}) => {
  const [open, setOpen] = React.useState(defaultOpen || false);

  React.useEffect(() => {
    setOpen(defaultOpen || open);
  }, [defaultOpen]);

  return (
    <div>
      <div onClick={() => setOpen(!open)} onKeyDown={() => ({})} className={`cursor-pointer mb-3 ${className || ""}`}>
        {!noCarret && (
          <span className="mr-2">
            <FontAwesomeIcon
              icon={faAngleRight}
              className={`ml-1 transform transition duration-200 ease-in-out ${open ? "rotate-90" : ""}`}
            />
          </span>
        )}
        <span className={titleClassName}>{title}</span>
      </div>
      <Transition
        appear={appear}
        show={open}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 -translate-y-2"
        enterTo="transform opacity-100 translate-y-0"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 translate-y-0"
        leaveTo="transform opacity-0 -translate-y-2"
        afterEnter={afterOpen}
      >
        <div>{children}</div>
      </Transition>

    </div>
  );
};

export default Collapse;
