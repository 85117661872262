import * as React from "react";

interface Props {
  domain?: string;
  className?: string
}

const Logo: React.FC<Props> = ({ domain = "idealvoyance", className }) => (
  <div className={className}>
    {(domain.includes("idealvoyance") || domain.includes("localhost")) && (<img src="/assets/images/idealvoyance.svg" alt="IdealVoyance" className="w-full" />)}
    {domain.includes("manticus") && (<img src="/assets/images/manticus.svg" alt="Manticus" className="w-full" />)}
  </div>
);
export default Logo;
