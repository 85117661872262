import React, {
  PropsWithChildren, useCallback, useEffect, useMemo, useState,
} from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { Trans, useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SharedComponents from "@audacia-hq/shared/components";
import { SurveyType } from "@audacia-hq/shared/models";
import { v4 as uuid } from "uuid";

import { GET_ACTIVE_SURVEYS } from "../graphql/query";
import { RESPOND_TO_SURVEY, SurveyMutations } from "../graphql/mutations";
import Logo from "../modules/common/components/Logo";

import { useExpert } from "./ExpertContext";

const ANSWERED_SURVEY_KEY = "answeredSurveyUids";
const IGNORED_SURVEY_KEY = "ignoredSurveyUids";

interface SurveyContextType {
  renderSurveyBanner: (closable?: boolean, rounded?: boolean, onParticipate?: () => void) => React.ReactNode;
  renderSurvey: () => React.ReactNode;
}

const SurveyContext = React.createContext<SurveyContextType>({
  renderSurveyBanner: () => <></>,
  renderSurvey: () => <></>,
});

const SurveyProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const { expert } = useExpert();
  // Get survey id query params
  const [searchParams] = useSearchParams();
  const { t } = useTranslation("survey");

  const [surveys, setSurveys] = useState<SurveyType[]>();
  const [getSurveys] = useLazyQuery<{ activeSurveys: SurveyType[] }>(GET_ACTIVE_SURVEYS);
  const [respondToSurvey] = useMutation<SurveyMutations>(RESPOND_TO_SURVEY);

  const [responseUid, setResponseUid] = useState<string>();

  const [answeredSurveys, setAnsweredSurveys] = useState<string[]>(JSON.parse(typeof localStorage !== "undefined" ? localStorage?.getItem(ANSWERED_SURVEY_KEY) ?? "[]" : "[]") || []);
  const [ignoredSurveys, setIgnoredSurveys] = useState<string[]>(JSON.parse(typeof sessionStorage !== "undefined" ? sessionStorage?.getItem(IGNORED_SURVEY_KEY) ?? "[]" : "[]") || []);

  useEffect(() => {
    if (answeredSurveys.length) {
      localStorage.setItem(ANSWERED_SURVEY_KEY, JSON.stringify(answeredSurveys));
    } else {
      localStorage.removeItem(ANSWERED_SURVEY_KEY);
    }
  }, [answeredSurveys]);

  useEffect(() => {
    if (ignoredSurveys.length) {
      sessionStorage.setItem(IGNORED_SURVEY_KEY, JSON.stringify(ignoredSurveys));
    } else {
      sessionStorage.removeItem(IGNORED_SURVEY_KEY);
    }
  }, [ignoredSurveys]);

  const activeSurvey = useMemo(() => {
    if (!surveys?.length) return null;
    return surveys.find(
      (s) => !answeredSurveys.includes(s.surveyUid)
        // either no locale or the locale matches
        && (!s.language || s.language === locale.toUpperCase())
        // either no country or the country matches
        && (!s.country || s.country === expert?.country)
        // Either survey is not targeted or the survey is targeted and found
        && (!searchParams.get("surveyId") || s.surveyUid === searchParams.get("surveyId"))
        && expert?.status === "ACTIVATED"
        && s.advertised,
    );
  }, [surveys, answeredSurveys]);

  useEffect(() => {
    if (activeSurvey && searchParams.get("surveyId") === activeSurvey.surveyUid) {
      setResponseUid(uuid());
    }
  }, [activeSurvey]);

  useEffect(() => {
    if (!expert) {
      setSurveys(undefined);
    } else {
      getSurveys().then((res) => {
        setSurveys(res.data.activeSurveys);
      });
    }
  }, [expert?.expertUid]);

  const renderSurveyBanner = useCallback((closable = true, rounded = false, onParticipate = () => null) => (
    activeSurvey && (!closable || !ignoredSurveys.includes(activeSurvey.surveyUid)) ? (
      <div className={`bg-slate-200 py-2 md:py-3 text-black text-sm ${rounded ? "mb-4 mx-2 rounded-lg" : ""}`}>
        <div className="wrapper">
          <div className="mx-6 flex flex-row justify-between">
            <span>
              <Trans
                i18nKey="banner"
                t={t}
                components={{
                  /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
                  anchor: <span className="text-blue-600 hover:underline cursor-pointer" onClick={() => { onParticipate?.(); setResponseUid(uuid()); }} />,
                }}
              />
            </span>
            {closable && (
              <FontAwesomeIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setIgnoredSurveys([...ignoredSurveys, activeSurvey.surveyUid]);
                }}
                className="rounded-md hover:bg-slate-300 opacity-50 text-black p-1 w-4 h-4 hover:cursor-pointer"
                icon={faClose}
              />
            )}
          </div>
        </div>
      </div>
    ) : <></>
  ), [activeSurvey, ignoredSurveys]);

  const renderSurvey = useCallback(() => (
    <SharedComponents.Modal
      show={!!responseUid && !!activeSurvey}
      mobileFullscreen={false}
      size="large"
      onClose={() => {
        setResponseUid(undefined);
      }}
    >
      <div className="pb-4">
        <Logo className="m-auto w-1/2 sm:w-1/4" />
      </div>
      <hr className="pb-6" />
      <div className="h-full">
        <SharedComponents.Survey
          survey={activeSurvey}
          onResponse={async (questionUid: string, response: string[]) => {
            if (response !== undefined) {
              await respondToSurvey({
                variables: {
                  response,
                  questionUid,
                  responseUid,
                },
              });
            }
          }}
          onFinish={() => setAnsweredSurveys([...answeredSurveys, activeSurvey.surveyUid])}
        />
      </div>
    </SharedComponents.Modal>
  ), [responseUid, activeSurvey, answeredSurveys]);

  const value = React.useMemo(() => ({
    renderSurveyBanner,
    renderSurvey,
  }), [renderSurveyBanner, renderSurvey]);

  return (
    <SurveyContext.Provider value={value}>
      {children}
    </SurveyContext.Provider>
  );
};

const useSurveys = () => React.useContext(SurveyContext);

export default SurveyContext;
export { SurveyProvider, useSurveys };
