import React from "react";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { Question } from "@audacia-hq/shared/models";

import LargeButton from "../LargeButton";

interface Props {
  question: Question;
}

const LikertScaleQuestion: React.FC<Props> = ({
  question,
}) => {
  const { t } = useTranslation("survey");
  const [props, meta, helpers] = useField("value");

  const handleChange = (value: string) => {
    helpers.setValue([value]);
  };

  const value = props.value[0];
  const valuesCount = question.settings.max - question.settings.min + 1;

  return (
    <>
      <div className="flex flex-col">
        <h1 className="text-xl font-semibold pb-3">{question.title}</h1>
        {question.content && question.showDescription && <p className="whitespace-pre-wrap text-gray-500 pb-6">{question.content}</p>}
        <div className="flex flex-row gap-2">
          {new Array(valuesCount).fill(0).map((_, index) => {
            const thisValue = String(question.settings.min + index);
            return (
              <LargeButton active={value === thisValue} key={index} onClick={handleChange} value={thisValue}>
                {thisValue}
              </LargeButton>
            );
          })}
        </div>
        <div className="hidden md:flex md:flex-row md:justify-between pt-2">
          <p className="text-gray-500">{question.settings.minLabel ?? t("minLabel")}</p>
          {(valuesCount % 2 !== 0) && <p className="text-gray-500">{question.settings.midLabel ?? t("midLabel")}</p>}
          <p className="text-gray-500">{question.settings.maxLabel ?? t("maxLabel")}</p>
        </div>
        <div className="inline-block md:hidden pt-2">
          <p className="text-gray-500">{`${question.settings.min}: ${question.settings.minLabel ?? t("minLabel")}`}</p>
          {(valuesCount % 2 !== 0) && <p className="text-gray-500">{`${((valuesCount - 1) / 2) + question.settings.min}: ${question.settings.midLabel ?? t("midLabel")}`}</p>}
          <p className="text-gray-500">{`${question.settings.max}: ${question.settings.maxLabel ?? t("maxLabel")}`}</p>
        </div>
        {meta.error && <p className="text-red-500 pt-2">{t(meta.error)}</p>}
      </div>
    </>
  );
};

export default LikertScaleQuestion;
