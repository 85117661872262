/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable consistent-return */
// eslint-disable-next-line import/order
import React, { useEffect } from "react";
import { useVideo } from "@audacia-hq/shared/contexts";

import Avatar from "../Avatar";

interface Props {
  user: { userUid: string; name: string; imageUrl?: string };
}

const VideoPublisher: React.FC<Props> = ({ user }) => {
  const {
    userSettings, publisherElement, forceCamera,
  } = useVideo();

  return (
    <div
      className="rounded-xl overflow-hidden w-full h-full bg-black"
      style={{
        boxShadow: "0px 0px 0px 4px #FFFFFF45",
      }}
    >

      {publisherElement && userSettings.videoToggle && (forceCamera || userSettings.videoDevice !== null) ? (
        <div
          className="w-full h-full relative"
          ref={(elt) => { if (elt) { elt.append(publisherElement); } }}
        />
      ) : (
        <div
          className="w-full h-full flex place-content-center items-center"
        >
          <Avatar
            uid={user.userUid}
            src={user.imageUrl}
            alt={user.name}
            name={user.name}
            className="rounded-full border-2 border-gray-200 aspect-square"
            height="50%"
            style={{
              minHeight: "30px",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default VideoPublisher;
