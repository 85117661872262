import React, { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeMute } from "@fortawesome/free-solid-svg-icons";

import Button from "../Button";

interface Props {
  play: boolean;
  displayMuteButton?: boolean;
  notificationUrl?: string;
}

const RingNotification: React.FC<Props> = ({ play, displayMuteButton = true, notificationUrl = "/assets/notification.wav" }: Props) => {
  const [mute, setMute] = useState(false);
  const audio = useMemo<HTMLAudioElement>(() => new Audio(notificationUrl), []);

  const stopRinging = () => {
    setMute(true);
    audio.muted = true;
    audio.pause();
    audio.currentTime = 0;
  };

  useEffect(() => {
    if (play) {
      audio.load();
      audio.loop = true;
      audio.play().catch(() => {
        setMute(true);
      });
    } else stopRinging();

    return () => stopRinging();
  }, [play]);

  return (
    <>
      {displayMuteButton && !mute && (
        <Button
          theme="blueGhost"
          data-pw="ringNotification"
          icon={<FontAwesomeIcon icon={faVolumeMute} />}
          onClick={() => stopRinging()}
          size="small"
        />
      )}
    </>
  );
};

export default RingNotification;
