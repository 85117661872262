/* eslint-disable react/no-danger */
import React, { useEffect } from "react";

import ClientOnlyPortal from "./ClientOnlyPortal";

interface Props {
  refElement: React.MutableRefObject<HTMLDivElement>
  selector: string;
  onPrint: () => void
}

const PrintModal: React.FC<Props> = ({ refElement, selector, onPrint }) => {
  useEffect(() => {
    const app = document.querySelector("#root");
    app.setAttribute("style", "display: none");
    setTimeout(() => {
      window.print();
      app.removeAttribute("style");
      onPrint();
    }, 100);
  }, []);

  if (!refElement) return <></>;
  return (
    <ClientOnlyPortal selector={selector}>
      <div
        className="absolute bg-white top-0 left-0 w-full h-screen z-[500]"
        dangerouslySetInnerHTML={{ __html: refElement.current.innerHTML }}
      />
    </ClientOnlyPortal>
  );
};

export default PrintModal;
