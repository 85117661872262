/* eslint-disable react/no-danger */
import { Announcement } from "@audacia-hq/shared/models";
import * as React from "react";

interface Props {
  announcement: Announcement;
  language: string;
}

const ReleaseNote: React.FC<Props> = ({ announcement, language }) => (
  announcement ? (
    <div className="prose" dangerouslySetInnerHTML={{ __html: JSON.parse(announcement.content)[language] }} />
  ) : <></>
);

export default ReleaseNote;
