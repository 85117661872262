import dayjs from "dayjs";
import locales from "dayjs/locale.json";

interface DayjsLocale {
  key: string;
  name: string;
}

const dayjsLocales = locales.map((l: DayjsLocale) => l.key);

const getNavigatorLocales = () => {
  if(typeof navigator === "undefined") return [];
  return navigator?.languages || (navigator?.language ? [navigator.language] : []);
}

const matchToDayjsLocale = (locale: string) => {
  if(dayjsLocales.includes(locale)) return locale;
  const split = locale.split("-");
  if(split.length > 1 && dayjsLocales.includes(split[0])) return split[0];
  return null;
}

const loadDayjsLocale = async (locale: string) => {
  if(locale === "en") return; //already loaded by default
  await import(`dayjs/locale/${locale}.js`);
  dayjs.locale(locale);
} 

export const setDayjsGlobalLocale = (fallbackLocale: string) => {
  const found = getNavigatorLocales().some((l) => {
    const match = matchToDayjsLocale(l.toLowerCase());
    if(!match) return false;

    loadDayjsLocale(match);
    return true;
  })

  if(!found){
    const fallback = matchToDayjsLocale(fallbackLocale);
    if (fallback) loadDayjsLocale(fallback);
  } 
};