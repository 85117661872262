import React from "react";
import { Switch } from "@headlessui/react";

interface Props {
  value: boolean;
  className?: string;
  dataPW?: string;
  bgOn?: string;
  bgOff?: string
  onChange?: (state: boolean) => void;
}

const Toggle: React.FC<Props> = ({
  value, className, dataPW, bgOn, bgOff, onChange,
}) => (
  <Switch
    checked={!!value}
    onChange={(v) => { if (onChange) onChange(v); }}
    className={`relative h-6 aspect-[11/6] p-[2px] cursor-pointer rounded-full
      transition-colors duration-200 ease-in-out focus:outline-none
      ${className || ""}
      ${value ? bgOn || "bg-indigo-600" : bgOff || "bg-gray-200"}
      `}
    data-pw={dataPW}
  >
    <div className="relative flex items-center h-full aspect-[11/6] flex-shrink-0 ">
      <span
        aria-hidden="true"
        className={`pointer-events-none inline-block h-full aspect-square transform rounded-full bg-white shadow ring-0 
          transition duration-200 ease-in-out
          ${value ? "translate-x-5" : "translate-x-0"}
          `}
      />
    </div>

  </Switch>

);
export default Toggle;
