import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ISupportForm, ISupportRelation, ITicketInput } from "@audacia-hq/shared/models";
import { toast } from "react-toastify";

import Button from "../Button";
import Form from "../form";

interface User {
  firstname?: string;
  lastname?: string;
  email: string;
  pseudo?: string;
}

const mimeTypes = {
  "image/jpeg": [".jpeg", ".jpg"],
  "image/png": [".png"],
  "image/bmp": [".bmp"],
  "image/webp": [".webp"],
  "video/mp4": [".mp4"],
  "video/quicktime": [".mov"],
  "application/pdf": [".pdf"],
};

interface Props {
  user?: User;
  language: string;
  relation?: ISupportRelation;
  subjects: string[];
  relations?: React.JSX.Element;
  createTicket: (ticketInput: ITicketInput) => Promise<any>;
  handleSubjectChanges?: (subject:string) => void;
  resetRelation?: () => void;
  uploadFiles: (files: File[]) => Promise<string[]>;
  t: (key: string, params?: any) => string;
}

const SupportRequestForm: React.FC<Props> = ({
  subjects, user, language, relation, relations,
  createTicket, uploadFiles, handleSubjectChanges, resetRelation, t,
}) => {
  const [loading, setLoading] = useState(false);

  const submitForm = async (values: ISupportForm) => {
    setLoading(true);
    createTicket({
      userFirstname: values.firstname || undefined,
      userLastname: values.lastname || undefined,
      userPseudo: values.pseudo || undefined,
      userEmail: values.email,
      language: values.language,
      subject: values.subject,
      content: values.message,
      attachments: await uploadFiles(values.attachments),
      relation: values.relation,
    }).then((d) => {
      formik.resetForm();
      setLoading(false);
    }).catch((e) => {
      toast.error(t(`common:errors.${e.message}`));
      setLoading(false);
    });
  };

  const initialValues: ISupportForm = {
    pseudo: user?.pseudo || "",
    firstname: user?.firstname || "",
    lastname: user?.lastname || "",
    email: user?.email || "",
    language: language || "FR",
    subject: "",
    message: "",
    relation,
    attachments: [],
  };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string(),
    lastname: Yup.string(),
    email: Yup.string().email(t("common:errors.email.invalid")).required(t("common:errors.required")),
    subject: Yup.string().required(t("common:errors.required")),
    message: Yup.string().required(t("common:errors.required")),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitForm,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    if (!user) return;

    formik.setValues({
      ...formik.values,
      pseudo: user?.pseudo || "",
      firstname: user?.firstname || "",
      lastname: user?.lastname || "",
      email: user?.email || "",
    });
  }, [user]);

  useEffect(() => {
    formik.setFieldValue("relation", null);
    if (handleSubjectChanges) handleSubjectChanges(formik.values.subject);
  }, [formik.values.subject]);

  return (
    <FormikProvider value={formik}>
      <div className="space-y-6 flex flex-col">
        <div className="grid grid-cols-1 gap-6 w-full">
          {(!user?.lastname || !user?.lastname) && (
            <Form.Input
              type="text"
              name="firstname"
              label={t("common:firstname")}
              placeholder={t("common:firstname")}
              value={formik.values.firstname}
              className="w-full"
              onChange={formik.handleChange}
              error={formik.errors.firstname}
              disabled={!!user?.firstname}
            />
          )}
          {(!user?.lastname || !user?.lastname) && (
            <Form.Input
              type="text"
              name="lastname"
              label={t("common:lastname")}
              placeholder={t("common:lastname")}
              value={formik.values.lastname}
              className="w-full"
              onChange={formik.handleChange}
              error={formik.errors.lastname}
              disabled={!!user?.lastname}
            />
          )}
          <Form.Input
            type="text"
            name="email"
            label={`${t("common:email")} *`}
            placeholder={t("common:email")}
            value={formik.values.email}
            className="w-full"
            onChange={formik.handleChange}
            error={formik.errors.email}
            disabled={!!user?.email}
          />
          <Form.Select
            dataPW="supportSubject"
            name="subject"
            label={`${t("tickets.subject")} *`}
            onChange={(v) => formik.setFieldValue("subject", v)}
            value={formik.values.subject}
            renderSelected={(v) => v && t(`support:subjects.${v}`)}
          >
            {subjects.sort((a, b) => {
              const aTr = t(`support:subjects.${a}`);
              const bTr = t(`support:subjects.${b}`);
              if (aTr < bTr) return -1;
              if (aTr > bTr) return 1;
              return 0;
            }).map((v) => (
              <Form.Option key={v} value={v} text={t(`support:subjects.${v}`)} />
            ))}
          </Form.Select>
          {relation && (
            <div className="space-y-2 p-4 rounded-md border w-fit flex space-x-4">
              <div>
                {relation?.title && <div className="font-semibold">{relation.title}</div>}
                {relation?.children && <div>{relation.children}</div>}
              </div>
              <Button size="small" theme="red" icon={<FontAwesomeIcon icon={faTrash} />} onClick={() => resetRelation && resetRelation()} />
            </div>
          )}
          {!relation && relations}
          <Form.InputTextArea
            required
            name="message"
            label={`${t("tickets.message")} *`}
            hint={t("tickets.messageHint")}
            placeholder={t("tickets.message")}
            value={formik.values.message}
            className="w-full"
            onChange={formik.handleChange}
            error={formik.errors.message}
          />
          {user && (
            <Form.InputFile
              name="attachments"
              value={formik.values.attachments}
              setFieldValue={formik.setFieldValue}
              setFieldTouched={formik.setFieldTouched}
              maxFiles={4}
              maxFileSize={10 * 1024 ** 2}
              mimeTypes={mimeTypes}
              t={t}
            />
          )}
          <div className="text-center">

            <Button
              data-pw="ticketSubmit"
              loading={loading}
              type="submit"
              onClick={() => formik.submitForm()}
            >
              {t("common:send")}
            </Button>
          </div>
        </div>
      </div>

    </FormikProvider>
  );
};

export default SupportRequestForm;
