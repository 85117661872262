import React from "react";

interface Props {
  checked: boolean;
  children: React.ReactNode
}

const RadioSelectOption: React.FC<Props> = ({ checked, children }) => (
  <div className="flex items-center bg-white rounded-lg shadow p-4 cursor-pointer space-x-4">
    <div className="h-6 w-6 rounded-full border flex justify-center items-center bg-white">
      {checked && <div className="h-3 w-3 bg-blue-500 rounded-full" />}
    </div>
    {children}
  </div>
);

export default RadioSelectOption;
