import {
  faCircleCheck, faCircleExclamation, faCircleInfo, faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

interface Props {
  type: "alert" | "warning" | "info" | "success"
  icon?: boolean
  thin?: boolean;
}

const Alert: React.FC<React.PropsWithChildren<Props>> = ({
  type, icon = false, thin, children,
}) => {
  const getTypeClasses = () => {
    switch (type) {
      case "alert":
        return "bg-red-500 rounded text-white border-none";
      case "warning":
        return "bg-orange-600 rounded text-white border-none";
      case "success":
        return "bg-lime-500 rounded text-white border-none";
      case "info":
        return "bg-blue-500 rounded text-white border-none";
      default:
        return "";
    }
  };

  const getIconForType = () => {
    switch (type) {
      case "alert":
        return faTriangleExclamation;
      case "warning":
        return faCircleExclamation;
      case "success":
        return faCircleCheck;
      case "info":
      default:
        return faCircleInfo;
    }
  };

  return (
    <div className={`w-full ${thin ? "p-2 rounded-none" : "p-6 border rounded-lg"} ${getTypeClasses()} flex space-x-2`} data-pw={`${type}Card`}>
      {icon && <div className="text-xl"><FontAwesomeIcon icon={getIconForType()} /></div>}
      <div className="flex-1">{children}</div>
    </div>
  );
};

export default Alert;
