import React, {
  useCallback, useEffect, useRef, useState,
} from "react";

interface ChildrenProps {
  volume: number;
}

interface Props {
  audioSource?: {
    deviceId?: string;
    defaultDevice?: string;
  }
  muted?: boolean;
  children: ({ volume }: ChildrenProps) => React.ReactNode;
}

const VolumeMeter: React.FC<Props> = ({
  audioSource, muted, children,
}) => {
  const [audioStream, setAudioStream] = useState<MediaStream>();
  const [volume, setVolume] = useState(0);

  const swapAudioStream = (stream?: MediaStream) => {
    setAudioStream(stream);
  };

  useEffect(() => {
    if ((!audioSource?.deviceId && !audioSource?.defaultDevice) || muted) {
      swapAudioStream(undefined);
      return;
    }

    const audioConstraint = audioSource?.deviceId ? { deviceId: { exact: audioSource.deviceId } } : true;
    navigator.mediaDevices.getUserMedia({ audio: audioConstraint })
      .then(swapAudioStream)
      .catch(console.error);
  }, [audioSource, muted]);

  const sampleVolume = useCallback((analyser: AnalyserNode) => {
    if (!audioStream) {
      setVolume(0);
      return;
    }

    const dataArray = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(dataArray);

    const averageVolume = dataArray.reduce((sum, v) => sum + v, 0) / dataArray.length;

    setVolume(parseFloat(averageVolume.toFixed(2)));

    requestAnimationFrame(() => sampleVolume(analyser));
  }, [audioStream]);

  useEffect(() => {
    if (!audioStream) {
      setVolume(0);
      return;
    }

    const audioContext = new AudioContext();
    const source = audioContext.createMediaStreamSource(audioStream);
    const analyser = audioContext.createAnalyser();
    source.connect(analyser);

    sampleVolume(analyser);
    // eslint-disable-next-line consistent-return
    return () => {
      source.disconnect();
      analyser.disconnect();
      audioContext.close();
      audioStream?.getTracks().forEach((track) => track.stop());
    };
  }, [audioStream]);

  return (
    <>
      {children({ volume })}
    </>
  );
};

export default VolumeMeter;
