import * as React from "react";
import { useEffect, useState } from "react";
import countries from "i18n-iso-countries";
import countryFR from "i18n-iso-countries/langs/fr.json";
import countryDE from "i18n-iso-countries/langs/de.json";
import countryEN from "i18n-iso-countries/langs/en.json";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import Flag from "../Flag";

import Select, { Option } from "./Select";

interface Props {
  className?: string;
  name: string;
  label?: string;
  selected: string | undefined;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
  setSelected: (value: string) => void;
  dataPW?: string;
}

const CountrySelect: React.FC<Props> = ({
  className, name, label, selected, required, error, disabled, setSelected, dataPW,
}) => {
  const { t, i18n } = useTranslation();
  countries.registerLocale(countryFR);
  countries.registerLocale(countryDE);
  countries.registerLocale(countryEN);
  const [countryList, setCountryList] = useState<{ iso: string, name: string }[]>();

  useEffect(() => {
    setCountryList(
      Object.entries(countries.getNames(i18n.languages[0], { select: "official" }))
        .reduce<{ iso: string, name: string }[]>((acc, [iso, name]) => {
          acc.push({ iso, name });
          return acc;
        }, [])
        .sort((a, b) => a.name.localeCompare(b.name)),
    );
  }, [i18n.languages]);

  return (
    <div>
      {label && (
        <label className="block mb-1">
          {required && "*"}
          {label}
        </label>
      )}

      <Select
        className={clsx(disabled && "opacity-30", className)}
        name={name}
        value={selected}
        error={error}
        disabled={disabled}
        onChange={setSelected}
        dataPW={dataPW}
        renderSelected={(v) => (v !== ""
          ? (
            <>
              <Flag country={v as string} />
              <span className="ml-3 block truncate">{countries.getName(v as string, i18n.languages[0])}</span>
            </>
          )
          : (<span className="block truncate text-[#9ca3af]">{t("countryPlaceholder")}</span>))}
      >
        {(countryList || []).map((country) => (
          <Option
            key={country.iso}
            value={country.iso}
            icon={<Flag country={country.iso} />}
            text={country.name}
            dataPW={`${dataPW}${country.iso}`}
          />
        ))}
      </Select>
    </div>
  );
};

export default CountrySelect;
