import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://4c10bbf6810e3a122c0e51838b760a42@o4505866103816192.ingest.sentry.io/4505867349917696",
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  environment: process.env.NODE_ENV,
  tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
  enabled: process.env.NODE_ENV !== "development",
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
