import React, { PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SharedComponents from "@audacia-hq/shared/components";
import { LivechatProvider, useAuth, VideoProvider } from "@audacia-hq/shared/contexts";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Subject } from "rxjs";

import config from "../../../modules/common/services/config";
import { useExpert } from "../../../context/ExpertContext";
import { WSMessage, useWS } from "../../../context/WSContext";
import { Consultation } from "../../../models/consultation";
import { STOP_VIDEO, SEND_EVENT_LOG } from "../../../graphql/mutations";

interface ModalConfig {
  content: string;
  onValidate: () => void;
}

const ConsultationManager: React.FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth();
  const { t } = useTranslation("consultations");
  const navigate = useNavigate();
  const location = useLocation();
  const { pendingActions, getRelations } = useExpert();
  const ws = useWS();
  const [modal, setModal] = useState<ModalConfig>();

  const impersonateSession = sessionStorage.getItem("tmpSession");

  const [stopVideo] = useMutation(STOP_VIDEO);
  const [sendEventLog] = useMutation(SEND_EVENT_LOG);

  useEffect(() => {
    const sub = ws.subscribe((msg) => handleConsultationEvents(msg as WSMessage));
    return () => sub.unsubscribe();
  }, [ws]);

  useEffect(() => {
    if (!pendingActions || !pendingActions.onGoingConsultation) return;
    if (pendingActions.onGoingConsultation.status === "REQUESTED" && (
      (pendingActions.onGoingConsultation.channel === "LIVECHAT" && location.pathname !== `/livechat/${pendingActions.onGoingConsultation.channelRefUid}`)
      || (pendingActions.onGoingConsultation.channel === "VIDEO" && location.pathname !== `/video/${pendingActions.onGoingConsultation.channelRefUid}`)
    )) {
      handleNewConsultation(pendingActions.onGoingConsultation);
    }
  }, [pendingActions]);

  const handleConsultationEvents = (msg: WSMessage) => {
    switch (msg.event) {
      case "expert.consultation.requested":
      case "expert.trialConsultation.requested":
        handleNewConsultation(JSON.parse(msg.payload));
        break;
      default:
        break;
    }
  };

  const handleNewConsultation = async (consultation: Consultation) => {
    const relation = await getRelations([consultation.memberUid]);
    if (consultation.channel === "LIVECHAT") {
      setModal({
        content: t("livechat.modalContent", { pseudo: relation[0]?.memberPseudo || t("common:oneMember") }),
        onValidate: () => {
          setModal(undefined);
          navigate(`/livechat/${consultation.channelRefUid}`);
        },
      });
    }
  };

  return (
    <>
      <SharedComponents.AlertModal
        open={modal !== undefined}
        title={t("newConsultationTitle")}
        validateLabel={t("common:agree")}
        variant="info"
        onValidate={modal?.onValidate}
        onClose={() => ({})}
      >
        {modal?.content}
      </SharedComponents.AlertModal>
      <LivechatProvider
        userUid={user?.uid}
        wsBaseHost={config.wsBaseHost}
        userToken={() => user.getIdToken()}
        t={useTranslation("consultations").t}
      >
        <VideoProvider
          appId={config.app.consultations.video.appId}
          closeVideoRoomHandler={(videoRoomUid: string) => stopVideo({
            variables: { videoRoomUid },
          })}
          ws={useWS() as Subject<WSMessage>}
          t={useTranslation("consultations").t}
          namespace="consultations"
          connectivityDisabled={!!impersonateSession}
          telemetryHandler={(eventsJsonString) => sendEventLog({
            variables: {
              subject: user.uid,
              event: "expert.video.debug",
              level: "DEBUG",
              ts: `${Date.now()}`,
              metadata: JSON.stringify({
                events: eventsJsonString,
              }),
            },
          })}
          forceCamera
        >
          {children}
        </VideoProvider>
      </LivechatProvider>
    </>
  );
};

export default ConsultationManager;
