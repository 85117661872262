import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useTranslation } from "react-i18next";

import Logo from "./Logo";

interface Props {
  text?: string;
}

const Loader: React.FC<Props> = ({ text }) => {
  const { i18n } = useTranslation();
  return (
    <div className="grid grid-cols-1 h-full">
      <div className="mt-6 self-end place-self-center">
        <Logo className="text-5xl" domain={i18n.languages[0].toLowerCase().startsWith("fr") ? "idealvoyance.com" : "manticus.com"} />
      </div>
      <div className="mt-5 flex-row">
        <div className="text-center"><FontAwesomeIcon className="text-5xl text-slate-500" icon={faCircleNotch} spin /></div>
        <div className="mt-2 text-center">{text}</div>
      </div>
    </div>
  );
};

export default Loader;
