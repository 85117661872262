/* eslint-disable jsx-a11y/control-has-associated-label */
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

interface Props {
  value: number;
  max: number;
  disabled?: boolean;
  variant?: "default" | "yellow";
  onChange?: (newValue: number) => void;
}

const StarRating: React.FC<Props> = ({
  value,
  max,
  disabled = false,
  variant = "default",
  onChange = () => ({}),
}) => {
  const [hoveredStar, setHoveredStar] = React.useState(0);

  const isActive = (n: number) => n <= value;
  const isHovered = (n: number) => n <= hoveredStar;

  const handleStarHover = (star: number) => {
    setHoveredStar(star);
  };

  const handleStarClick = (star: number) => {
    onChange(star);
  };

  const getPrimaryColor = () => {
    switch (variant) {
      case "yellow":
        return "text-yellow-400";
      case "default":
      default:
        return "text-orange-400";
    }
  };

  return (
    <div className="flex">
      {new Array(max).fill(0).map((_, i) => (
        <button
          key={`star-${i}`}
          disabled={disabled}
          type="button"
          onClick={() => handleStarClick(i + 1)}
          onMouseEnter={() => handleStarHover(i + 1)}
          onMouseLeave={() => handleStarHover(0)}
          className="bg-transparent border-0 px-0.5"
        >
          <FontAwesomeIcon
            className={`${(hoveredStar > 0 && isHovered(i + 1)) || (hoveredStar === 0 && isActive(i + 1))
              ? getPrimaryColor()
              : "text-slate-200"
              }`}
            icon={faStar}
          />
        </button>
      ))}
    </div>
  );
};

export default StarRating;
