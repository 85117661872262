import React from "react";
import { Editor } from "@tinymce/tinymce-react";

interface Props {
  initialValue: string;
  value?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
}

const TinyRichTextEditor: React.FC<Props> = ({
  initialValue, value, onChange, onBlur,
}) => {
  const filePickerCallback = (callback) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.onchange = (e) => {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const base64 = (reader.result as string).split(",")[1];
        callback(`data:${file.type};base64,${base64}`, { title: file.name });
      };
      reader.readAsDataURL(file);
    };
    input.click();
  };
  return (
    <Editor
      tinymceScriptSrc="/assets/tinymce/tinymce.min.js"
      initialValue={initialValue}
      value={value}
      onEditorChange={(newValue) => onChange(newValue)}
      onBlur={() => onBlur && onBlur()}
      init={{
        menubar: false,
        toolbar_mode: "sliding",
        plugins: [
          "advlist", "autolink", "lists", "link", "image", "charmap",
          "preview", "anchor", "searchreplace", "visualblocks",
          "code", "fullscreen", "insertdatetime", "media", "table",
          "help", "wordcount",
        ],
        min_height: 300,
        file_picker_types: "image",
        file_picker_callback: filePickerCallback,
        toolbar: " bold italic underline strikethrough | blocks fontsize | forecolor backcolor | alignleft aligncenter alignright alignjustify lineheight | checklist bullist numlist indent outdent | table link image | removeformat code print undo redo",
      }}
    />
  );
};

export default TinyRichTextEditor;
