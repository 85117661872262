import AudioInput from "./AudioInput";
import AutocompleteInput from "./AutocompleteInput";
import Checkbox from "./Checkbox";
import CountrySelect from "./CountrySelect";
import DevicePicker from "./DevicePicker";
import Input from "./Input";
import InputDateField from "./InputDateField";
import InputFile from "./InputFile";
import InputNumber from "./InputNumber";
import InputTextArea from "./InputTextArea";
import Select, { Option } from "./Select";
import Radio from "./Radio";
import UploadAndCropImageField from "./UploadAndCropImageField";
import TinyRichTextEditor from "./TinyRichTextEditor";
import EmailChange from "./EmailChange";

export default {
  AudioInput,
  AutocompleteInput,
  TinyRichTextEditor,
  Checkbox,
  CountrySelect,
  EmailChange,
  DevicePicker,
  Input,
  InputDateField,
  InputFile,
  InputNumber,
  InputTextArea,
  Select,
  Option,
  Radio,
  UploadAndCropImageField,
};
