import React from "react";

interface HeaderProps {
  /** Username of the other user in the chat */
  pseudo?: string;
  /** Status of the other user in the chat. If null or undefined, the status will be hidden. */
  active?: boolean;
  /** PRofile picture */
  picture?: string;
  /** Hide indicator */
  hideIndicator?: boolean;
}

const Header: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  pseudo, active, picture, children, hideIndicator,
}) => (
  <div className="flex sm:items-center justify-between py-3 px-2 border-b-2 border-gray-200">
    <div className="flex items-center space-x-2">
      {typeof active === "boolean" && !picture && !hideIndicator && (
        <div>
          <div className={`w-2 h-2 rounded-full ${active ? "bg-green-500" : "bg-red-600 animate-pulse"}`} />
        </div>
      )}
      {
        picture && (
          <img
            src={picture}
            className="w-12 h-12 rounded-full"
            alt="profile"
          />
        )
      }
      <div>{pseudo}</div>
    </div>
    {children}
  </div>
);

export default Header;
