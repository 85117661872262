import * as React from "react";

interface Props {
  country: string;
  className?: string;
  rounded?: boolean;
  cover?: boolean;
}

const Flag: React.FC<Props> = ({
  country, className, rounded, cover,
}) => {
  const c = country === "EN" ? "GB" : country;
  return (
    <img
      className={`${rounded ? "rounded-md" : ""} ${className || "w-5"}`}
      src={`https://cdn.satinahub.com/flags/${c}.svg`}
      alt={`flag-${c}`}
      title={c}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(cover ? { preserveAspectRatio: "xMidYMid slice" } : {})}
    />
  );
};

export default Flag;
