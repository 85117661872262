import React from "react";

export interface ITicketExchange {
  ticketExchangeUid: string;
  origin: string;
  content: string;
  attachments: string[];
  userPseudo: string;
  createdAt: string;
}

export interface ITicket {
  ticketUid: string;
  userType: UserType;
  userUid: string;
  userPseudo: string;
  userFirstname: string;
  userLastname: string;
  userAgent?: string;
  userEmail: string;
  adminDraft?: string;
  context: string;
  subject: string;
  language: string;
  exchanges?: ITicketExchange[];
  ticketRelation?: ITicketRelation;
  history?: ITicketHistory[];
  status: TicketStatus;
  assignedTo?: string;
  createdAt: string;
  updatedAt?: string;
  supportAgent?: ISupportAgent;
}

export interface PaginatedSupportTickets {
  totalRows: number;
  data: ITicket[];
}

export interface ITicketInput {
  userFirstname: string;
  userLastname: string;
  userPseudo: string;
  userEmail: string;
  language: string;
  subject: string;
  content: string;
  attachments: string[];
  relation?: ITicketRelation;
}

export interface ITicketRelation {
  relationType: string;
  relationRef: string;
}

export interface ITicketHistory {
  ticketHistoryUid: string;
  ticketUid: string;
  action: TicketAction;
  userUid: string;
  metadata: Partial<ITicketExchange & ITicket>;
  createdAt: string;
}

export interface ISupportForm {
  firstname: string;
  lastname: string;
  pseudo: string;
  email: string;
  language: string;
  subject: string;
  message: string;
  relation?: ITicketRelation;
  attachments: File[];
}

export interface ISupportRelation {
  title?: string;
  children?: React.JSX.Element;
  relationRef: string;
  relationType: string;
}

/* eslint-disable no-shadow */
export enum UserType {
  EXPERT = "EXPERT",
  MEMBER = "MEMBER",
}

type TicketAction = "CREATE" | "ASSIGN" | "USER_ANSWER" | "AGENT_ANSWER" | "CHANGE_STATUS";

export enum TicketStatus {
  PENDING = "PENDING",
  ANSWERED = "ANSWERED",
  IN_PROGRESS = "IN_PROGRESS",
  RESOLVED = "RESOLVED",
}

export interface ISupportAgent {
  supportAgentUid: string;
  name: string;
  profilePicture?: string;
  deleted: boolean;
  createdAt?: string;
  title?: string;
}

export interface IPredefinedAnswer {
  context: string;
  userType?: UserType;
  subject?: string;
  predefinedAnswerUid: string;
  contentUid: string;
  label: string;
  labelKey: string;
}

export interface IInternalNote {
  internalNoteUid: string;
  context: string;
  userType: string;
  userUid: string;
  content: string;
  attachments: string[];
  createdAt: string;
  updatedAt: string;
}

export interface ITicketSummary {
  pendingExpertCount: number;
  pendingMemberCount: number;
  pendingVisitorCount: number;
}

export interface ReplaceableTags {
  AGENT_TITLE?: string;
  AGENT_PSEUDO?: string;
  FIRSTNAME?: string;
  LASTNAME?: string;
  PSEUDO?: string;
  EMAIL?: string;
  NAME?: string;
}
