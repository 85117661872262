export class DateUtils {
  date: Date;

  private constructor(date?: string | number) {
    this.date = date ? new Date(date) : new Date();
  }

  static from(date: string | number) {
    return new DateUtils(date);
  }

  static now() {
    return new DateUtils();
  }

  private zeroPad = (num: number, places: number) => String(num).padStart(places, '0')

  getTime = () => this.date.getTime();
  getDay = () => this.zeroPad(this.date.getDate(), 2);
  getMonth = () => this.zeroPad(this.date.getMonth() + 1, 2);
  getYear = () => this.date.getFullYear();
  getHour = () => this.zeroPad(this.date.getHours(), 2);
  getMin = () => this.zeroPad(this.date.getMinutes(), 2);
  getSec = () => this.zeroPad(this.date.getSeconds(), 2);

  formatDate = () => `${this.getDay()}.${this.getMonth()}.${this.getYear()}`;

  formatDateTime = () => `${this.getDay()}.${this.getMonth()}.${this.getYear()} ${this.getHour()}:${this.getMin()}:${this.getSec()}`;

  formatTime = () => `${this.getHour()}:${this.getMin()}:${this.getSec()}`;
}
