import React from "react";
import clsx from "clsx";

interface Props {
  label?: string;
  hint?: string;
  error?: string;
}

const inputBase = "text-sm focus:outline-none w-full py-2 px-3 border border-gray-300 rounded-md disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200";
const inputError = "border-red-300";
const inputDisabled = "bg-gray-50 text-gray-500 border-gray-200";

const InputNumber: React.FC<
Props &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
> = ({
  label, hint, error, name, min, max, ...props
}) => (
  <div className="flex-1">
    <label
      htmlFor={name}
      className="block text-sm font-medium text-gray-700"
    >
      {label}
    </label>
    <div className="mt-2">
      <input
        type="number"
        name={name}
        min={min}
        max={max}
        {...props}
        className={clsx(inputBase, error && inputError, props.disabled && inputDisabled, props.className)}
      />
      {error && <div className="text-red-500">{error}</div>}
    </div>
  </div>
);

export default InputNumber;
