import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import config from "../common/services/config";

import SupportForm from "./SupportForm";

const Support: React.FC = () => (
  <GoogleReCaptchaProvider
    reCaptchaKey={config.gcp.recaptchaPublicKey}
    useEnterprise
    scriptProps={{
      async: false,
      defer: false,
      appendTo: "head",
      nonce: undefined,
    }}
    container={{
      element: "recaptcha-badge",
      parameters: {
        badge: undefined,
        theme: undefined,
      },
    }}
  >
    <div id="recaptcha-badge" className="hidden" />
    <SupportForm />
  </GoogleReCaptchaProvider>
);

export default Support;
