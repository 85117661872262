import React from "react";
import { DateUtils } from "@audacia-hq/shared/utils";
import { ITicket } from "@audacia-hq/shared/models";

import Button from "../Button";

interface Props {
  ticket: ITicket;
  selectTicket: (ticketUid) => void
  t: (key: string, params?: any) => string;
}

const TicketListItem: React.FC<Props> = ({ ticket, selectTicket, t }) => (
  <div className="shadow p-4 my-4 flex space-x-2 items-center">
    <div className="flex-grow">
      <div className="font-semibold">{DateUtils.from(ticket.createdAt).formatDateTime()}</div>
      {ticket.subject && <div>{t(`support:subjects.${ticket.subject}`)}</div>}
    </div>
    <Button theme="blue" size="small" onClick={() => selectTicket(ticket.ticketUid)}>
      {t("common:details")}
    </Button>
  </div>
);

export default TicketListItem;
