export const getElementPageOffset = (element: HTMLElement, aggr = {top: 0, left:0}): {top: number, left: number}  => {
  const {position, overflowX, overflowY} = getComputedStyle(element);
  const res = {
    top: element.offsetTop + aggr.top - (overflowY === "scroll" ? element.scrollTop : 0), 
    left: element.offsetLeft + aggr.left - (overflowX === "scroll" ? element.scrollLeft : 0)
  }
  if(position === "fixed") {
    return {
      top: res.top + window.scrollY,
      left: res.left + window.scrollX,
    };
  }
  if(element.offsetParent) {
    return getElementPageOffset(element.offsetParent as HTMLElement, res);
  }
  return res;
};