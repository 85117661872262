import Alert from "./Alert";
import AnimatedDots from "./dots/AnimatedDots";
import Badge from "./Badge";
import Button from "./Button";
import ChannelIcon from "./ChannelIcon";
import Collapse from "./Collapse";
import Flag from "./Flag";
import Pagination from "./Pagination";
import ProgressBar from "./ProgressBar";
import SizeAwareWrapper from "./SizeAwareWrapper";
import StarRating from "./StarRating";
import Tabs from "./Tabs";
import TextAvatar from "./TextAvatar";
import Toggle from "./Toggle";
import Tooltip from "./Tooltip";
import Form from "./form";
import Chat from "./chat";
import Sidebar from "./messages/Sidebar";
import UploadAttachmentsModal from "./messages/UploadAttachmentsModal";
import AlertModal from "./modal/AlertModal";
import AlternativesModal from "./modal/AlternativesModal";
import Modal from "./modal/Modal";
import PrintModal from "./modal/PrintModal";
import Calendar from "./calendar/Calendar";
import CalendarPosition from "./calendar/CalendarPosition";
import CalendarDraggable from "./calendar/CalendarDraggable";
import RingNotification from "./messages/RingNotification";
import Spinner from "./Spinner";
import TicketItem from "./supports/TicketItem";
import TicketList from "./supports/TicketList";
import TicketStatusTag from "./supports/TicketStatusTag";
import SupportRequestForm from "./supports/SupportRequestForm";
import SupportRelationList from "./supports/SupportRelationList";
import TicketExchange from "./supports/TicketExchange";
import AnnounceBanner from "./announcements/AnnounceBanner";
import PromoBanner from "./announcements/PromoBanner";
import ReleaseNote from "./announcements/ReleaseNote";
import { EventTrackerProvider } from "./event-tracker/EventTrackerContext";
import EventTrackerWrapper from "./event-tracker/EventTrackerWrapper";
import MarketingApiClient from "./services/marketingAPI";
import Survey from "./survey/Survey";
import LoaderOverlay from "./LoaderOverlay";
import Avatar from "./Avatar";

const SharedComponents = {
  Form,
  Flag,
  Modal,
  AlternativesModal,
  AlertModal,
  Announcements: {
    AnnounceBanner,
    PromoBanner,
    ReleaseNote,
  },
  Button,
  StarRating,
  Collapse,
  TextAvatar,
  Tabs,
  Calendar,
  CalendarPosition,
  CalendarDraggable,
  Toggle,
  Tooltip,
  Badge,
  SizeAwareWrapper,
  AnimatedDots,
  RingNotification,
  Spinner,
  SupportRequestForm,
  TicketItem,
  TicketList,
  TicketStatusTag,
  SupportRelationList,
  TicketExchange,
  EventTrackerWrapper,
  Chat,
  Survey,
};

export {
  Avatar, Alert, ChannelIcon, Pagination, ProgressBar, Sidebar, StarRating, UploadAttachmentsModal,
  Modal, Tooltip, PrintModal, LoaderOverlay, EventTrackerProvider, EventTrackerWrapper, MarketingApiClient,
};

export * from "./livechat/LivechatRoom";
export * from "./video/VideoRoom";
export * from "./video/VideoRoomArchive";
export * from "./video/VideoRoomControls";
export * from "./video/VideoRoomCloseButton";
export * from "./video/VideoReadyUpModal";
export * from "./messages/models";
export * from "./calendar/Calendar";
export { useCalendar } from "./calendar/CalendarContext";
export * from "./calendar/CalendarDraggable";
export * from "./supports/TicketSubjects";

export type { ChatViewMessage } from "./chat/ChatView";
export * from "./calendar/models";
export { default as VolumeMeter } from "./audio/VolumeMeter";
export { default as VolumeActivityBadge } from "./audio/VolumeActivityBadge";
export { default as ConsentRenewalModal } from "./consent/ConsentRenewalModal";

export default SharedComponents;
