import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { DateUtils } from "@audacia-hq/shared/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { ITicketExchange } from "@audacia-hq/shared/models";
import DOMPurify from "dompurify";

import Button from "../Button";
import Modal from "../modal/Modal";

interface Props {
  ticketExchange: ITicketExchange;
  isAnswer: boolean;
  getFile: (url: string) => Promise<string>;
  t: (key: string, params?: any) => string;
}

interface ImageAttribute {
  type: string;
  value: string;
  name: string;
}

const getAttachmentType = (att: string) => {
  if (att.match(/\.(png|jpeg|jpg|webp)$/i)) return "image";
  if (att.match(/\.(mp4|mov|pdf)$/i)) return "file";
  return "file";
};

const TicketExchange: React.FC<Props> = ({
  ticketExchange, isAnswer = true, getFile, t,
}) => {
  const [attachments, setAttachments] = useState<ImageAttribute[]>(undefined);
  const [selectedImage, setSelectedImage] = useState<ImageAttribute>(undefined);

  useEffect(() => {
    setAttachments(undefined);
    if (!ticketExchange.attachments || ticketExchange.attachments.length === 0) return;
    (async () => {
      const files = await Promise.all(
        ticketExchange.attachments.filter((att) => att !== "").map(async (att) => ({
          value: att.startsWith("https") ? att : await getFile(att).then((url) => url),
          type: getAttachmentType(att),
          name: att.split("/")[att.split("/").length - 1],
        })),
      );
      setAttachments(files.length > 0 ? files : undefined);
    })();
  }, [ticketExchange.attachments]);
  return (
    <>
      <div className={clsx(
        "mb-4 flex flex-col",
        !isAnswer && "items-end",
      )}
      >
        <div className="w-2/3">
          <div className={clsx("text-xs my-1 text-gray-400", !isAnswer && "text-right")}>
            {ticketExchange.userPseudo}
          </div>
          <div
            data-pw="ticketExchange"
            className={clsx(!isAnswer && "bg-green-100", isAnswer && "bg-gray-100", "p-3 rounded-md whitespace-pre-line h-fit")}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ticketExchange.content) }}
          />

          {attachments && (
            <div className="grid grid-cols-3 gap-4 my-4">
              {attachments.map((file, index: number) => (
                <div key={`attachment-${index}`}>
                  {file.type === "image" && (
                    <button type="button" onClick={() => setSelectedImage(file)} key={`file-${ticketExchange.ticketExchangeUid}-${index}`} className="hover:opacity-80">
                      <img src={file.value} alt={file.name} />
                    </button>
                  )}
                  {file.type === "file" && (
                    <a href={file.value} target="_blank" rel="noreferrer" download={file.name} key={`file-${ticketExchange.ticketExchangeUid}-${index}`}>
                      <Button size="small" className="px-2 w-fit">
                        <FontAwesomeIcon icon={faDownload} />
                        <span className="ml-2">
                          {t("common:downloadFile")}
                        </span>
                      </Button>
                    </a>
                  )}
                </div>

              ))}
            </div>
          )}
          <div className={clsx(
            "text-xs my-1 text-gray-400",
            !isAnswer && "text-right",
          )}
          >
            {DateUtils.from(ticketExchange.createdAt).formatDateTime()}
          </div>
        </div>
        {selectedImage && (
          <Modal
            title={t("support:displayImageModalTitle")}
            show={!!selectedImage}
            onClose={() => setSelectedImage(undefined)}
            size="large"
          >
            <div className="flex flex-col items-end">
              <img className="w-full" src={selectedImage.value} alt="test" />
              <hr className="py-4" />
              <a href={selectedImage.value} target="_blank" rel="noreferrer" download={selectedImage.name}>
                <Button className="overflow-hidden px-2 w-fit">
                  <FontAwesomeIcon icon={faDownload} />
                  <span className="ml-2">
                    {t("common:downloadImage")}
                  </span>
                </Button>
              </a>

            </div>
          </Modal>
        )}
      </div>
      <hr />
    </>
  );
};

export default TicketExchange;
