import React, { useEffect, useRef, useState } from "react";
import { Combobox } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

interface Item {
  [prop: string]: any,
  key: string;
  isQuery?: boolean;
}

interface Props {
  value?: string;
  filterItems: (search: string) => Item[];
  itemValue: (item: Item) => string;
  itemRender: (active: boolean, selected: boolean, item: Item) => React.JSX.Element;
  label?: string;
  placeholder?: string;
  submitIcon?: IconDefinition;
  onSubmit?: (value: Item) => void;
  clearOnSubmit?: (value: Item) => boolean;
  strictValue?: boolean;
  searchOptionLabel?: (query: string) => string;
}

const AutocompleteInput: React.FC<Props> = ({
  value, filterItems, itemValue, itemRender, label, placeholder, submitIcon, onSubmit, clearOnSubmit, strictValue, searchOptionLabel,
}) => {
  const [query, setQuery] = useState(value || "");
  const [selectedItem, setSelectedItem] = useState<Item>(null);
  const [filteredItems, setFilteredItems] = useState<Item[]>([]);
  const inputRef = useRef(null);

  useEffect(() => {
    setFilteredItems(filterItems(query));
  }, [query, filterItems]);

  useEffect(() => {
    setQuery(value || "");
  }, [value]);

  return (
    <Combobox
      as="div"
      value={selectedItem}
      onChange={(item) => {
        setSelectedItem(item);
        if (onSubmit) onSubmit(item);
        if (clearOnSubmit && clearOnSubmit(item)) setQuery("");
      }}
    >
      {label && <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">{label}</Combobox.Label>}

      <div className="relative mt-2">
        <Combobox.Input
          ref={inputRef}
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset
          ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder={placeholder}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(item) => {
            if (!query.length) return "";
            if (!item) return query;
            return (item as Item)?.isQuery ? query : itemValue(item as Item);
          }}
          autoComplete="off"
          data-pw="searchInput"
        />
        {submitIcon && (
          <button
            type="button"
            className={`absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none 
              ${onSubmit ? "cursor-pointer" : ""}`}
            onClick={() => {
              const item = { key: null, isQuery: true, query };
              setSelectedItem(item);
              if (onSubmit) onSubmit(item);
            }}
          >
            <FontAwesomeIcon icon={submitIcon} className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
        )}

        <Combobox.Options className="absolute z-20 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base
          shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >

          {!strictValue && searchOptionLabel && query.length > 0 && (
            <Combobox.Option
              key={`__${query}`}
              className={({ active }) => `relative cursor-default select-none py-2 pl-3 pr-9 
                  ${active ? "bg-indigo-600 text-white" : "text-gray-900"}`}
              value={{ key: null, query, isQuery: true }}
            >
              {searchOptionLabel(query)}
            </Combobox.Option>
          )}

          {filteredItems.map((item) => (
            <Combobox.Option
              data-pw="searchItems"
              key={item.key}
              value={item}
              className={({ active }) => `relative cursor-default select-none py-2 pl-3 pr-9 
                  ${active ? "bg-indigo-600 text-white" : "text-gray-900"}`}
            >
              {({ active, selected }) => <>{itemRender(active, selected, item)}</>}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
};

export default AutocompleteInput;
