import { Consent } from "@audacia-hq/shared/models";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "antd";

import Modal from "../modal/Modal";

interface Props {
  consents: (Consent & {content: string;})[];
  onAccept: (consent: Consent[]) => void;
  onSupport: () => void;
}

const ConsentRenewalModal: React.FC<Props> = ({
  consents,
  onAccept,
  onSupport,
}) => {
  const { t, i18n } = useTranslation(["common", "terms"]);

  const currentConsent = consents[0];

  const handleAccept = () => {
    onAccept([currentConsent]);
  };

  const handleSupport = () => {
    onSupport();
  };

  if (!currentConsent) return null;

  return (
    <Modal
      title={t("consent.renewal.title", { title: t(`terms:${currentConsent.contentId}`) })}
      subtitle={
        t("consent.renewal.summary", {
          date: new Date(currentConsent.lastModifiedAt).toLocaleString(i18n.language, {
            timeZone: "UTC",
            timeStyle: "long",
            dateStyle: "medium",
          }),
        })
      }
      show={!!currentConsent}
    >
      {currentConsent.required && (
        <p className="pb-4">
          {t("consent.renewal.notice")}
        </p>
      )}
      <div
        className="overflow-auto h-[50vh] px-4"
        style={{
          background: "linear-gradient(#ffffff 33%, rgba(255,255,255, 0)),\n    linear-gradient(rgba(255,255,255, 0), #ffffff 66%) 0 100%,\n    radial-gradient(farthest-side at 50% 0, rgba(102,102,102, 0.5), rgba(0,0,0,0)),\n    radial-gradient(farthest-side at 50% 100%, rgba(102,102,102, 0.5), rgba(0,0,0,0)) 0 100%",
          backgroundColor: "#ffffff",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "local, local, scroll, scroll",
          backgroundSize: "100% 48px, 100% 48px, 100% 16px, 100% 16px",
        }}
      >
        <div
          dangerouslySetInnerHTML={{ __html: currentConsent.content }}
        />
      </div>
      <div className="flex flex-row-reverse justify-between pt-4">
        <Button onClick={handleAccept} type="primary" size="large">
          {t("accept")}
        </Button>
        <Button onClick={handleSupport} type="default" size="large">
          {t("menu.support")}
        </Button>
      </div>
    </Modal>
  );
};

export default ConsentRenewalModal;
