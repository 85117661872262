import { Question } from "@audacia-hq/shared/models";

import EmailQuestion from "./EmailQuestion";
import InformationQuestion from "./InformationQuestion";
import LikertScaleQuestion from "./LikertScaleQuestion";
import LongTextQuestion from "./LongTextQuestion";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import NumericQuestion from "./NumericQuestion";
import ShortTextQuestion from "./ShortTextQuestion";
import UniqueChoiceQuestion from "./UniqueChoiceQuestion";

export const Questions = {
  InformationQuestion,
  ShortTextQuestion,
  LongTextQuestion,
  NumericQuestion,
  LikertScaleQuestion,
  MultipleChoiceQuestion,
  UniqueChoiceQuestion,
  EmailQuestion,
};
interface Props {
  question: Question;
}

const QuestionRenderer = ({
  question,
}: Props) => {
  switch (question.type) {
    case "INTRODUCTION":
    case "CONCLUSION":
      return <Questions.InformationQuestion question={question} />;
    case "SHORT_TEXT":
      return <Questions.ShortTextQuestion question={question} />;
    case "LONG_TEXT":
      return <Questions.LongTextQuestion question={question} />;
    case "NUMERIC":
      return <Questions.NumericQuestion question={question} />;
    case "LIKERT_SCALE":
      return <Questions.LikertScaleQuestion question={question} />;
    case "MULTIPLE_CHOICE":
      return <Questions.MultipleChoiceQuestion question={question} />;
    case "UNIQUE_CHOICE":
      return <Questions.UniqueChoiceQuestion question={question} />;
    case "EMAIL":
      return <Questions.EmailQuestion question={question} />;
    default:
      return <></>;
  }
};

export default QuestionRenderer;
