import { gql } from "@apollo/client";

export const getExpertFragmentId = (expertUid) => `Expert:{"expertUid":"${expertUid}"}`;

export const EXPERT_QUEUE_FRAGMENT = gql`
  fragment ExpertQueueFragment on Expert {
    queue
  }
`;

export const EXPERT_PROFILES_FRAGMENT = gql`
  fragment ExpertProfileFragment on Expert {
    profiles
  }
`;

export const EXPERT_PICTURES_FRAGMENT = gql`
  fragment ExpertPictureFragment on Expert {
    pictures
  }
`;

export const EXPERT_DOCUMENTS_FRAGMENT = gql`
  fragment ExpertDocumentsFragment on Expert {
    documents
  }
`;
