import React from "react";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  show?: boolean;
}
const LoaderOverlay: React.FC<Props> = ({ show = true }) => (show
  ? (
    <div className="bg-slate-100 opacity-30 absolute top-0 left-0 w-full h-full z-50">
      <FontAwesomeIcon icon={faCircleNotch} className="text-2xl animate-spin left-1/2 top-1/2 absolute" />
    </div>
  ) : null
);

export default LoaderOverlay;
